import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpService } from 'src/app/services/http.service';
import { AlertpopupComponent } from '../alertpopup/alertpopup.component';

@Component({
  selector: 'app-loadwallet',
  templateUrl: './loadwallet.component.html',
  styleUrls: ['./loadwallet.component.css']
})
export class LoadwalletComponent implements OnInit {
  title: any;
  type: any;
  firstFormGroup: FormGroup;
  inProgress: boolean = false;
  userStatus: any;
  // constructor() { }
  constructor(
    @Inject(MAT_DIALOG_DATA) data: any, 
    public dialogRef: MatDialogRef<LoadwalletComponent>,
    private _formBuilder: FormBuilder,
    private http: HttpService,
    private dialog: MatDialog
  ) { 
    console.log(data);
    this.title = data.title;
    this.type = data.popup;
}
  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
      wallet_id: ['', Validators.required],
      amount: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9]{1,5}')
      ])],
      comment: 'Load wallet balance',
    });
  }
  closePopup(respo: any){
    this.dialogRef.close(respo);
  }
  loadRequest(){
    this.inProgress = true;
    let formdata = new FormData();
    formdata.append('wallet',JSON.stringify(this.firstFormGroup.value));
    formdata.append('userRole',sessionStorage.getItem('userRole'));
    this.http.postDataByForm('mobikwik/loadWallet',formdata)
           .then(data => {
              this.inProgress = false;
              console.log(data);
              this.userStatus  = data;
              if(this.userStatus.status==1){
                let dialogPopupRef = this.dialog.open(AlertpopupComponent,{
                  width: "350px",
                  data: { title: 'Request success !', message: this.userStatus.message, popup:'popup'}
                });
                dialogPopupRef.afterClosed().subscribe(result => {
                    this.firstFormGroup.reset();
                    this.closePopup(this.userStatus.data.walletBalance);
                });
              }
              else {
                let dialogPopupRef = this.dialog.open(AlertpopupComponent,{
                  width: "350px",
                  data: { title: 'Request fail !', message: this.userStatus.message, popup:'popup'}
                });
                dialogPopupRef.afterClosed().subscribe(result => {
                });
              }
           });
  }
}
