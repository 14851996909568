import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { HttpService } from 'src/app/services/http.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AlertpopupComponent } from 'src/app/common/alertpopup/alertpopup.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-pendingbills',
  templateUrl: './pendingbills.component.html',
  styleUrls: ['./pendingbills.component.css']
})
export class PendingbillsComponent implements OnInit {

  allBills: any = [];
  walletRecords: any = [];
  billCart: any = [];
  FormGroup: FormGroup;
  respo: any;
  cols: any = [];
  colus: any = [];
  first = 0;
  rows = 50;
  datasource: any = [];
  loading: boolean = false;
  totalRecords: number = 0;
  wallet_balance: any = 0.00;
  current_balance: any = 0.00;
  used_amount:any = 0;
  userRole: any = "Admin";
  inProgress: boolean = true;
  isImage: boolean = false;
  PendingBillsRed: any = [];
  PendingBillsOther: any = [];
  dataBillList: any;
  tType: any = "pending";
  successCheckbox: any = [];
  billAdded: any = [];
  ismoresuccess: boolean = false;

  exportColumns: any[];

  constructor(private http: HttpService, 
    private _formBuilder: FormBuilder,
    private dialog: MatDialog  
  ) { 
      this.userRole = sessionStorage.getItem('userRole');
  }

  ngOnInit(): void {
    this.getDetails();
    this.initTable();
    this.getWallets();
    this.FormGroup = this._formBuilder.group({
      Wallet: ''
    });
    this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));

    this.loading = false;
  }
  getCheckbox(event: any, index: any){
    this.ismoresuccess =true;
    if(event.target.checked){
        if(this.allBills[index]){
          this.allBills[index].paychecked = true;
        }
    }
    else {
        if(this.allBills[index]){
          this.allBills[index].paychecked = false;
        }
    }
  }
  checkAll(event: any){
    if(event.target.checked){
        for(let i=0;i<this.rows;i++){
          if(this.allBills[i]){
            this.allBills[i].paychecked = true;
          }
        }  
        this.ismoresuccess =true;
    }
    else {
      for(let i=0;i<this.rows;i++){
        if(this.allBills[i]){
          this.allBills[i].paychecked = false;
        }
      }  
      this.ismoresuccess =false;
    }
  }
  initTable(){
    this.cols = [
      // { field: 'Id', header: 'Id' },
      { field: 'TXN_Id', header: 'TXN No' },
      { field: 'Recharge_Type', header: 'Service Type' },
      { field: 'Invoice_No', header: 'Invoice No' },
      { field: 'Amount', header: 'Pay Amount' },
      { field: 'Customer_Name', header: 'Customer Name' },
      { field: 'Due_Date', header: 'Due Date' },
      { field: 'Recharge_Date', header: 'Recharge Date' },
      { field: 'Name', header: 'Retailer Name' },
      { field: 'SendBB', header: 'BB Status' }
    ];

    this.colus = [
      // { field: 'Id', header: 'Id' },
      { field: 'TXN_Id', header: 'TXN No' },
      { field: 'Recharge_Type', header: 'Service Type' },
      { field: 'Invoice_No', header: 'Invoice No' },
      { field: 'Amount', header: 'Pay Amount' },
      { field: 'charge_amount', header: 'Charge' },
      { field: 'Customer_Name', header: 'Customer Name' },
      // { field: 'Customer_Phone', header: 'Customer Phone' },
      { field: 'Due_Date', header: 'Due Date' },
      { field: 'Recharge_Date', header: 'Recharge Date' },
      { field: 'Name', header: 'Retailer Name' },
      // { field: 'SendBB', header: 'BB Status' },
    ];
  }
  getBalance(){
      let control = this.FormGroup.get('Wallet').value;
      this.walletRecords.forEach(element => {
        if(element.Id==control){
          this.wallet_balance = element.Credited_Amount - element.Used_Amount;
          this.used_amount = element.Used_Amount;
        }
      });
  }
  loadCarsLazy(event: LazyLoadEvent) {
    this.loading = true;

    //in a real application, make a remote request to load data using state metadata from event
    //event.first = First row offset
    //event.rows = Number of rows per page
    //event.sortField = Field name to sort with
    //event.sortOrder = Sort order as number, 1 for asc and -1 for dec
    //filters: FilterMetadata object having field as key and filter value, filter matchMode as value

    //imitate db connection over a network
    setTimeout(() => {
        if (this.datasource) {
            this.allBills = this.datasource.slice(event.first, (event.first + event.rows));
            this.loading = false;
        }
    }, 1000);
}

  next() {
    this.first = this.first + this.rows;
  }

  prev() {
      this.first = this.first - this.rows;
  }

  reset() {
      this.first = 0;
  }

  isLastPage(): boolean {
      return this.first === (this.allBills.length - this.rows);
  }

  isFirstPage(): boolean {
      return this.first === 0;
  }
  
//   exportPdf() {
//     import("jspdf").then(jsPDF => {
//         import("jspdf-autotable").then(x => {
//             const doc = new jsPDF.default(0,0);
//             doc.autoTable(this.exportColumns, this.allBills);
//             doc.save('primengTable.pdf');
//         })
//     })
// }

// exportExcel() {
//     import("xlsx").then(xlsx => {
//         const worksheet = xlsx.utils.json_to_sheet(this.getDetails());
//         const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
//         const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
//         this.saveAsExcelFile(excelBuffer, "primengTable");
//     });
// }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }
  changeType(type: any = 'pending'){
      this.tType = type;
      if(type== 'pending'){
        this.dataBillList = this.PendingBillsOther;
      } 
      else {
        this.dataBillList = this.PendingBillsRed;
      }
  }
  getDetails(){
    this.PendingBillsRed= [];
    this.PendingBillsOther = [];
    this.inProgress = true;
    let formdata = new FormData();
    formdata.append('type','pending');
    this.http.postDataByForm('payrecharge/get_list',formdata)
        .then(data => {
          // console.log(data);
          this.inProgress = false;
          this.respo = data;
          if(this.respo.status==1){
            //this.datasource = this.respo.data.records;
            this.allBills = this.respo.data.records;
            this.totalRecords = this.datasource.length;
            for (let bills of this.allBills) {
              bills.SendBB = bills.isSendBB == '1'?'Yes':'No';
              bills.paychecked = false;
              if(bills.Recharge_Type == 'Torrent Power Limited, Ahmedabad'){
                this.PendingBillsRed.push(bills);
              }
              else {
                this.PendingBillsOther.push(bills);
              }
            }
            this.dataBillList = this.PendingBillsOther;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.inProgress = false;
        });
  }
  setSuccessAll(){
    for (let bills of this.allBills) {
      console.log(bills);
      if(bills.paychecked){
        bills.Pay_Status = "1";
        bills.bbpsReferenceNumber = "Check Portal";
        this.failBills(bills, true);
      }
    }
  }
  paySuccess(bills: any){      
    let dialogRef = this.dialog.open(AlertpopupComponent,{
      width: "350px",
      data: { title: 'Enter BBPS No', message: "", popup:'bbps_id'}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        if(result!=''){
          bills.Pay_Status = "1";
          bills.bbpsReferenceNumber = result.bbps_no;
          this.failBills(bills);
        }
      }
    });
  }
  payRedSuccess(bills: any){      
    let dialogRef = this.dialog.open(AlertpopupComponent,{
      width: "350px",
      data: { title: 'Enter BBPS No & Paid Charge', message: "", popup:'bbps_red_id'}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        console.log(result);
        if(result!=''){
          bills.Pay_Status = "1";
          bills.bbpsReferenceNumber = result.bbps_no;
          bills.charge_paid = result.charge_paid;
          this.failBills(bills);
        }
      }
    });
  }
  failBill(bills: any){
    let dialogRef = this.dialog.open(AlertpopupComponent,{
      width: "350px",
      data: { title: 'Confirm !', message: "Are you sure to failed bill??", popup:'confirm'}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result=='Yes'){        
        bills.Pay_Status = "2";
        this.failBills(bills);
      }
    });
  }
  failBills(bills: any, isMore: boolean = false){
    this.inProgress = true;
    let formdata = new FormData();
    delete bills.SendBB;
    formdata.append('bills',JSON.stringify(bills));
    formdata.append('userRole',sessionStorage.getItem('userRole'));
    this.http.postDataByForm('payrecharge/updateStatusRecords',formdata)
        .then(data => {
          // console.log(data);
          this.inProgress = false;
          this.respo = data;
          if(this.respo.status==1){
            if(!isMore){
              let dialogRef = this.dialog.open(AlertpopupComponent,{
                width: "350px",
                data: { title: 'Status success !', message: 'Status changed success !', popup:'popup'}
              });
              dialogRef.afterClosed().subscribe(result => {
                  this.getDetails();
              });
            }
            this.getDetails();
          }
          else {
            let dialogRef = this.dialog.open(AlertpopupComponent,{
              width: "350px",
              data: { title: 'Action fail !', message: 'Status change fail ! Try again.', popup:'popup'}
            });
            dialogRef.afterClosed().subscribe(result => {
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.inProgress = false;
        });
  }
  getWallets(){
    this.inProgress = true;
    let formdata = new FormData();
    this.http.postDataByForm('wallets/get_records',formdata)
        .then(data => {
          this.inProgress = false;
          // console.log(data);
          this.respo = data;
          if(this.respo.status==1){
            //this.datasource = this.respo.data.records;
            this.walletRecords = this.respo.data.records;
          }
        });
  }
  addToCart(bills: any){
      let i = 0;
      let count = -1;
      let billAmount = 0;
      this.allBills.forEach(element => {
          if(element.Id==bills.Id){
            count = i;
            this.current_balance = parseFloat(this.current_balance) + parseFloat(bills.Amount);
            billAmount = bills.Amount;
          }
          i++;
      });
      if(this.wallet_balance >= this.current_balance){
        if(count!=-1){
          bills.Pay_Status = 'Success';
          bills.Paid_by = sessionStorage.getItem('userId');
          this.allBills.splice(count,1);
          this.billCart.push(bills);
        }
      }
      else if(!this.FormGroup.get('Wallet').value){
        this.current_balance = parseFloat(this.current_balance) - billAmount;
        let dialogRef = this.dialog.open(AlertpopupComponent,{
          width: "350px",
          data: { title: 'Select Wallet', message: "Please, Select wallet for pay bills.", popup:'popup'}
        });
        dialogRef.afterClosed().subscribe(result => {
        });
      }
      else {
        this.current_balance = parseFloat(this.current_balance) - billAmount;
        let dialogRef = this.dialog.open(AlertpopupComponent,{
          width: "350px",
          data: { title: 'Wallet Balance Low', message: "Wallet balance is low, Please, Push wallet", popup:'popup'}
        });
        dialogRef.afterClosed().subscribe(result => {
        });
      }
  }
  removeBill(bills: any){
    let formdata = new FormData();
      formdata.append('userId',sessionStorage.getItem('userId'));
      formdata.append('bills',JSON.stringify(bills));
      formdata.append('userRole',sessionStorage.getItem('userRole'));
      this.http.postDataByForm('payrecharge/deleteRecords',formdata)
          .then(data => {
            // console.log(data);
            this.respo = data;
            if(this.respo.status==1){
              let dialogRef = this.dialog.open(AlertpopupComponent,{
                width: "350px",
                data: { title: 'Deleted success !', message: this.respo.message, popup:'popup'}
              });
              dialogRef.afterClosed().subscribe(result => {
                  this.getDetails();
              });
            }
            else {
              let dialogRef = this.dialog.open(AlertpopupComponent,{
                width: "350px",
                data: { title: 'Deleted fail !', message: this.respo.message, popup:'popup'}
              });
              dialogRef.afterClosed().subscribe(result => {
              });
            }
          });
  }
  payCardBills(billCart: any){
      this.inProgress = true;
      let formdata = new FormData();
      // formdata.append('Wallet_Id',this.FormGroup.get('Wallet').value);
      // formdata.append('card_amount',this.current_balance);
      // formdata.append('wallet_balance',this.wallet_balance);
      // formdata.append('used_amount',this.used_amount);
      formdata.append('bills',JSON.stringify(billCart));
      formdata.append('userRole',sessionStorage.getItem('userRole'));
      this.http.postDataByForm('payrecharge/updateRecords',formdata)
          .then(data => {
            // console.log(data);
            this.inProgress = false;
            this.respo = data;
            if(this.respo.status==1){
              this.billCart = [];
              this.FormGroup.reset();
              this.current_balance = 0;
              this.used_amount = 0;
              this.wallet_balance = 0;
              let dialogRef = this.dialog.open(AlertpopupComponent,{
                width: "350px",
                data: { title: 'Paid success !', message: this.respo.message, popup:'popup'}
              });
              dialogRef.afterClosed().subscribe(result => {
                  this.getWallets();
                  this.getDetails();
              });
            }
            else {
              let dialogRef = this.dialog.open(AlertpopupComponent,{
                width: "350px",
                data: { title: 'Paid fail !', message: this.respo.message, popup:'popup'}
              });
              dialogRef.afterClosed().subscribe(result => {
              });
            }
          });
  }
  sendToBB(bills: any){
    let cartList = [];
    cartList.push(bills);
    this.inProgress = true;
    let formdata = new FormData();
    formdata.append('recharge', JSON.stringify(cartList));
    formdata.append('paytype', 'multiple');
    this.http.postDataByForm('payrecharge/sendToBB', formdata)
      .then(data => {
        this.inProgress = false;
        this.respo = data;
        if (this.respo.status == 1) {
          let dialogRef = this.dialog.open(AlertpopupComponent,{
            width: "350px",
            data: { title: 'Sent to BB !', message: this.respo.message, popup:'popup'}
          });
          dialogRef.afterClosed().subscribe(result => {
              bills.isSendBB = 1;
          });
        }
        else {
          let dialogRef = this.dialog.open(AlertpopupComponent,{
            width: "350px",
            data: { title: 'Fail to sent!', message: this.respo.message, popup:'popup'}
          });
          dialogRef.afterClosed().subscribe(result => {
          });
        }
      });
  }
}
