import { Component, OnInit } from '@angular/core';
import { AlertpopupComponent } from 'src/app/common/alertpopup/alertpopup.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpService } from 'src/app/services/http.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-myprofile',
  templateUrl: './myprofile.component.html',
  styleUrls: ['./myprofile.component.css']
})
export class MyprofileComponent implements OnInit {
  editId: any = 0;
  userType: any = 'Retailer';
  FormGroup: FormGroup;
  imageSrc: any;
  imageSrc1: any;
  actionName: any = "Save";
  parentType: any = "Distributor";
  first = 0;
  rows = 10;
  allRecords: any = [];
  totalRecords: any = 0;
  respo: any;
  openUser: boolean = false;
  userRole: any = "Admin";
  inProgress: boolean = false;
  response: any;
  userFormgroup: FormGroup;
  constructor(private _formBuilder: FormBuilder,
    private http: HttpService,
    private dialog: MatDialog) { 
      this.getUserRecords();
  }

  ngOnInit(): void {
    this.FormGroup = this._formBuilder.group({
      Name: ['', Validators.required],
      Address: ['', Validators.required],
      Contact_No: ['', Validators.required],
      Email_Id: ['', Validators.required]
    });
    this.userFormgroup = this._formBuilder.group({
      CurrentPassword: ['', Validators.required],
      Password: ['', Validators.required],
      CPassword: ['', Validators.required]
    });
  }
  editRecords(record: any){
    this.FormGroup.patchValue({
      Name: record.Name,
      Address: record.Address,
      Contact_No: record.Contact_No,
      Email_Id: record.Email_Id,
    });
    this.editId = record.Id;
    this.openUser= true;
    this.actionName = "Update";
  }
  getUserRecords(){
    this.inProgress = true;
    let formdata = new FormData();
    formdata.append('Id',sessionStorage.getItem('userId'));
    this.http.postDataByForm('users/get_records',formdata)
            .then(data => {
              this.inProgress = false;
              this.respo = data;
              console.log(this.respo);
              if(this.respo.status==1){
                  this.allRecords = this.respo.data.records[0];
                  this.editRecords(this.allRecords);
              }
            });
  }
  saveRecord(){
    this.inProgress = true;
    let user = JSON.parse(sessionStorage.getItem('user'));
    let userData = user;
    if(this.userFormgroup.get('Password').value != this.userFormgroup.get('CPassword').value) {
      let dialogRef = this.dialog.open(AlertpopupComponent, {
        width: "350px",
        data: { title: "", message: "Password and Confirm Password not matched, Please, verify it",popup:'popup'}
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log(result);
      });
    }
    else {
      let records = {
          Id: userData.Id,
          Password: this.userFormgroup.get('Password').value,
          CurrentPassword: this.userFormgroup.get('CurrentPassword').value,
      };
      let data = JSON.stringify(records);
      this.inProgress = true;
      let formdata = new FormData();
      formdata.append('user',data);
      this.http.postDataByForm('users/changePassword',formdata)
              .then(data => {
                this.inProgress = false;
                this.response = data;
                console.log(this.response);
                if(this.response.status==1){
                  let dialogRef = this.dialog.open(AlertpopupComponent,{
                    width: "350px",
                    data: { title: 'Password change success!', message: "Password change successfully", popup:'popup'}
                  });
                  dialogRef.afterClosed().subscribe(result => {
                      this.userFormgroup.reset();
                      let res = {'status':'-1'};
                      sessionStorage.removeItem('user');
                      sessionStorage.removeItem('userId');
                      sessionStorage.removeItem('userName');
                      sessionStorage.removeItem('isLogin');
                      sessionStorage.removeItem('Auth-token');
                      this.http.tokenExpired(res);
                  });
                }
                else {
                  let dialogRef = this.dialog.open(AlertpopupComponent,{
                    width: "350px",
                    data: { title: this.response.title, message: this.response.message,popup:'popup'}
                  });
                  dialogRef.afterClosed().subscribe(result => {
                    console.log(result);
                  });
                }
              });
    }
  }
  // saveUser(){
  //   this.inProgress = true;
  //   let data = this.FormGroup.value;
  //   data.userRole = this.userType;
  //   data.Created_by = sessionStorage.getItem('userName');
  //   if(this.editId!=0){
  //     data.Id = this.editId;
  //     data.myProfile = "yes";
  //   }
  //   let formdata = new FormData();
  //   formdata.append('user',JSON.stringify(data));
  //   this.http.postDataByForm('users/saveUser',formdata)
  //           .then(data => {
  //             this.inProgress = false;
  //             this.respo = data;
  //             let Action = this.editId==0?"Saved":"Updated";
  //             if(this.respo.status==1){
  //               let dialogRef = this.dialog.open(AlertpopupComponent,{
  //                 width: "350px",
  //                 data: { title: Action+' successfully', message: this.respo.message, popup:'popup'}
  //               });
  //               dialogRef.afterClosed().subscribe(result => {
  //                   this.getUserRecords();
  //               });         
  //               if(this.editId!=0){
  //                 this.openUser = false;
  //                 this.editId = 0;
  //               }       
  //             }
  //             else {
  //               let dialogRef = this.dialog.open(AlertpopupComponent,{
  //                 width: "350px",
  //                 data: { title: 'Action fail', message: this.respo.message, popup:'popup'}
  //               });
  //               dialogRef.afterClosed().subscribe(result => {
  //                   if(result=='Yes'){
  //                     this.getUserRecords();
  //                   }
  //               });
  //             }
  //           });
  // }
}
