<app-page-loading [hidden]="!inProgress"></app-page-loading>
<div class="row" style="margin-left: 0px; margin-right: 0px;">
    <div class="col-12" class="header">
        <mat-card-title>{{userRole === 'Super Admin' ? 'Manage Bank' : 'Payment Options'}}</mat-card-title>
    </div>
    <div class="col-md-12 text-right" *ngIf="!openUser && userRole === 'Super Admin'" style="padding-right:15px; padding-left:15px; padding-bottom: 15px; padding-top: 15px;">
        <button mat-raised-button color="primary" (click)="createRecord()">
            <mat-icon>add_circle</mat-icon> Create New Bank
        </button>
    </div>
    <div class="col-12" *ngIf="!openUser">
        <mat-divider></mat-divider>
        <div>&nbsp;</div>
        <div class="row" *ngIf="allRecords.length>0">
            <div class="col-md-4 col-sm-6 col-xs-12 bankdetails mb-3" *ngFor="let bank of allRecords">
                <mat-card>
                    <mat-card-header>
                        <mat-card-title>
                            {{bank.Bank_Name}}
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <mat-divider></mat-divider>
                        <div>&nbsp;</div>
                        <div>
                            <strong>Holder Name : </strong><span>{{bank.Account_Name}}</span>
                        </div>
                        <div>
                            <strong>Account Type : </strong><span>{{bank.Account_Type}}</span>
                        </div>
                        <div>
                            <strong>Account No. : </strong><span>{{bank.Account_No}}</span>
                        </div>
                        <div>
                            <strong>Branch Name : </strong><span>{{bank.Branch_Name}}</span>
                        </div>
                        <div>
                            <strong>IFSC Code : </strong><span>{{bank.IFSC_code}}</span>
                        </div>
                        <div class="text-right" *ngIf="userRole === 'Super Admin'">
                            <button class="mr-2" type="button" mat-raised-button (click)="editRecords(bank)" color="basic">
                                <mat-icon>edit</mat-icon> Edit
                            </button>
                            <button type="button" mat-raised-button color="warn" *ngIf="bank.is_Active==0" (click)="changeStatus(bank)">Inactive</button>
                            <button type="button" mat-raised-button color="primary" *ngIf="bank.is_Active!=0" (click)="changeStatus(bank)">Active</button>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
    <div *ngIf="openUser">
        <form [formGroup]="FormGroup">
            <div class="row" style="margin-right: 0px; margin-left: 0px; margin-top: 15px;">
                <div class="col-md-12">
                    <mat-card>
                        <mat-card-content>
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label>Bank Name</label>
                                        <input class="form-control" placeholder="Bank Name" formControlName="Bank_Name" required>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label>Holder Name</label>
                                        <input class="form-control" placeholder="Account Name" formControlName="Account_Name" required>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label>Account Type</label>
                                        <input class="form-control" placeholder="Account Type" formControlName="Account_Type" required>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label>Account No</label>
                                        <input class="form-control" placeholder="Account No" formControlName="Account_No" required>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Branch Name</label>
                                        <input class="form-control" placeholder="Branch Name" formControlName="Branch_Name" required>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Bank Address</label>
                                        <input class="form-control" placeholder="Bank Address" formControlName="Bank_Address" required>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>IFSC code</label>
                                        <input class="form-control" placeholder="IFSC code" formControlName="IFSC_code" required>
                                    </div>
                                </div>
                                <div class="col-md-12 text-right">
                                    <div><br></div>
                                    <button mat-raised-button color="warn" (click)="closeUser()" style="margin-right: 15px;">
                                        <mat-icon>close</mat-icon> Clear and Close
                                    </button>
                                    <button mat-raised-button color="primary" [disabled]="inProgress" (click)="saveUser()">
                                        <mat-icon>save</mat-icon> {{actionName}} <mat-icon>forward</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </form>
    </div>
</div>