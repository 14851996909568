<app-page-loading [hidden]="!inProgress"></app-page-loading>
<div class="col-12" class="header">
    <mat-card-title>Bank Management</mat-card-title>
</div>
<div *ngIf="openUser">
    <form [formGroup]="FormGroup">
        <div class="row" style="margin-right: 0px; margin-left: 0px; margin-top: 15px;">
            <div class="col-md-12">
                <mat-card>
                    <mat-card-header>
                        <mat-card-title>Bank Records</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <mat-divider></mat-divider>
                        <div>&nbsp;</div>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Bank Name</label>
                                    <input class="form-control" placeholder="Bank Name" formControlName="Bank_Name" required>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Holder Name</label>
                                    <input class="form-control" placeholder="Account Name" formControlName="Account_Name" required>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Account Type</label>
                                    <input class="form-control" placeholder="Account Type" formControlName="Account_Type" required>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Account No</label>
                                    <input class="form-control" placeholder="Account No" formControlName="Account_No" required>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Branch Name</label>
                                    <input class="form-control" placeholder="Branch Name" formControlName="Branch_Name" required>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Bank Address</label>
                                    <input class="form-control" placeholder="Bank Address" formControlName="Bank_Address" required>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>IFSC code</label>
                                    <input class="form-control" placeholder="IFSC code" formControlName="IFSC_code" required>
                                </div>
                            </div>
                            <div class="col-md-12 text-right">
                                <div><br></div>
                                <button mat-raised-button color="warn" (click)="closeUser()" style="margin-right: 15px;">
                            <mat-icon>close</mat-icon> Clear and Close
                        </button>
                                <button mat-raised-button color="primary" [disabled]="inProgress" (click)="saveUser()">
                            <mat-icon>save</mat-icon> {{actionName}} <mat-icon>forward</mat-icon>
                        </button>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </form>
</div>
<div *ngIf="!openUser">
    <mat-divider></mat-divider>
    <div class="row" style="padding: 10px; margin-right:0px; margin-left: 0px">
        <div class="col-md-12 text-right" style="padding-right:0px; padding-left:0px; padding-bottom: 15px;">
            <button mat-raised-button color="primary" (click)="createRecord()"><mat-icon>add_circle</mat-icon> Create New Bank</button>
        </div>
        <div class="col-md-12" style="padding-right:0px; padding-left:0px" *ngIf="allRecords">
            <p-table #dt [value]="allRecords" [paginator]="true" [rows]="100" [showCurrentPageReport]="true" [totalRecords]="totalRecords" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [responsive]="true">
                <ng-template pTemplate="caption">
                    <div style="font-size: 20px;">
                        Banks Records
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div style="text-align: left">
                                <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                                <input type="text" pInputText size="50" placeholder="Search Global filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="ui-helper-clearfix" style="text-align: right">
                                <button type="button" pButton icon="pi pi-file-excel" iconPos="left" label="EXCEL" (click)="dt.exportCSV()" style="margin-right: 0.5em;"></button>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th>
                            Bank Name
                        </th>
                        <th>
                            Account Name
                        </th>
                        <th>
                            Account Type
                        </th>
                        <th>
                            Account No
                        </th>
                        <th>
                            Branch Name
                        </th>
                        <th>
                            Bank Address
                        </th>
                        <th>
                            IFSC code
                        </th>
                        <th style="width: 100px;">
                            Status
                        </th>
                        <th style="width: 120px;">
                            Action
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-record>
                    <tr>
                        <td>
                            {{record.Bank_Name}}
                        </td>
                        <td>
                            {{record.Account_Name}}
                        </td>
                        <td>
                            {{record.Account_Type}}
                        </td>
                        <td>
                            {{record.Account_No}}
                        </td>
                        <td>
                            {{record.Branch_Name}}
                        </td>
                        <td>
                            {{record.Bank_Address}}
                        </td>
                        <td>
                            {{record.IFSC_code}}
                        </td>
                        <td>
                            <button type="button" mat-raised-button color="warn" *ngIf="record.is_Active==0" (click)="changeStatus(record)">Inactive</button>
                            <button type="button" mat-raised-button color="primary" *ngIf="record.is_Active!=0" (click)="changeStatus(record)">Active</button>
                        </td>
                        <td>
                            <button type="button" mat-icon-button (click)="editRecords(record)" color="primary"><mat-icon>edit</mat-icon></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                    There are {{allRecords?.length}} Records
                </ng-template>
            </p-table>
        </div>
    </div>
</div>