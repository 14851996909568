import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpService } from 'src/app/services/http.service';
import { MatDialog } from '@angular/material/dialog';
import { Router, Event, NavigationEnd, NavigationError } from '@angular/router';
import { AlertpopupComponent } from 'src/app/common/alertpopup/alertpopup.component';

@Component({
  selector: 'app-walletmanagement',
  templateUrl: './walletmanagement.component.html',
  styleUrls: ['./walletmanagement.component.css']
})
export class WalletmanagementComponent implements OnInit {

  editId: any = 0;
  FormGroup: FormGroup;
  PopupGroup: FormGroup;
  actionName: any = "Save";
  first = 0;
  rows = 10;
  allRecords: any = [];
  walletHistory: any = [];
  walletBills: any = [];
  totalRecords: any = 0;
  respo: any;
  openWallet: boolean = false;
  openUser: boolean = false;
  inProgress: boolean = false;
  walletNAME: any = "Wallet Name";

  constructor(
    private _formBuilder: FormBuilder,
    private http: HttpService,
    private dialog: MatDialog,
    private router: Router
  ) {

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
          // Show loading indicator
          this.getUserRecords();
      }
      if (event instanceof NavigationError) {
          // Hide loading indicator
          console.log(event.error);
      }
  });
  }
  setFirstLetterToUppercase(string:string):string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  createRecord(){
    this.openUser = true;
  }
  closeUser() {
    this.FormGroup.reset();
    this.openUser = false;
    this.getUserRecords();
  }
  // Data tables
  next() {
    this.first = this.first + this.rows;
  }

  prev() {
      this.first = this.first - this.rows;
  }

  reset() {
      this.first = 0;
  }

  isLastPage(): boolean {
      return this.first === (this.allRecords.length - this.rows);
  }

  isFirstPage(): boolean {
      return this.first === 0;
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
}
  editRecords(record: any){
      this.FormGroup.patchValue({
        Wallet_Name: record.Wallet_Name,
        Credited_Amount: record.Credited_Amount
      });
      this.editId = record.Id;
      this.openUser= true;
      this.actionName = "Update";
  }
  changeStatus(record: any){
    let dialogRef = this.dialog.open(AlertpopupComponent,{
      width: "350px",
      data: { title: 'Sure to change status!', message: "Are you sure, You want to change the status of Wallet.", popup:'confirm'}
    });
    dialogRef.afterClosed().subscribe(result => {
          this.changeConfirm(record);
    });
  }
  changeConfirm(record: any){
    this.inProgress = true;
    record.is_Active = record.is_Active=='0'?'1':'0';
    let formdata = new FormData();
    let firstForm = {
        Id: record.Id,
        is_Active: record.is_Active
    };
    let data = JSON.stringify(firstForm);
    formdata.append('wallet',data);
    this.http.postDataByForm('wallets/saveRecords',formdata)
            .then(data => {
               this.inProgress = false;
                console.log(data);
                this.getUserRecords();
            });
  }
  ngOnInit(): void {
    this.FormGroup = this._formBuilder.group({
      Wallet_Name: ['', Validators.required],
      Credited_Amount: ['', Validators.required],
    });
    this.PopupGroup = this._formBuilder.group({
      WalletId: ['', Validators.required],
      Opening_bal: ['', Validators.required],
      Amount: ['0', Validators.required],
      Closing_bal: ['0', Validators.required],
    }); 
    this.getUserRecords();
  }
  findCloseBal(){
    let closingBal = parseFloat(this.PopupGroup.get('Opening_bal').value) + parseFloat(this.PopupGroup.get('Amount').value);
    this.PopupGroup.get('Closing_bal').setValue(closingBal);
  }
  getUserRecords(){
    this.inProgress = true;
    let formdata = new FormData();
    this.http.postDataByForm('wallets/get_records',formdata)
            .then(data => {
              this.inProgress = false;
              this.respo = data;
              console.log(this.respo);
              if(this.respo.status==1){
                  this.allRecords = this.respo.data.records;
              }
            });
  }
  saveUser(){
    this.inProgress = true;
    let data = this.FormGroup.value;
    data.Created_by = sessionStorage.getItem('userName');
    if(this.editId!=0){
      data.Id = this.editId;
    }
    let formdata = new FormData();
    formdata.append('wallet',JSON.stringify(data));
    this.http.postDataByForm('wallets/saveRecords',formdata)
            .then(data => {
              this.inProgress = false;
              this.respo = data;
              let Action = this.editId==0?"Saved":"Updated";
              if(this.respo.status==1){
                let dialogRef = this.dialog.open(AlertpopupComponent,{
                  width: "350px",
                  data: { title: Action+' successfully', message: this.respo.message, popup:'popup'}
                });
                dialogRef.afterClosed().subscribe(result => {
                    this.getUserRecords();
                    this.FormGroup.reset();
                });         
                if(this.editId!=0){
                  this.openUser = false;
                  this.editId = 0;
                }       
              }
              else {
                let dialogRef = this.dialog.open(AlertpopupComponent,{
                  width: "350px",
                  data: { title: 'Action fail', message: this.respo.message, popup:'popup'}
                });
                dialogRef.afterClosed().subscribe(result => {
                    if(result=='Yes'){
                      this.getUserRecords();
                    }
                });
              }
            });
  }
  popupWallet(record: any){
      console.log(record);
      this.PopupGroup.get('WalletId').setValue(record.Id);
      this.PopupGroup.get('Opening_bal').setValue(record.Credited_Amount);
      this.walletNAME = record.Wallet_Name;
      this.openWallet = true;
      this.getWalletHistory();
  }
  getWalletHistory(){
    this.inProgress = true;
    let formdata = new FormData();
    formdata.append('walletId',this.PopupGroup.get('WalletId').value);
    this.http.postDataByForm('wallets/getWalletHistory',formdata)
            .then(data => {
              this.inProgress = false;
              this.respo = data;
              console.log(this.respo);
              if(this.respo.status==1){
                  this.walletHistory = this.respo.data;
              }
            });
  }
  savePopupWallet(){
    this.inProgress = true;
    let formdata = new FormData();
    let forms = this.PopupGroup.value;
    forms.Created_by = sessionStorage.getItem('userName');
    formdata.append('wallet',JSON.stringify(forms));
    this.http.postDataByForm('wallets/popupWallet',formdata)
            .then(data => {
              this.inProgress = false;
              console.log(data);
              this.getUserRecords();
              this.getWalletHistory();
              this.PopupGroup.get('Opening_bal').setValue(this.PopupGroup.get('Closing_bal').value);
              this.PopupGroup.get("Closing_bal").setValue("0");
              this.PopupGroup.get("Amount").setValue("0");
            });
  }
  closeWallet(){
    this.walletNAME = "Wallet Name";
    this.PopupGroup.reset();
    this.openWallet = false;
  }
  fetchUsed(record: any){
    this.router.navigate(['/user/recharge'],{ queryParams: { walletId : record.Id }})
    // this.inProgress = true;
    // let formdata = new FormData();
    // formdata.append('walletId',record.Id);
    // this.http.postDataByForm('wallets/getWalletHistory',formdata)
    //         .then(data => {
    //           this.inProgress = false;
    //           this.respo = data;
    //           console.log(this.respo);
    //           if(this.respo.status==1){
    //               this.walletBills = this.respo.data;
    //           }
    //         });
  }
}
