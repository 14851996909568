import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { Router } from '@angular/router';
import { AlertpopupComponent } from 'src/app/common/alertpopup/alertpopup.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-paymentoptions',
  templateUrl: './paymentoptions.component.html',
  styleUrls: ['./paymentoptions.component.css']
})
export class PaymentoptionsComponent implements OnInit {

  editId: any = 0;
  FormGroup: FormGroup;
  actionName: any = "Save";
  first = 0;
  rows = 10;
  allRecords: any = [];
  totalRecords: any = 0;
  respo: any;
  openUser: boolean = false;
  inProgress: boolean = false;
  public userRole: string;

  exportColumns: any[];

  constructor(
    private _formBuilder: FormBuilder,
    private http: HttpService,
    private dialog: MatDialog
  ) { }
  setFirstLetterToUppercase(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  createRecord() {
    this.openUser = true;
  }
  closeUser() {
    this.FormGroup.reset();
    this.openUser = false;
    this.getUserRecords();
  }
  // Data tables
  next() {
    this.first = this.first + this.rows;
  }

  prev() {
    this.first = this.first - this.rows;
  }

  reset() {
    this.first = 0;
  }

  isLastPage(): boolean {
    return this.first === (this.allRecords.length - this.rows);
  }

  isFirstPage(): boolean {
    return this.first === 0;
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }
  editRecords(record: any) {
    this.FormGroup.patchValue({
      Account_Name: record.Account_Name,
      Bank_Name: record.Bank_Name,
      Account_Type: record.Account_Type,
      Account_No: record.Account_No,
      Branch_Name: record.Branch_Name,
      Bank_Address: record.Bank_Address,
      IFSC_code: record.IFSC_code
    });
    this.editId = record.Id;
    this.openUser = true;
    this.actionName = "Update";
  }
  changeStatus(record: any) {
    let dialogRef = this.dialog.open(AlertpopupComponent, {
      width: "350px",
      data: { title: 'Sure to change status!', message: "Are you sure, You want to change the status of Bank.", popup: 'confirm' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes') {
        this.changeConfirm(record);
      }
    });
  }
  changeConfirm(record: any) {
    console.log(record);
    this.inProgress = true;
    record.is_Active = record.is_Active == '0' ? '1' : '0';
    let formdata = new FormData();
    let firstForm = {
      Id: record.Id,
      is_Active: record.is_Active
    };
    let data = JSON.stringify(firstForm);
    formdata.append('bank', data);
    this.http.postDataByForm('banks/saveRecords', formdata)
      .then(data => {
        this.inProgress = false;
        console.log(data);
        this.getUserRecords();
      });
  }
  ngOnInit(): void {
    this.userRole = sessionStorage.getItem('userRole');
    this.FormGroup = this._formBuilder.group({
      Bank_Name: ['', Validators.required],
      Account_Name: ['', Validators.required],
      Account_Type: ['', Validators.required],
      Account_No: ['', Validators.required],
      Branch_Name: ['', Validators.required],
      Bank_Address: ['', Validators.required],
      IFSC_code: ['', Validators.required]
    });

    this.getUserRecords();
  }

  getUserRecords() {
    this.inProgress = true;
    let formdata = new FormData();
    this.http.postDataByForm('banks/get_records', formdata)
      .then(data => {
        this.inProgress = false;
        this.respo = data;
        console.log(this.respo);
        if (this.respo.status == 1) {
          if (this.userRole === "Super Admin") {
            this.allRecords = this.respo.data.records;
          } else {
            this.allRecords = this.respo.data.records.filter(t => t.is_Active == 1);
          }
          console.log(this.allRecords);
        }
      });
  }
  saveUser() {
    this.inProgress = true;
    let data = this.FormGroup.value;
    data.Created_by = sessionStorage.getItem('userName');
    if (this.editId != 0) {
      data.Id = this.editId;
    }
    let formdata = new FormData();
    formdata.append('bank', JSON.stringify(data));
    this.http.postDataByForm('banks/saveRecords', formdata)
      .then(data => {
        this.inProgress = false;
        this.respo = data;
        let Action = this.editId == 0 ? "Saved" : "Updated";
        if (this.respo.status == 1) {
          let dialogRef = this.dialog.open(AlertpopupComponent, {
            width: "350px",
            data: { title: Action + ' successfully', message: this.respo.message, popup: 'popup' }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result == 'Yes') {
              this.getUserRecords();
            }
          });
          if (this.editId != 0) {
            this.openUser = false;
            this.editId = 0;
          }
        }
        else {
          let dialogRef = this.dialog.open(AlertpopupComponent, {
            width: "350px",
            data: { title: 'Action fail', message: this.respo.message, popup: 'popup' }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result == 'Yes') {
              this.getUserRecords();
            }
          });
        }
      });
  }
}
