import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpService } from 'src/app/services/http.service';
import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { AlertpopupComponent } from 'src/app/common/alertpopup/alertpopup.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  editId: any = 0;
  userType: any = 'Retailer';
  FormGroup: FormGroup;
  imageSrc: any;
  imageSrc1: any;
  actionName: any = "Save";
  parentType: any = "Distributor";
  first = 0;
  rows = 10;
  allRecords: any = [];
  totalRecords: any = 0;
  respo: any;
  openUser: boolean = false;
  userRole: any = "Admin";
  inProgress: boolean = false;
  cols: any = [];
  exportColumns: any[];
  userByRoles: any = [];
  constructor(
    private _formBuilder: FormBuilder,
    private http: HttpService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.route.params.subscribe(
      params => {
        if (params.userType) {
          this.userType = this.setFirstLetterToUppercase(params.userType);
          this.getUserRecordsByRoles();
        }
      }
    );
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        // Show loading indicator
        this.parentType = this.userType == 'Admin' ? "Super Admin" : (this.userType == 'Distributor' ? "Admin" : "Distributor");
        this.getUserRecords();
      }
      if (event instanceof NavigationError) {
        // Hide loading indicator
        console.log(event.error);
      }
    });
    this.userRole = sessionStorage.getItem('userRole');
    this.parentType = this.userType == 'Admin' ? "Super Admin" : (this.userType == 'Distributor' ? "Admin" : "Distributor");
  }
  setFirstLetterToUppercase(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  createRecord() {
    this.openUser = true;
  }
  closeUser() {
    this.FormGroup.reset();
    this.FormGroup.get("billFormat").setValue("1");
    this.FormGroup.get("Parent_Id").setValue(sessionStorage.getItem('userId'));
    this.imageSrc = null;
    this.imageSrc1 = null;
    this.openUser = false;
    this.getUserRecordsByRoles();
  }
  // Data tables
  next() {
    this.first = this.first + this.rows;
  }

  prev() {
    this.first = this.first - this.rows;
  }

  reset() {
    this.first = 0;
  }

  isLastPage(): boolean {
    return this.first === (this.allRecords.length - this.rows);
  }

  isFirstPage(): boolean {
    return this.first === 0;
  }
  initTable() {
    this.cols = [
      { field: 'merchant_ID', header: 'merchant ID' },
      { field: 'Name', header: 'Name' },
      { field: 'Parent_Name', header: 'Parent Name' },
      { field: 'Address', header: 'Address' },
      { field: 'City', header: 'City' },
      { field: 'Contact_No', header: 'Contact No' },
      { field: 'Email_Id', header: 'Email Id' },
      { field: 'balance', header: 'Balance' },
      { field: 'Register_Date', header: 'Register Date' },
      { field: 'Created_by', header: 'Created by' },
    ];
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }
  editRecords(record: any) {
    this.FormGroup.patchValue({
      Name: record.Name,
      Address: record.Address,
      City: record.City,
      Contact_No: record.Contact_No,
      Email_Id: record.Email_Id,
      Password: record.Password,
      billFormat: record.billFormat,
      Parent_Id: record.Parent_Id
    });
    this.editId = record.Id;
    if(record.Adhar_Card != ""){
      this.imageSrc = this.http.serviceUrl+"adhar-cards/"+record.Adhar_Card;
    }
    if(record.Pan_Card != ""){
      this.imageSrc1 = this.http.serviceUrl+"pan-cards/"+record.Pan_Card;
    }
    this.openUser = true;
    this.actionName = "Update";
  }
  changeStatus(record: any) {
    // if (this.userRole == 'Super Admin') {
      let dialogRef = this.dialog.open(AlertpopupComponent, {
        width: "350px",
        data: { title: 'Sure to change status!', message: "Are you sure, You want to change the status of " + this.userType + ".", popup: 'confirm' }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result == 'Yes') {
          this.changeConfirm(record);
        }
      });
    // }
  }
  changeConfirm(record: any) {
    this.inProgress = true;
    let is_Active = record.is_Active == '0' ? '1' : '0';
    let formdata = new FormData();
    let firstForm = {
      Id: record.Id,
      Updated_by: sessionStorage.getItem('userName'),
      is_Active: is_Active
    };
    let data = JSON.stringify(firstForm);
    formdata.append('user', data);
    this.http.postDataByForm('users/saveUser', formdata)
      .then(data => {
        this.inProgress = false;
        console.log(data);
        this.getUserRecords();
      });
  }
  ngOnInit(): void {
    this.FormGroup = this._formBuilder.group({
      Name: ['', Validators.required],
      Address: ['', Validators.required],
      City: ['', Validators.required],
      Contact_No: ['', [Validators.required, Validators.pattern("[0-9]{10}")]],
      Email_Id: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      Password: ['', Validators.required],
      billFormat: ['1'],
      Parent_Id: sessionStorage.getItem('userId')
    });

    this.getUserRecords();
    this.initTable();
    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));

  }

  onFileChange(event: any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      console.log(event.target.files[0].type);
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result as string;
      };
    }
  }

  onFileBackground(event: any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      console.log(event.target.files[0].type);
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc1 = reader.result as string;
      };
    }
  }

  // BLOB conversation
  private convertBase64ToBlob(base64: string) {
    const info = this.getInfoFromBase64(base64);
    const sliceSize = 512;
    const byteCharacters = window.atob(info.rawBase64);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      byteArrays.push(new Uint8Array(byteNumbers));
    }
    return new Blob(byteArrays, { type: info.mime });
  }
  private getInfoFromBase64(base64: string) {
    const meta = base64.split(',')[0];
    const rawBase64 = base64.split(',')[1].replace(/\s/g, '');
    const mime = /:([^;]+);/.exec(meta)[1];
    const extension = /\/([^;]+);/.exec(meta)[1];
    return {
      mime,
      extension,
      meta,
      rawBase64
    };
  }
  // End of BLOB Conversation

  getUserRecords() {
    this.inProgress = true;
    let formdata = new FormData();
    if(this.userRole == 'Distributor'){
      formdata.append('parentId', sessionStorage.getItem('userId'));
    }
    formdata.append('userRole', this.userType);
    this.http.postDataByForm('users/get_records', formdata)
      .then(data => {
        this.inProgress = false;
        this.respo = data;
        console.log(this.respo);
        if (this.respo.status == 1) {
          this.allRecords = this.respo.data.records;
        }
      });
  }
  getUserRecordsByRoles() {
    this.inProgress = true;
    let formdata = new FormData();
    formdata.append('userRole', this.userType);
    this.http.postDataByForm('users/get_records_roles', formdata)
      .then(data => {
        this.inProgress = false;
        this.respo = data;
        console.log("i")
        console.log(this.respo);
        if (this.respo.status == 1) {
          this.userByRoles = this.respo.data.records;
        }
      });
  }
  saveUser() {
    let data = this.FormGroup.value;
    data.userRole = this.userType;
    // if(this.userRole == 'Distributor'){
    // data.Parent_Id = sessionStorage.getItem('userId');
    // }
    data.Created_by = sessionStorage.getItem('userName');
    if (this.editId != 0) {
      data.Id = this.editId;
    }
    let formdata = new FormData();
    let validForm = 2;
    if (this.editId == 0) {
      validForm = 0;
      if (this.imageSrc) {
        let blob = this.convertBase64ToBlob(this.imageSrc as string);
        formdata.append('adharcard', blob, 'adhar-card.png');
        validForm ++;
      }
      if (this.imageSrc1) {
        let blob = this.convertBase64ToBlob(this.imageSrc1 as string);
        formdata.append('pancard', blob, 'pancard.png');
        validForm ++;
      }
    }
    if(validForm == 2){      
      this.inProgress = true;
      formdata.append('user', JSON.stringify(data));
      this.http.postDataByForm('users/saveUser', formdata)
        .then(data => {
          this.inProgress = false;
          this.respo = data;
          let Action = this.editId == 0 ? "Saved" : "Updated";
          if (this.respo.status == 1) {
            let dialogRef = this.dialog.open(AlertpopupComponent, {
              width: "350px",
              data: { title: Action + ' successfully', message: this.respo.message, popup: 'popup' }
            });
            dialogRef.afterClosed().subscribe(result => {
              this.FormGroup.reset();
              this.FormGroup.get("billFormat").setValue("1");
              this.FormGroup.get("Parent_Id").setValue(sessionStorage.getItem('userId'));
              this.imageSrc = null;
              this.imageSrc1 = null;
              if (this.editId != 0) {
                this.editId = 0;
                this.openUser = false;
              } else {
                this.closeUser();
              }
            });
            this.getUserRecords();
          }
          else {
            let dialogRef = this.dialog.open(AlertpopupComponent, {
              width: "350px",
              data: { title: this.respo.title, message: this.respo.message, popup: 'popup' }
            });
            dialogRef.afterClosed().subscribe(result => {
              if (result == 'Yes') {
                this.getUserRecords();
              }
            });
          }
        })
        .catch((err) => {
          this.dialog.open(AlertpopupComponent, {
            width: "350px",
            data: { title: err.title, message: err.message, popup: 'popup' }
          });
        })
        .finally(() => {
          this.inProgress = false;
        });
      }
      else {
        let dialogRef = this.dialog.open(AlertpopupComponent, {
          width: "350px",
          data: { title: "Adharcard Or Pancard missing", message: "Adharcard and Pancard are required to submit form.", popup: 'popup' }
        });
        dialogRef.afterClosed().subscribe(result => {
        });
      }
  }
  viewHistory(record) {

  }
}
