import { Component, HostListener } from '@angular/core';
// import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
// import { AlertpopupComponent } from './common/alertpopup/alertpopup.component';
// import { HttpService } from './services/http.service';
// import { MessagingService } from './services/messaging.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'HNT Services';

  show: any;
  
  @HostListener('window:beforeunload', ['$event'])
  public onPageUnload($event: BeforeUnloadEvent) {
    // this.logoutNow();
  }

  constructor(
    // private messagingService: MessagingService, 
    // private http: HttpService,
    // private dialog: MatDialog,
    private _router: Router
  ) { }
  
  ngOnInit() {
      // this.messagingService.requestPermission();
      // this.messagingService.receiveMessage();
      // this.show = this.messagingService.currentMessage;
  }
  ngOnDestroy() {
    this.logoutNow();
    // this.http.getDataByPage("users/remove_session")
    //     .then((data: any) => {
    //         console.log(data);
    //     });
    // sessionStorage.removeItem('user');
    // sessionStorage.removeItem('userId');
    // sessionStorage.removeItem('userName');
    // sessionStorage.removeItem('isLogin');
    // sessionStorage.removeItem('Auth-token');
  }
  logoutNow() {
    // let dialogRef = this.dialog.open(AlertpopupComponent, {
    //   width: "350px",
    //   data: { title: 'Sure to exit !', message: "Are you sure, You want to logout from system.", popup: 'confirm' }
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   if (result == 'Yes') {
    //     this.http.getDataByPage("users/remove_session")
    //         .then((data: any) => {
    //             console.log(data);
    //         });
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('userId');
        sessionStorage.removeItem('userName');
        sessionStorage.removeItem('isLogin');
        sessionStorage.removeItem('Auth-token');
        this._router.navigate(['/']);
    // }
    // });
  }
}
