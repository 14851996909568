<app-page-loading [hidden]="!inProgress"></app-page-loading>
<div class="row" style="padding: 10px; margin-right:0px; margin-left: 0px">
    <div class="col-md-8 offset-md-2">
        <h2>Change Password</h2>
        <mat-divider></mat-divider>
    </div>
    <div class="col-md-8 offset-md-2">
        <mat-card>
            <form [formGroup]="userFormgroup">
                <div class="row">
                    <div class="form-group col-md-4">
                        <label>Current Password*</label>
                        <input class="form-control" type="password" placeholder="Current Password" formControlName="CurrentPassword">
                    </div>
                    <div class="form-group col-md-4">
                        <label>New Password*</label>
                        <input class="form-control" type="password" placeholder="New Password" formControlName="Password">
                    </div>
                    <div class="form-group col-md-4">
                        <label>Confirm New Password*</label>
                        <input type="password" class="form-control" placeholder="Confirm New Password" formControlName="CPassword">
                    </div>
                    <div class="form-group col-md-12 text-right">
                        <button mat-raised-button color="primary" [disabled]="!userFormgroup.valid || inProgress" (click)="saveRecord()">
                        Change Password Now <mat-icon>forward</mat-icon>
                    </button>
                    </div>
                </div>
            </form>
        </mat-card>
    </div>
    <div style="clear: both;"></div>
</div>