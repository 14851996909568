<div class="spinner" fxLayoutAlign="center center" fxFlexFill [hidden]="!inProgress">
    <mat-spinner diameter="50"></mat-spinner>
    <p>Please wait, Work on progress...</p>
</div>
<form [formGroup]="firstFormGroup">
<mat-card>
    <mat-card-header>
        <h3>Load Wallet from MOBIKWIK</h3>
    </mat-card-header>
    <mat-card-content>      
        <mat-divider></mat-divider>
        <div>&nbsp;</div>
        <div class="row align-items-center h-100">
        <div class="col-lg-12 col-md-12 mx-auto">  
        <mat-form-field>
            <mat-label>Wallet Id*</mat-label>
            <input matInput class="inputForm" fxFlex="100" placeholder="Wallet Id" type="email" formControlName="wallet_id">
        </mat-form-field>
        </div>
        <div class="col-lg-12 col-md-12 mx-auto">
        <mat-form-field>
            <mat-label>Amount*</mat-label>
            <input matInput class="inputForm" fxFlex="100" placeholder="Amount" type="number" formControlName="amount">
        </mat-form-field>
        </div>
        <div class="col-lg-12 col-md-12 mx-auto">
        <mat-form-field>
            <mat-label>Comment</mat-label>
            <input matInput class="inputForm" fxFlex="100" placeholder="Comment" type="text" formControlName="comment">
        </mat-form-field>
        </div>
        </div>
    </mat-card-content>
    <mat-card-actions style="text-align: right;">
        <button mat-raised-button (click)="closePopup('No')" color="warn">Close</button>
        <button mat-raised-button (click)="loadRequest()" color="primary" [disabled]="!firstFormGroup.valid || inProgress">Load Now</button>
    </mat-card-actions>
</mat-card>
</form>