import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { LazyLoadEvent } from 'primeng/api';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AlertpopupComponent } from 'src/app/common/alertpopup/alertpopup.component';

@Component({
  selector: 'app-allbills',
  templateUrl: './allbills.component.html',
  styleUrls: ['./allbills.component.css']
})
export class AllbillsComponent implements OnInit {
  
  allBills: any = [];
  respo: any;
  cols: any[];
  coldata: any = [];
  first = 0;
  rows = 10;
  datasource: any = [];
  loading: boolean = false;
  totalRecords: number = 0;
  inProgress: boolean = false;
  exportColumns: any[];
  billAll: FormGroup;
  Distributor: any = [];
  Retailer: any = [];
  tType: any = "today";
  payStatus: any = "";

  walletID: any;
  reciepe: any;
  merchant_ID: any = "";
  @ViewChild('btnPrint') btnPrint: ElementRef;
  pdfSrc: any = "";

  constructor(
    private http: HttpService,
    private _formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {
    this.pdfSrc  = this.http.serviceUrl + "pdf-invoices/";
    this.merchant_ID = sessionStorage.getItem('merchant_ID');
    this.billAll = this._formBuilder.group({
      distributor: '',
      retailer: '',
      startDate: '',
      endDate: '',
    });

    // this.route.queryParams.subscribe(param => {
    //   this.walletID = param.walletId;
    //   this.searchBillByWallet();
    // });

    this.getUser('Distributor');
  }
  getUser(user: any) {
    this.inProgress = true;
    let formdata = new FormData();
    formdata.append('userRole', user);
    this.http.postDataByForm('users/get_records', formdata)
      .then(data => {
        this.inProgress = false;
        this.respo = data;
        console.log(this.respo);
        if (this.respo.status == 1) {
          this.Distributor = this.respo.data.records;
        }
      });
  }
  fetchRetailer() {
    this.inProgress = true;
    let formdata = new FormData();
    formdata.append('userRole', 'retailer');
    formdata.append('parentId', this.billAll.get('distributor').value);
    this.http.postDataByForm('users/get_records', formdata)
      .then(data => {
        this.inProgress = false;
        this.respo = data;
        console.log(this.respo);
        if (this.respo.status == 1) {
          this.Retailer = this.respo.data.records;
        }
      });
  }
  searchBills() {
    console.log(this.billAll.value);
    this.tType = "custom";
    let formdata = new FormData();
    let billAll = this.billAll.value;
    let isValid = false;
    this.allBills = [];
    this.totalRecords = 0;

    // "User_Id": sessionStorage.getItem('userId'),

    if(billAll.startDate != "" && billAll.endDate != ""){
      isValid = true;
      let condition = { 'Pay_Status': this.payStatus, "startDate": billAll.startDate, 'endDate': billAll.endDate};
      formdata.append('params', JSON.stringify(condition));
    }
    else {
      let dialogRef = this.dialog.open(AlertpopupComponent, {
        width: "350px",
        data: { title: 'Date required!', message: "Date range are required for custom summary", popup: 'popup' }
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }

    if(isValid){
      this.inProgress = true;
      let userRole = sessionStorage.getItem('userRole');
      if(userRole == 'Super Admin'){
        delete billAll.retailer;
        delete billAll.distributor;
      }
      formdata.append('params', JSON.stringify(billAll));
      formdata.append('period', this.tType);
      this.http.postDataByForm('payrecharge/search_list', formdata)
        .then(data => {
          console.log(data);
          this.respo = data;
          this.inProgress = false;
          if (this.respo.status == 1) {
            //this.datasource = this.respo.data.records;
            this.allBills = this.respo.data.records;
            this.totalRecords = this.datasource.length;
            for (let bills of this.allBills) {
              bills.paidAmount = parseFloat(bills.Amount) + parseFloat(bills.charge_amount);
                if (bills.isDelete == 1) {
                  bills.status = 'Deleted';
                } else if (bills.isDelete == 0) {
                  if (bills.Pay_Status == 0) {
                    bills.status = 'Pending';
                  } else if (bills.Pay_Status == 1) {
                    bills.status = 'Success';
                  } else if (bills.Pay_Status == 2) {
                    bills.status = 'Operator Failed';
                  } else if (bills.Pay_Status == 3) {
                    bills.status = 'System Failed';
                  } else if (bills.Pay_Status == 4) {
                    bills.status = 'Under Process';
                  } else if (bills.Pay_Status == 5) {
                    bills.status = 'Refunded';
                  } else if (bills.Pay_Status == 6) {
                    bills.status = 'Under Process';
                  }
                }
              }
          }
          else {
            this.allBills = [];
            this.totalRecords = 0;
          }
        });
      }
  }
  getStatus(event: any){
    // console.log(event.target.value);
    // this.inProgress = true;
    // if(event.target.value!=''){
      let formdata = new FormData();
      let billAll = this.billAll.value;
      this.payStatus = event.target.value;
      let isValid = false;
      // "User_Id": sessionStorage.getItem('userId'),
      if(this.tType == 'custom'){
        if(billAll.startDate != "" && billAll.endDate != ""){
          isValid = true;
          let condition = { 'Pay_Status': this.payStatus, "startDate": billAll.startDate, 'endDate': billAll.endDate};
          formdata.append('params', JSON.stringify(condition));
        }
        else {
          let dialogRef = this.dialog.open(AlertpopupComponent, {
            width: "350px",
            data: { title: 'Date required!', message: "Date range are required for custom summary", popup: 'popup' }
          });
          dialogRef.afterClosed().subscribe(result => {
          });
        }
      }
      else {
        isValid = true;
        formdata.append('period', this.tType);
        let condition = {'Pay_Status':event.target.value};
        formdata.append('params', JSON.stringify(condition));
      }
      if(isValid){
        this.inProgress = true;
        // formdata.append("userId", sessionStorage.getItem('userId'));
        this.http.postDataByForm('payrecharge/search_list', formdata)
          .then(data => {
            // console.log(data);
            this.respo = data;            
            this.inProgress = false;
            if (this.respo.status == 1) {
              //this.datasource = this.respo.data.records;
              this.allBills = this.respo.data.records;
              this.totalRecords = this.datasource.length;
              for (let bills of this.allBills) {
                bills.paidAmount = parseFloat(bills.Amount) + parseFloat(bills.charge_amount);
                if (bills.isDelete == 1) {
                  bills.status = 'Deleted';
                } else if (bills.isDelete == 0) {
                  if (bills.Pay_Status == 0) {
                    bills.status = 'Pending';
                  } else if (bills.Pay_Status == 1) {
                    bills.status = 'Success';
                  } else if (bills.Pay_Status == 2) {
                    bills.status = 'Operator Failed';
                  } else if (bills.Pay_Status == 3) {
                    bills.status = 'System Failed';
                  } else if (bills.Pay_Status == 4) {
                    bills.status = 'Under Process';
                  } else if (bills.Pay_Status == 5) {
                    bills.status = 'Refunded';
                  } else if (bills.Pay_Status == 6) {
                    bills.status = 'Under Process';
                  }
                }
                // console.log(bills);
              }
            }
            else {
              this.allBills = [];
              this.totalRecords = 0;
            }
          });
        // }
        // else {
        //   this.getDetails();      
        // }
      }
  }
  searchBillByWallet() {
    this.inProgress = true;
    let formdata = new FormData();
    formdata.append('walletId', this.walletID);
    this.http.postDataByForm('payrecharge/get_list', formdata)
      .then(data => {
        console.log(data);
        this.respo = data;
        if (this.respo.status == 1) {
          this.inProgress = false;
          // this.datasource = this.respo.data.records;
          this.allBills = this.respo.data.records;
          this.totalRecords = this.datasource.length;
        }
      });
  }
  ngOnInit(): void {
    // if (!this.walletID) {
      this.getDetails();
    // }
    this.initTable();

    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));

    this.loading = false;
  }
  initTable() {
    this.cols = [
      // { field: 'Id', header: 'Id' },
      // { field: 'Order_No', header: 'Order No.' },
      { field: 'TXN_Id', header: 'TXN Id' },
      { field: 'Recharge_Type', header: 'Service Type' },
      { field: 'Invoice_No', header: 'Invoice No' },
      { field: 'paidAmount', header: 'Amount' },
      { field: 'FinalBal', header: 'Balance' },
      { field: 'Customer_Name', header: 'Customer Name' },
      { field: 'Customer_Phone', header: 'Customer Phone' },
      { field: 'Name', header: 'Retailer Name' },
      { field: 'bbpsReferenceNumber', header: 'BBPS Ref No.' },
      { field: 'Due_Date', header: 'Due Date' },
      { field: 'Recharge_Date', header: 'Recharge Date' },
      { field: 'SendBB', header: 'BB Status' },
      { field: 'status', header: 'Status' },
    ];
    this.coldata = [
      // { field: 'Id', header: 'Id' },
      // { field: 'Order_No', header: 'Order No.' },
      // { field: 'TXN_Id', header: 'TXN Id' },
      { field: 'Recharge_Type', header: 'Service Type' },
      { field: 'Invoice_No', header: 'Invoice No' },
      { field: 'paidAmount', header: 'Amount' },
      { field: 'FinalBal', header: 'Balance' },
      { field: 'Customer_Name', header: 'Customer Name' },
      { field: 'Customer_Phone', header: 'Customer Phone' },
      { field: 'Name', header: 'Retailer Name' },
      { field: 'bbpsReferenceNumber', header: 'BBPS Ref No.' },
      { field: 'Due_Date', header: 'Due Date' },
      // { field: 'Recharge_Date', header: 'Recharge Date' },
      // { field: 'status', header: 'Status' },
    ];
  }

  loadCarsLazy(event: LazyLoadEvent) {
    this.loading = true;

    //in a real application, make a remote request to load data using state metadata from event
    //event.first = First row offset
    //event.rows = Number of rows per page
    //event.sortField = Field name to sort with
    //event.sortOrder = Sort order as number, 1 for asc and -1 for dec
    //filters: FilterMetadata object having field as key and filter value, filter matchMode as value

    //imitate db connection over a network
    setTimeout(() => {
      if (this.datasource) {
        this.allBills = this.datasource.slice(event.first, (event.first + event.rows));
        this.loading = false;
      }
    }, 1000);
  }

  next() {
    this.first = this.first + this.rows;
  }

  prev() {
    this.first = this.first - this.rows;
  }

  reset() {
    this.first = 0;
  }

  isLastPage(): boolean {
    return this.first === (this.allBills.length - this.rows);
  }

  isFirstPage(): boolean {
    return this.first === 0;
  }

  //   exportPdf() {
  //     import("jspdf").then(jsPDF => {
  //         import("jspdf-autotable").then(x => {
  //             const doc = new jsPDF.default(0,0);
  //             doc.autoTable(this.exportColumns, this.allBills);
  //             doc.save('primengTable.pdf');
  //         })
  //     })
  // }

  // exportExcel() {
  //     import("xlsx").then(xlsx => {
  //         const worksheet = xlsx.utils.json_to_sheet(this.getDetails());
  //         const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
  //         const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
  //         this.saveAsExcelFile(excelBuffer, "primengTable");
  //     });
  // }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }
  getDetails() {
    this.inProgress = true;
    let formdata = new FormData();
    formdata.append('period', this.tType);
    this.http.postDataByForm('payrecharge/get_list', formdata)
      .then(data => {
        console.log(data);
        this.respo = data;
        if (this.respo.status == 1) {
          this.inProgress = false;
          //this.datasource = this.respo.data.records;
          this.allBills = this.respo.data.records;
          this.totalRecords = this.datasource.length;
          for (let bills of this.allBills) {
            bills.paidAmount = parseFloat(bills.Amount) + parseFloat(bills.charge_amount);
            /** BB status */
            bills.SendBB = bills.isSendBB == '1'?'Yes':'No';
            /** Others */
            if (bills.isDelete == 1) {
              bills.status = 'Deleted';
            } else if (bills.isDelete == 0) {
              if (bills.Pay_Status == 0) {
                bills.status = 'Pending';
              } else if (bills.Pay_Status == 1) {
                bills.status = 'Success';
              } else if (bills.Pay_Status == 2) {
                bills.status = 'Operator Failed';
              } else if (bills.Pay_Status == 3) {
                bills.status = 'System Failed';
              } else if (bills.Pay_Status == 4) {
                bills.status = 'Under Process';
              } else if (bills.Pay_Status == 5) {
                bills.status = 'Refunded';
              } else if (bills.Pay_Status == 6) {
                bills.status = 'Under Process';
              }
            }
            // console.log(bills);
          }
        }
        else {
          this.allBills = [];
          this.totalRecords = 0;
        }
      });
  }
  changeType(type: any) {
    this.tType = type;
    this.payStatus = "";
    this.allBills = [];
    this.totalRecords = 0;
    if(type != 'custom'){
      this.getDetails();
    }
  }
  printBill(bills: any) {
    this.reciepe = bills;
    console.log(bills);
    let timer = setTimeout(() => {
      this.openPrint();
      clearTimeout(timer);
    }, 1000);
  }
  openPrint() {
    console.log(this.reciepe);
    let inputElement: HTMLElement = this.btnPrint.nativeElement as HTMLElement;
    inputElement.click();
  }
}
