<app-page-loading [hidden]="!inProgress"></app-page-loading>
<div fxLayoutAlign="center start" fxLayout="row" style="background-color: #3f51b594;" fxFlexFill>
    <div fxFlex="25">
        <mat-card class="box-design">
            <h2 style="text-align: right;">Biller Details</h2>
            <mat-divider></mat-divider>
            <form [formGroup]="firstFormGroup">
                <mat-form-field class="example-full-width">
                    <mat-label>Biller Name</mat-label>
                    <input matInput formControlName="billerName">
                </mat-form-field>
                <mat-form-field class="example-full-width">
                    <mat-label>Biller City</mat-label>
                    <input matInput formControlName="billerCity">
                </mat-form-field>
                <mat-form-field class="example-full-width">
                    <mat-label>Biller Code</mat-label>
                    <input matInput formControlName="billerCode">
                </mat-form-field>
                <mat-form-field class="example-full-width">
                    <mat-label>Required Params</mat-label>
                    <textarea matInput formControlName="amount"></textarea>
                </mat-form-field>
                <mat-form-field class="example-full-width">
                    <mat-label>Is Active</mat-label>
                    <input type="checkbox" formControlName="isActive" value="1">
                </mat-form-field>
                <div fxLayout="row" fxLayoutGap="15px" style="margin-bottom: 15px;">
                    <button type="reset" mat-stroked-button color="primary">Clear</button>
                    <button type="submit" [disabled]="!firstFormGroup.valid || inProgress" (click)="addBillerNow()" mat-raised-button color="primary">Add Biller</button>
                </div>
            </form>
        </mat-card>
    </div>
    <div fxFlex="75" fxFlexFill style="max-height: 80vh; overflow-y: auto;">
        <mat-card class="box-design">
            <div class="row">
                <div class="col-md-4">
                    <h2>Biller List</h2>
                </div>
            </div>
            <div style="clear: both;"></div>
            <mat-divider></mat-divider>
            <div class="rechargecart">
                <table width="100%" cellpadding="10" cellspacing="0">
                    <thead>
                        <tr>
                            <th>
                                Transaction No.
                            </th>
                            <th>
                                Recharge Type
                            </th>
                            <th>
                                Invoice Number
                            </th>
                            <th>
                                Customer Name
                            </th>
                            <th>
                                Amount
                            </th>
                            <th>
                                Due Date
                            </th>
                            <th>
                                Action
                            </th>
                        </tr>
                    </thead>
                    <!-- <tbody *ngIf="billers.length>0">
                        <tr *ngFor="let biller of billers, let i=index">
                            <td>{{recharge.transactionNo}}</td>
                            <td>{{recharge.recharge_type}}</td>
                            <td>{{recharge.invoice_no}}</td>
                            <td>{{recharge.customer_name}}</td>
                            <td>{{recharge.amount}}</td>
                            <td>{{recharge.due_date}}</td>
                            <td>
                                <button type="button" mat-mini-fab (click)="removeCart(i)" color="warn"><mat-icon>delete_sweep</mat-icon></button>
                            </td>
                        </tr>
                    </tbody> -->
                </table>
            </div>
        </mat-card>
    </div>
</div>