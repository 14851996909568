import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SidenavService } from 'src/app/services/sidenav.service';
import { AlertpopupComponent } from '../alertpopup/alertpopup.component';

@Component({
  selector: 'app-add-beneficiary',
  templateUrl: './add-beneficiary.component.html',
  styleUrls: ['./add-beneficiary.component.css']
})
export class AddBeneficiaryComponent {

  beneName: any = "Beneficiary";
  beneACCno: any = "0000";
  sendMoney: FormGroup;
  paymode: any = '1';
  convience_fees: any = 0;
  total_amount: any = 0;
  remainValue: any = 0;

  constructor(
      @Inject(MAT_DIALOG_DATA) data: any, 
      private dialog: MatDialog,
      public dialogRef: MatDialogRef<AddBeneficiaryComponent>,
      private _formBuilder: FormBuilder,
      private locationService: SidenavService
  ) {
      console.log(data);
      let beneficiary = JSON.parse(data.beneficiary);
      this.beneName = beneficiary.BENENAME;
      this.beneACCno = beneficiary.ACCNO;
      this.remainValue = data.Limit;

      this.sendMoney = this._formBuilder.group({
        location: [''],
        BENENAME: [''],
        ACCNO: [''],
        BENEID: [''],
        AMT: ['', Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9]+') 
        ])],
        orginalAmount: [''],
        TRNTYPE: ['1'],
        IMPS_SCHEDULE: ['1'],
        CHN: ['WEB'],
        CUR: ['INR'],
        AG_LAT: [''],
        AG_LONG: ['']
      });
      this.sendMoney.get("BENENAME").setValue(this.beneName);
      this.sendMoney.get("BENEID").setValue(beneficiary.BENEID);
      this.sendMoney.get("ACCNO").setValue(beneficiary.ACCNO);

      this.locationService.getPosition().then(pos=>
      {
          // console.log(`Positon: ${pos.lng} ${pos.lat}`);
          this.sendMoney.get('AG_LAT').setValue(pos.lng);
          this.sendMoney.get('AG_LONG').setValue(pos.lat);
          this.sendMoney.get('location').setValue(pos.lng+", "+pos.lat);
      });
  }
  countFees(event: any){
    let amount = event.target.value;
    if(amount < 100){
      let dialogRef = this.dialog.open(AlertpopupComponent,{
        width: "350px",
        data: { title: 'Less value!', message: "Amount must have greater or equals to 100", popup:'popup'}
      });
      dialogRef.afterClosed().subscribe(result => {
          this.sendMoney.get('AMT').setValue(0);
      });
      this.convience_fees = 0;
      this.total_amount = 0;
    }
    // else if(amount > 5000){
    //   let dialogRef = this.dialog.open(AlertpopupComponent,{
    //     width: "350px",
    //     data: { title: 'Exceed value!', message: "Amount must have less or equals to 5000", popup:'popup'}
    //   });
    //   dialogRef.afterClosed().subscribe(result => {
    //       this.sendMoney.get('AMT').setValue(0);
    //   });
    //   this.convience_fees = 0;
    //   this.total_amount = 0;
    // }
    else if(parseFloat(this.remainValue) < amount){
      let dialogRef = this.dialog.open(AlertpopupComponent,{
        width: "350px",
        data: { title: 'Limit reached', message: "Monthly limit reached. Limit is "+this.remainValue, popup:'popup'}
      });
      dialogRef.afterClosed().subscribe(result => {
          this.sendMoney.get('AMT').setValue(0);
      });
      this.convience_fees = 0;
      this.total_amount = 0;
    }
    else {
      let onePercent = parseFloat(amount)*0.01;
      this.convience_fees = onePercent<=10?10:onePercent.toFixed(2);
      this.total_amount = (parseFloat(amount) + parseFloat(this.convience_fees)).toFixed(2);
    }
    this.sendMoney.get('orginalAmount').setValue(this.total_amount);
    console.log(event);
  }
  changeMode(payType: any){
    console.log(payType);
    this.paymode = payType;
    this.sendMoney.get('TRNTYPE').setValue(payType);
    this.sendMoney.get('IMPS_SCHEDULE').setValue(payType);
  }
  closeDialog(respo: any = 'reject') {
    if(respo == 'confirm'){
        respo = this.sendMoney.value;
    }
    this.dialogRef.close(respo);
  }
}
