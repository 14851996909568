<div class="spinner" fxLayoutAlign="center center" fxFlexFill [hidden]="!inProgress">
    <mat-spinner diameter="75"></mat-spinner>
    <!-- <p>Please wait, Work on progress...</p> -->
    <img src="../../../favicon.ico" style="height:67px; position: absolute;">
    <!-- <div class="dot-collision"></div> -->
</div>
<app-header (toggleSidebarForMenu)="sideBarToggle($event)" class="header header-first"></app-header>
<mat-drawer-container>
    <mat-drawer mode="{{drawerMode}}" [opened]="sideBarOpen">
        <app-sidebar (toggleSidebarForMenu)="sideBarToggle($event)"></app-sidebar>
    </mat-drawer>
    <mat-drawer-content>
        <router-outlet></router-outlet>
    </mat-drawer-content>
</mat-drawer-container>
<div *ngIf="isChatbox" class="chat-support">
    <div class="chat-box">
        <ul class="chat-tabs">
            <li [ngClass]="{'active': tabname=='txn'}" (click)="changeTab('txn')">By TXN No</li>
            <li [ngClass]="{'active': tabname=='service'}" (click)="changeTab('service')">By Service No</li>
        </ul>
        <div class="p-3">
            <div *ngIf="tabname=='txn'">
                <h3 class="mb-2">Give your TXN No</h3>
                <div class="row">
                    <div class="col-7">
                        <form [formGroup]="firstFormGroup">
                            <input matInput class="inputForm form-control" placeholder="TXN No" type="text" formControlName="txnNo">
                        </form>
                    </div>
                    <div class="col-5">
                        <button type="button" mat-raised-button (click)="searchBills()" [disabled]="(!firstFormGroup.valid) || inProgress" color="primary">Get Details</button>
                    </div>
                </div>
            </div>
            <div *ngIf="tabname=='service'">
                <form [formGroup]="formGroup">
                    <div class="row">
                        <div class="col-5">
                            <h3 class="mb-2">Your Services</h3>
                        </div>
                        <div class="col-7 mb-2">
                            <mat-select class="form-control" formControlName="recharge_by" [disabled]="inProgress" (selectionChange)="getBillers($event)" matNativeControl>
                                <mat-option *ngFor="let service of services" value="{{service.type}}">{{service.title}}</mat-option>
                            </mat-select>
                        </div>
                        <div class="col-5" [hidden]="servType=='dmt'">
                            <h3 class="mb-2">Service Provider</h3>
                        </div>
                        <div class="col-7 mb-2" [hidden]="servType=='dmt'">
                            <mat-select class="form-control" formControlName="Recharge_Type" [disabled]="inProgress" matNativeControl>
                                <mat-option *ngFor="let biller of billers" [value]="biller.billerName+', '+biller.city">{{biller.billerName}}</mat-option>
                            </mat-select>
                        </div>
                        <div class="col-5">
                            <h3 class="mb-2">Service No</h3>
                        </div>
                        <div class="col-7 mb-2">
                            <input matInput class="inputForm form-control" placeholder="Service No" type="text" formControlName="Invoice_No">
                        </div>
                        <div class="col-12 text-center">
                            <button type="button" mat-raised-button (click)="getLast(3)" [disabled]="(!formGroup.valid) || inProgress" class="mr-2" color="primary">Get Last 3</button>
                            <button type="button" mat-raised-button (click)="getLast()" [disabled]="(!formGroup.valid) || inProgress" color="primary">Get Latest</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="p-3" *ngIf="allBills.length > 0">
            <mat-card *ngFor="let bill of allBills" class="mb-3">
                <div class="row">
                    <div class="col-12" style="font-weight: bold;">
                        {{bill.Recharge_Type}}
                    </div>
                    <div class="col-12">
                        <strong>Name :</strong> {{bill.Customer_Name}}
                    </div>
                    <div class="col-12">
                        <strong>Contact :</strong> {{bill.Customer_Phone}}
                    </div>
                    <div class="col-12">
                        <strong>Service No :</strong> {{bill.Invoice_No}}
                    </div>
                    <div class="col-12">
                        <strong>Due Date :</strong> {{bill.Due_Date | date: 'd-M-y'}}
                    </div>
                    <div class="col-12">
                        <strong>Status :</strong> {{bill.status}}
                    </div>
                    <div class="col-12">
                        <strong>Recharge Date :</strong> {{bill.Recharge_Date | date: 'd-M-y H:m:s'}}
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
</div>
<button mat-fab color="primary" (click)="openChatBox()" style="position: fixed; bottom: 10px; right: 25px; z-index: 99;" aria-label="Example icon button with a delete icon">
    <mat-icon *ngIf="!isChatbox">contact_support</mat-icon>
    <mat-icon *ngIf="isChatbox">close</mat-icon>
</button>
<app-footer></app-footer>