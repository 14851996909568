<h1 mat-dialog-title>Send Money to {{beneName}} : {{beneACCno}}</h1>
<div mat-dialog-content>
    <div class="row">
        <div class="col-12">
            <form [formGroup]="sendMoney">
                <div class="example-full-width">
                    <mat-label>Transaction Amount*</mat-label>
                    <input class="form-control" min="1" max="25000" (change)="countFees($event)" formControlName="AMT" type="number" placeholder="Enter Amount">
                </div>
                <div class="example-full-width mt-3 mb-3">
                    <mat-label>Transaction By*</mat-label>
                    <div class="row">
                        <div class="col-6">
                            <button type="button" (click)="changeMode('1')" [ngClass]="{'btn-info': paymode==='1'}" class="btn btn-block" style="padding: 3px 10px;">
                                <i class="fa" [ngClass]="{'fa-check': paymode==='1'}"></i> IMPS
                            </button>
                        </div>
                        <div class="col-6">
                            <button type="button" (click)="changeMode('0')" [ngClass]="{'btn-info': paymode==='0'}" class="btn btn-block" style="padding: 3px 10px;">
                                <i class="fa" [ngClass]="{'fa-check': paymode==='0'}"></i> NEFT
                            </button>
                        </div>
                    </div>
                </div>
                <div class="example-full-width mb-3">
                    <mat-label>Convience Fees*</mat-label>
                    <div><strong>{{convience_fees | number : '1.2-2'}}</strong></div>
                </div>
                <div class="example-full-width mb-3">
                    <mat-label>Collective Amount*</mat-label>
                    <div style="font-size: 1.5rem; color: #990000"><strong>{{total_amount | number : '1.2-2'}}</strong></div>
                    <!-- <input class="form-control" min="1" max="25000" formControlName="AMT" type="number" placeholder="Enter Amount"> -->
                </div>
            </form>
        </div>
    </div>
</div>
<div class="pt-4 text-right">
    <button (click)="closeDialog('confirm')" [disabled]="!sendMoney.valid" class="btn btn-info mr-2" style="padding: 3px 10px;">Confirm</button>
    <button (click)="closeDialog('reject')" class="btn btn-danger" style="padding: 3px 10px;">Reject</button>
</div>