import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpService } from 'src/app/services/http.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertpopupComponent } from 'src/app/common/alertpopup/alertpopup.component';
import { ShareserviceService } from 'src/app/services/shareservice.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pushwallet',
  templateUrl: './pushwallet.component.html',
  styleUrls: ['./pushwallet.component.css']
})
export class PushwalletComponent implements OnInit {
  paymentTypes: any = [];

  editId: any = 0;
  FormGroup: FormGroup;
  actionName: any = "Save";
  first = 0;
  rows = 10;
  allRecords: any = [];
  Banks: any = [];
  totalRecords: any = 0;
  respo: any;
  openUser: boolean = false;
  inProgress: boolean = false;
  Beneficiary_Bank: any;
  isOnline: boolean = false;
  isBankSelecter: boolean = false;
  selectedProfileImage: any;
  exportColumns: any[];
  imageSrc: any;
  recipePath: any = "";
  userRole: any = "Retailer";
  isCreditPage: boolean = false;
  timer: any;
  cols: any = [];
  userId: any;

  constructor(
    private _formBuilder: FormBuilder,
    private http: HttpService,
    private dialog: MatDialog,
    private shareService: ShareserviceService,
    private route: ActivatedRoute
  ) {
    this.isCreditPage = false;
    this.paymentTypes = [{ type: 'Online' }, { type: 'Cash' }];
    this.recipePath = this.http.serviceUrl + "Receipt/";
    this.userRole = sessionStorage.getItem('userRole');
    this.userId = sessionStorage.getItem('userId');
    console.log(this.userRole);
    this.FormGroup = this._formBuilder.group({
      Pay_mode: ['', Validators.required],
      Amount: ['', Validators.required],
      Beneficiary_Bank: '',
      Beneficiary_Account: '',
      Beneficiary_IFSC: '',
      Remitter_Name: ['', Validators.required],
      Transaction_Date: '',
      Txn_Reference: ['', Validators.required],
      Remark: ''
    });

    this.route.paramMap.subscribe(params => {
      console.log(params.get('paidby'));
      if (params.get('paidby') == 'credit') {
        this.paymentTypes = [{ type: 'Credit' }];
        this.isCreditPage = true;
        this.imageSrc = true;
        this.isBankSelecter = true;
        this.isOnline = false;
        this.FormGroup.get('Txn_Reference').setValue('N/A');
        this.FormGroup.get('Pay_mode').setValue('Credit');
      }
      // let paramdata = JSON.parse(params.);
      // if(paramdata.paidby==='credit'){
      //     this.paymentTypes = [{type:'Credit'}];
      //     this.isCreditPage = true;
      // }
    });
  }

  initTable() {
    this.cols = [
      // { field: 'Id', header: 'Id' },
      { field: 'requestNo', header: 'Request No.' },
      { field: 'Pay_mode', header: 'Payment Mode' },
      { field: 'Amount', header: 'Amount' },
      { field: 'Remitter_Name', header: 'Remitter Name' },
      { field: 'Txn_Reference', header: 'Txn Reference' },
      { field: 'Created_on', header: 'Transaction Date' },
      // { field: 'Customer_Phone', header: 'Customer Phone' },
      { field: 'Remark', header: 'Remark' },
      { field: 'Status', header: 'Status' },
    ];
  }

  setFirstLetterToUppercase(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  createRecord() {
    this.openUser = true;
  }
  closeUser() {
    this.FormGroup.reset();
    this.openUser = false;
    this.getUserRecords();
  }
  // Data tables
  next() {
    this.first = this.first + this.rows;
  }

  prev() {
    this.first = this.first - this.rows;
  }

  reset() {
    this.first = 0;
  }

  isLastPage(): boolean {
    return this.first === (this.allRecords.length - this.rows);
  }

  isFirstPage(): boolean {
    return this.first === 0;
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }
  ngOnInit(): void {
    this.FormGroup.get('Remitter_Name').setValue(sessionStorage.getItem('userName'));
    this.getUserRecords();
    this.getBanks();
    this.initTable();
    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
  }
  startProcessTimer() {
    this.timer = setInterval(() => {
      clearInterval(this.timer);
      let dialogRef = this.dialog.open(AlertpopupComponent, {
        width: "350px",
        data: { title: "Action fail!", message: "Process fail. please, do it further. It may be due to slow internet or server error. Please, try again..", popup: 'popup' }
      });
      dialogRef.afterClosed().subscribe(result => {
      });
      this.inProgress = false;
    }, 1000);
  }
  getUserRecords() {
    // this.startProcessTimer();
    this.inProgress = true;
    let data: any = { Created_by: '', userId: '', userRole: 'Retailer', pageType: 'NonCredit' };
    data.Created_by = sessionStorage.getItem('userName');
    data.userId = sessionStorage.getItem('userId');
    data.userRole = sessionStorage.getItem('userRole');
    data.pageType = (this.isCreditPage) ? "Credit" : "NonCredit";
    let formdata = new FormData();
    formdata.append('request', JSON.stringify(data));
    this.http.postDataByForm('payrequest/get_records', formdata)
      .then(data => {
        this.inProgress = false;
        this.respo = data;
        console.log(this.respo);
        if (this.respo.status == 1) {
          this.allRecords = this.respo.data.records;
          // console.log(this.allRecords);
          this.totalRecords = this.allRecords.length;
        }
      })
      .catch(err => {
        this.inProgress = false;
        let dialogRef = this.dialog.open(AlertpopupComponent, {
          width: "350px",
          data: { title: err.statusText, message: "Unknown Error, Please, check your internet connection not available or internet is down", popup: 'popup' }
        });
        dialogRef.afterClosed().subscribe(result => {
        });
      });
  }
  getBanks() {
    this.inProgress = true;
    let formdata = new FormData();
    formdata.append('is_Active', "1");
    this.http.postDataByForm('banks/get_records', formdata)
      .then(data => {
        this.inProgress = false;
        this.respo = data;
        // console.log(this.respo);
        if (this.respo.status == 1) {
          if (this.userRole === "Super Admin") {
            this.Banks = this.respo.data.records;
          } else {
            this.Banks = this.respo.data.records.filter(t => t.is_Active == 1);
          }
          console.log(this.Banks);
        }
      })
      .catch(err => {
        this.inProgress = false;
        let dialogRef = this.dialog.open(AlertpopupComponent, {
          width: "350px",
          data: { title: err.statusText, message: "Unknown Error, Please, check your internet connection not available or internet is down", popup: 'popup' }
        });
        dialogRef.afterClosed().subscribe(result => {
        });
      });
  }
  checkMode() {
    let payMode = this.FormGroup.get('Pay_mode').value;
    if (payMode == 'Online') {
      this.isOnline = true;
      this.isBankSelecter = true;
    }
    else {
      this.isOnline = false;
      this.isBankSelecter = false;
    }
  }
  onFileChange(event: any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      console.log(event.target.files[0].type);
      this.selectedProfileImage = <File>event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result as string;
      };
    }
  }
  getBankDetails() {
    let bank = this.FormGroup.get('Beneficiary_Bank').value;
    console.log(bank);
    this.isBankSelecter = true;
    this.Banks.forEach(element => {
      if (element.Id == bank) {
        this.Beneficiary_Bank = element.Bank_Name;
        this.FormGroup.get('Beneficiary_Account').setValue(element.Account_No);
        this.FormGroup.get('Beneficiary_IFSC').setValue(element.IFSC_code);
      }
    });
  }
  giveBalance(record: any) {
    let dialogRef = this.dialog.open(AlertpopupComponent, {
      width: "350px",
      data: { title: "Are you sure ?", message: "Are you sure to do the action?", buttonNo: "Reject", buttonYes: "Accept", popup: 'confirm' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes') {
        let currentbalance = sessionStorage.getItem('balance');
        if (parseFloat(record.Amount) > parseFloat(currentbalance)) {
          let dialogRef = this.dialog.open(AlertpopupComponent, {
            width: "350px",
            data: { title: "Insufficient balance!", message: "Insufficient balance in your wallet. Kindly topup your wallet.", popup: 'popup' }
          });
          dialogRef.afterClosed().subscribe(result => {
          });
        }
        else {
          this.updateStatus(record, "Accepted");
        }
      }
      else if (result == 'No') {
        this.updateStatus(record, "Rejected");
      }
    });
  }
  clearCredit(record: any) {
    let dialogRef = this.dialog.open(AlertpopupComponent, {
      width: "350px",
      data: { title: "Are you sure ?", message: "Are you sure to clear credit?", buttonNo: "No", buttonYes: "Yes", popup: 'confirm' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes') {
        this.updateStatus(record, "Clear");
      }
    });
  }
  updateStatus(record: any, status: any) {
    this.inProgress = true;
    let data = record;
    data.Status = status;
    data.Credited_by = sessionStorage.getItem('userName');
    let formdata = new FormData();
    formdata.append('request', JSON.stringify(data));
    formdata.append('userId', sessionStorage.getItem('userId'));
    this.http.postDataByForm('payrequest/saveRecords', formdata)
      .then(data => {
        this.inProgress = false;
        this.respo = data;
        let Action = this.editId == 0 ? "Saved" : "Updated";
        if (this.respo.status == 1) {
          let dialogRef = this.dialog.open(AlertpopupComponent, {
            width: "350px",
            data: { title: '', message: this.respo.message, popup: 'popup' }
          });
          dialogRef.afterClosed().subscribe(result => {
            this.getUserRecords();
            let currentBalance = this.respo.data.balance;
            console.log(currentBalance);
            sessionStorage.setItem('balance', currentBalance.toString());
            this.shareService.accountBalance.next(currentBalance);
          });
        }
        else {
          let dialogRef = this.dialog.open(AlertpopupComponent, {
            width: "350px",
            data: { title: 'Action fail', message: this.respo.message, popup: 'popup' }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result == 'Yes') {
              this.getUserRecords();
            }
          });
        }
      });
  }
  saveUser() {
    this.inProgress = true;
    this.actionName = "Progress saving...";
    let data = this.FormGroup.value;
    data.Beneficiary_Bank = this.Beneficiary_Bank;
    data.Created_by = sessionStorage.getItem('userName');
    data.userId = sessionStorage.getItem('userId');
    let formdata = new FormData();
    if (this.selectedProfileImage) {
      formdata.append('receiptImg', this.selectedProfileImage, this.selectedProfileImage.name);
    }
    formdata.append('request', JSON.stringify(data));
    this.http.postDataByForm('payrequest/saveRecords', formdata)
      .then(data => {
        this.inProgress = false;
        this.respo = data;
        let Action = this.editId == 0 ? "Saved" : "Updated";
        if (this.respo.status == 1) {
          let dialogRef = this.dialog.open(AlertpopupComponent, {
            width: "350px",
            data: { title: Action + ' successfully', message: this.respo.message, popup: 'popup' }
          });
          dialogRef.afterClosed().subscribe(result => {
          });
          this.getUserRecords();
          this.closeUser();
          this.FormGroup.reset();
        }
        else {
          let dialogRef = this.dialog.open(AlertpopupComponent, {
            width: "350px",
            data: { title: 'Action fail', message: this.respo.message, popup: 'popup' }
          });
          dialogRef.afterClosed().subscribe(result => {
            this.getUserRecords();
          });
        }
      });
  }
}
