<div class="spinner" fxLayoutAlign="center center" fxFlexFill [hidden]="!inProgress">
    <mat-spinner diameter="50"></mat-spinner>
    <p>Please wait, Work on progress...</p>
</div>
<form [formGroup]="firstFormGroup">
<mat-card>
    <mat-card-header>
        <h3>Create User Wallet</h3>
    </mat-card-header>
    <mat-card-content>      
        <mat-divider></mat-divider>
        <div>&nbsp;</div>
        <div class="row align-items-center h-100">
        <div class="col-lg-12 col-md-12 mx-auto">  
        <mat-form-field>
            <mat-label>Email Id*</mat-label>
            <input matInput class="inputForm" fxFlex="100" placeholder="Email Id" type="email" formControlName="emailId">
        </mat-form-field>
        </div>
        <div class="col-lg-12 col-md-12 mx-auto">
        <mat-form-field>
            <mat-label>Contact No*</mat-label>
            <input matInput class="inputForm" fxFlex="100" placeholder="cell" type="tel" formControlName="cell">
        </mat-form-field>
        </div>
        <div class="col-lg-12 col-md-12 mx-auto">
        <mat-form-field>
            <mat-label>OTP*</mat-label>
            <input matInput class="inputForm" fxFlex="100" placeholder="OTP" type="number" formControlName="otp">
        </mat-form-field>
        </div>
        <div class="col-lg-12 col-md-12 mx-auto">
            <button (click)="sendOTP()" type="button" mat-stroked-button color="primary">Resend OTP</button>
        </div>
        </div>
    </mat-card-content>
    <mat-card-actions style="text-align: right;">
        <button mat-raised-button (click)="closePopup()" color="warn">Close</button>
        <button mat-raised-button (click)="loadRequest()" color="primary" [disabled]="!firstFormGroup.valid || inProgress">Load Now</button>
    </mat-card-actions>
</mat-card>
</form>