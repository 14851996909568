<div id="printAvail" style="display: none;">
    <div *ngIf="reciepes.length>0">
        <table cellpadding="2px" cellspacing="0" style="width:100%" *ngFor="let reciepe of reciepes">
            <tbody>
                <tr>
                    <th colspan="2" align="center" style="border-bottom:1px dotted #000000;">
                        <table style="width: 100%;" cellspacing="0" cellpadding="0">
                            <tr>
                                <!-- <td>
                                <img [src]="imgSrc+'nirved-logo.png'" style="max-height:80px; float: left; margin-bottom: 10px;">
                            </td> -->
                                <td style="text-align: center;">
                                    <h3 style="padding:0px;margin:0px;margin-bottom:5px;font-weight: bold;font-style: italic;">Online Payment Receipt </h3>
                                    <h4 style="margin:0px;font-weight: bold;font-style: italic;">Receipt of {{reciepe.Recharge_Type}}</h4>
                                </td>
                                <!-- <td>
                                <img [src]="imgSrc+'easy-pay.png'" style="max-height:50px; float: right;">
                            </td> -->
                            </tr>
                        </table>
                    </th>
                </tr>
                <tr>
                    <td style="border-bottom:1px dotted #000000;font-weight: bold;font-style: italic;">
                        Date : {{reciepe.Recharge_Date | date: 'd-M-y'}}
                    </td>
                    <td style="text-align: right; border-bottom:1px dotted #000000;font-weight: bold;font-style: italic;">
                        Time : {{reciepe.payTime}}
                    </td>
                </tr>
                <tr>
                    <td style="font-weight: bold;font-style: italic;">
                        Service Number
                    </td>
                    <td style="font-weight: bold;font-style: italic;">
                        : {{reciepe.Invoice_No}}
                    </td>
                </tr>
                <tr>
                    <td style="font-weight: bold;font-style: italic;">
                        Customer Name
                    </td>
                    <td style="font-weight: bold;font-style: italic;">
                        : {{reciepe.Customer_Name}}
                    </td>
                </tr>
                <!-- <tr>
                    <td style="font-weight: bold;font-style: italic;">
                        BBPS Reference Number
                    </td>
                    <td style="font-weight: bold;font-style: italic;">
                        : {{reciepe.bbpsReferenceNumber || '-'}}
                    </td>
                </tr> -->
                <tr>
                    <td style="font-weight: bold;font-style: italic;">Merchant ID</td>
                    <td style="font-weight: bold;font-style: italic;">: {{merchant_ID}}</td>
                </tr>
                <tr>
                    <td style="font-weight: bold;font-style: italic;">Receipt Number</td>
                    <td style="font-weight: bold;font-style: italic;">: {{reciepe.TXN_Id}}</td>
                </tr>
                <tr>
                    <td style="font-weight: bold;font-style: italic;">Txn Status</td>
                    <td style="font-weight: bold;font-style: italic;">: {{reciepe.status}}</td>
                </tr>
                <tr>
                    <td style="border-top:1px dotted #000000;font-weight: bold;font-style: italic;" align="left">Bill Amount</td>
                    <th style="border-top:1px dotted #000000;font-weight: bold;font-style: italic;" align="left">Rs. {{reciepe.Amount}}</th>
                </tr>
                <tr>
                    <td align="left" style="font-weight: bold;font-style: italic;">Amount Deposited</td>
                    <th align="left" style="font-weight: bold;font-style: italic;">Rs. {{reciepe.Pay_Amount}}</th>
                </tr>
                <tr *ngIf="reciepe.charge_amount!='0'">
                    <td align="left" style="font-weight: bold;font-style: italic;">Convenience Amount</td>
                    <th align="left" style="font-weight: bold;font-style: italic;">Rs. {{reciepe.charge_amount}}</th>
                </tr>
                <tr>
                    <td align="left" style="font-weight: bold;font-style: italic;">Excess Payment</td>
                    <th align="left" style="font-weight: bold;font-style: italic;">Rs. {{reciepe.Amount - reciepe.Pay_Amount}} <b>(Credit)</b></th>
                </tr>
                <tr>
                    <th colspan="2" style="border-top:1px dotted #000000;font-weight: bold;font-style: italic;">Thank You For Using Our Services</th>
                </tr>
                <tr>
                    <td colspan="2" style="font-size: 10px;font-weight: bold;font-style: italic;">
                        <strong>Disclaimer -</strong>
                        <ol>
                            <li>Please check Service Number and amount mentioned in your receipt.</li>
                            <li>This receipt is valid subject to realization of cheque.</li>
                            <li>Biller may acknowledge payment within 2 business days</li>
                            <li>Receipt required for the settlement of deposit, If any</li>
                            <li>Receipt and Transaction inquiries are valid for 90-days only.</li>
                        </ol>
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <ngx-barcode [bc-value]="reciepe.TXN_Id" [bc-height]="40" [bc-display-value]="true"></ngx-barcode>
                    </td>
                </tr>
                <tr>
                    <th colspan="2" style="border-top:1px dotted #000000;font-size: 10px;">
                        <!-- Paid by Nirved Multiservices LLP<br> -->
                        <!-- Contact to Customer care : care@nirvedservices.in<br> -->
                        <!-- Nirved Multiservices LLP<br> -->
                        <!-- <strong>www.nirvedservices.in</strong><br> -->
                        <!-- Thanks for using Service of Nirved Multiservices LLP -->
                    </th>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<button id="btnPrint" style="display: none;" #btnPrint printSectionId="printAvail" ngxPrint>Print Now</button>
<div>
    <app-page-loading [hidden]="!inProgress"></app-page-loading>
    <div class="row ml-0 mr-0">
        <div class="col-md-4">
            <mat-card class="box-design">
                <!-- <h2 style="text-align: right;">Bal. : Rs. {{currentBalance | number:'.2'}}</h2>
                <mat-divider></mat-divider>   
                <br> -->
                <div *ngIf="currentBalance<=250" class="noBalance">
                    <br><br><br> Please maintain minimum balance of Rs. 250 in wallet.<br>
                    <strong>Recharge account Now</strong>
                </div>
                <form [formGroup]="firstFormGroup" #aForm>
                    <mat-form-field>
                        <mat-label>Service Type *</mat-label>
                        <mat-select formControlName="recharge_type" [disabled]="currentBalance<=250 || inProgress" (selectionChange)="clearPrint()" matNativeControl>
                            <mat-option *ngFor="let biller of billers" value="{{biller.Id}}">{{biller.billerName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="example-full-width">
                        <mat-label *ngIf="billType === 'gas' || billType === 'electricity'">Service Number *</mat-label>
                        <mat-label *ngIf="billType === 'lic'">Policy Number *</mat-label>
                        <mat-label *ngIf="billType === 'tax'">Tenament Number *</mat-label>
                        <mat-label *ngIf="billType === 'water'">Connection Number *</mat-label>
                        <mat-label *ngIf="billType === 'mobile'">Mobile Number *</mat-label>
                        <mat-label *ngIf="billType === 'dth'">DTH account No *</mat-label>
                        <mat-label *ngIf="billType === 'fastage'">Vehicle No *</mat-label>
                        <input matInput *ngIf="billType !== 'electricity'" formControlName="invoice_no" name="invoice_no" [readonly]="currentBalance<=250 || inProgress" oninput="this.value=this.value.replace(/[^0-9a-zA-Z]/g,'');" (change)="getBillerDetails()" [errorStateMatcher]="matcher">
                        <input matInput *ngIf="billType === 'electricity'" formControlName="invoice_no" name="invoice_no" [readonly]="currentBalance<=250 || inProgress" oninput="this.value=this.value.replace(/[^0-9]/g,'');" (change)="getBillerDetails()" [errorStateMatcher]="matcher">
                    </mat-form-field>
                    <div [hidden]='!isFetchable' fxLayout="row" *ngIf="currentBalance>250" fxLayoutGap="15px" style="margin-bottom: 15px;">
                        <button type="submit" class="w-100" [disabled]="inProgress" (click)="getBillerDetails()" mat-raised-button color="primary">Get Bill</button>
                    </div>
                    <div [hidden]='isFetchable'>
                        <mat-form-field class="example-full-width">
                            <mat-label>Customer Name <span *ngIf="isRed">[Required]</span><span *ngIf="!isRed">[Optional]</span></mat-label>
                            <input matInput [readonly]="!isEditable" formControlName="customer_name" [errorStateMatcher]="matcher">
                        </mat-form-field>
                        <mat-form-field class="example-full-width" *ngIf="billType !== 'mobile'">
                            <mat-label>Customer Phone <span *ngIf="isRed">[Required]</span><span *ngIf="!isRed">[Optional]</span></mat-label>
                            <input matInput formControlName="customer_mobile" [errorStateMatcher]="matcher" oninput="this.value=this.value.replace(/[^0-9]/g,'');">
                        </mat-form-field>
                        <mat-form-field class="example-full-width">
                            <mat-label>Amount *</mat-label>
                            <input matInput formControlName="amount" (blur)="changeAmount()" [errorStateMatcher]="matcher" oninput="this.value=this.value.replace(/[^0-9]/g,'');">
                        </mat-form-field>
                        <mat-form-field class="example-full-width" [hidden]="!isRed">
                            <mat-label>Convenience Amount *</mat-label>
                            <input matInput formControlName="charge_amount" [errorStateMatcher]="matcher" oninput="this.value=this.value.replace(/[^0-9]/g,'');">
                        </mat-form-field>
                        <mat-form-field class="example-full-width">
                            <mat-label>Due Date <span *ngIf="billType === 'gas' || billType === 'electricity'">*</span></mat-label>
                            <input matInput formControlName="due_date" [errorStateMatcher]="matcher" placeholder="Due Date *" type="date" style="max-width: 100% !important;">
                        </mat-form-field>
                        <div fxLayout="row" *ngIf="currentBalance>0" fxLayoutGap="15px" style="margin-bottom: 15px;">
                            <button type="reset" mat-stroked-button color="primary">Clear</button>
                            <button type="submit" [disabled]="!firstFormGroup.valid || inProgress" (click)="addToCart()" mat-raised-button color="primary">Add to List</button>
                            <!-- <button type="submit" [disabled]="!firstFormGroup.valid || inProgress" (click)="payNow()" mat-raised-button color="primary">Pay Now</button> -->
                        </div>
                    </div>
                </form>
            </mat-card>
        </div>
        <div class="col-md-8" style="max-height: 80vh; overflow-y: auto;">
            <mat-card class="box-design">
                <div *ngIf="cartList.length>0">
                    <div class="row">
                        <div class="col-md-4">
                            <h2>Paybill List</h2>
                        </div>
                    </div>
                    <div style="clear: both;"></div>
                    <mat-divider></mat-divider>
                    <div class="rechargecart">
                        <table width="100%" cellpadding="10" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>
                                        #
                                    </th>
                                    <th>
                                        Recharge Type
                                    </th>
                                    <th>
                                        Service Number
                                    </th>
                                    <th>
                                        Customer Name
                                    </th>
                                    <th>
                                        Due Date
                                    </th>
                                    <th>
                                        Amount
                                    </th>
                                    <th [hidden]="!isRed">
                                        Convenience
                                    </th>
                                    <th>
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody *ngIf="cartList.length>0">
                                <tr *ngFor="let recharge of cartList, let i=index">
                                    <td>{{i+1}}</td>
                                    <td>{{recharge.recharge_type}}</td>
                                    <td>{{recharge.invoice_no}}</td>
                                    <td>{{recharge.customer_name}}</td>
                                    <td>{{recharge.due_date}}</td>
                                    <td>{{recharge.amount | number:'.2'}}</td>
                                    <td [hidden]="!isRed">{{recharge.charge_amount | number:'.2'}}</td>
                                    <td>
                                        <button type="button" mat-mini-fab (click)="removeCart(i)" color="warn"><mat-icon>delete_sweep</mat-icon></button>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th style="text-align: left;" colspan="3">Total</th>
                                    <th>
                                        Collected Amount : <input type="number" style="max-width: 70px !important;" (change)="getReturnAmount()" [(ngModel)]="receivedAmount">
                                    </th>
                                    <th>
                                        Remain : <br> {{remainAmount | number:'.2'}}
                                    </th>
                                    <th *ngIf="!isRed">{{cartAmount | number:'.2'}}</th>
                                    <th *ngIf="isRed" colspan="2">{{cartAmount | number:'.2'}}</th>
                                    <th></th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <div class="row">
                        <div class="col-md-12" style="margin-top: 15px;" *ngIf="cartList.length>0">
                            <div class="row">
                                <div class="col-md-9 text-right p-t-10"></div>
                                <div class="col-md-3">
                                    <button class="w-100" type="submit" [disabled]="inProgress" (click)="payGroupNow()" mat-raised-button color="primary">Pay Now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>&nbsp;</div>
                </div>
                <div *ngIf="cartList.length==0">
                    <img class="img-fluid" src="assets/banner-6.jpg" alt="banner 5">
                </div>
                <!-- <mat-divider></mat-divider>
                <table width="100%" cellpadding="10" cellspacing="0">
                    <thead style="background: #1e659acc; color: #ffffff;">
                        <tr>
                            <th>
                                #
                            </th>
                            <th>
                                Recharge Type
                            </th>
                            <th>
                                Service Number
                            </th>
                            <th>
                                Amount
                            </th>
                            <th>
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody *ngIf="allBills.length==0">
                        <tr>
                            <th colspan="5" style="text-align: center;">No Pending bills</th>
                        </tr>
                    </tbody>
                    <tbody *ngIf="allBills.length>0">
                        <tr *ngFor="let recharge of allBills, let i=index">
                            <td>{{i+1}}</td>
                            <td>{{recharge.Recharge_Type}}</td>
                            <td>{{recharge.Invoice_No}}</td>
                            <td>{{recharge.Pay_Amount | number:'.2'}}</td>
                            <td style="text-align: center;">
                                <span *ngIf="recharge.Pay_Status==0" class="blueFont">Pending</span>
                                <button (click)="printBill(recharge)" mat-icon-button color="primary" *ngIf="recharge.Pay_Status == 1">
                                    <mat-icon class="greenFont">print</mat-icon>
                                </button>
                                <span *ngIf="recharge.Pay_Status==4" class="orangeFont">In Queue</span>
                                <span *ngIf="recharge.Pay_Status==6" class="yellowFont">In Progress</span>
                                <span *ngIf="recharge.Pay_Status==2 || recharge.Pay_Status==3 || recharge.Pay_Status==5" class="redFont">Failed</span>
                            </td>
                        </tr>
                    </tbody>
                </table> -->
            </mat-card>
        </div>
    </div>
</div>
<!-- <div style="position: absolute; bottom: 0; left: 0; right: 0;">
    bill details
</div> -->