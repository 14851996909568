import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  // serviceUrl: any = "http://localhost/portal1-backend/";
  // serviceUrl: any = "https://services.hntservices.in/";
  serviceUrl: any = "https://services.hntservices.co.in/";
  header: any = null;
  userToken: any = "be55b7f22ccf0b8-HNT-"+new Date();
  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private _router: Router
  ) { 
    this.updateAuthToken();  
  }
  updateAuthToken(){
    // console.log(sessionStorage.getItem('Auth-token'));
    if(sessionStorage.getItem('Auth-token')){
      this.userToken = sessionStorage.getItem('Auth-token');
    }
    this.header = new HttpHeaders({
      'X-Auth-Token': this.userToken
    });
  }
  getDataByPage(pageName: any) {
    this.updateAuthToken();
    return new Promise(resolve => {
      this.http.get(this.serviceUrl+pageName, { headers: this.header})
      // this.http.get(this.serviceUrl+pageName)
          .subscribe(data => {
            this.tokenExpired(data);
            resolve(data);
          }, err => {
            console.log(err);
          });
    });
  }
  getDataByUrl(pageName: any) {
    this.updateAuthToken();
    return new Promise(resolve => {
      this.http.get(pageName, { headers: this.header})
      // this.http.get(pageName)
          .subscribe(data => {
            this.tokenExpired(data);
            resolve(data);
          }, err => {
            console.log(err);
          });
    });
  }
  postDataByJson(pageName: any, pageData: any){
    this.updateAuthToken();
    return new Promise((resolve, reject) => {
      this.http.post(this.serviceUrl+pageName, JSON.stringify(pageData), { headers: this.header})
      // this.http.post(this.serviceUrl+pageName, JSON.stringify(pageData))
          .subscribe(res => {
            this.tokenExpired(res);
            resolve(res);
          }, (err) => {
            console.log(err);
            reject(err);
          });
    });
  }
  postDataByForm(pageName: any, pageData: any){
    this.updateAuthToken();
    return new Promise((resolve, reject) => {
      this.http.post(this.serviceUrl+pageName, pageData, { headers: this.header})
      // this.http.post(this.serviceUrl+pageName, pageData)
          .subscribe((res: any) => {
            this.tokenExpired(res);
            resolve(res);
          }, (err) => {
            console.log(err);
            reject(err);
          });
    });
  }
  postDataUrlByForm(pageName: any, pageData: any){
    this.updateAuthToken();
    return new Promise((resolve, reject) => {
      this.http.post(pageName, pageData, { headers: this.header})
      // this.http.post(pageName, pageData)
          .subscribe(res => {
            this.tokenExpired(res);
            resolve(res);
          }, (err) => {
            console.log(err);
            reject(err);
          });
    });
  }
  tokenExpired(res: any){
    this.dialog.closeAll();
    if(res.status){
      if(res.status==-1){
          sessionStorage.removeItem('user');
          sessionStorage.removeItem('userId');
          sessionStorage.removeItem('userName');
          sessionStorage.removeItem('isLogin');
          sessionStorage.removeItem('Auth-token');
          sessionStorage.clear();
          this._router.navigate(['/']);
      }
    }
  }
}