import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpService } from 'src/app/services/http.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertpopupComponent } from 'src/app/common/alertpopup/alertpopup.component';
import { AddBeneficiaryComponent } from 'src/app/common/add-beneficiary/add-beneficiary.component';
import { SidenavService } from 'src/app/services/sidenav.service';
import { Observable, timer } from 'rxjs';
import { ShareserviceService } from 'src/app/services/shareservice.service';

@Component({
  selector: 'app-moneytransfer',
  templateUrl: './moneytransfer.component.html',
  styleUrls: ['./moneytransfer.component.css']
})
export class MoneytransferComponent implements OnInit {

  @ViewChild('btnPrint') btnPrint: ElementRef;
  @ViewChild('aForm') aForm: ElementRef;
  
  firstFormGroup: FormGroup;
  beneficiaryGroup: FormGroup;
  reciepes: any = [];
  cartList: any = [];
  inProgress: boolean = false;
  response: any = [];
  Beneficiaries: any = [];
  OTPsent: boolean = false;
  msgcode: any = "0";
  createUser: boolean = false;
  customerDetails: any;
  newBeneficiary: boolean = false;
  allBanks: any = [];
  imgSrc: any;
  transLimit: any = 0;
  merchant_ID: any = "";
  subscription: any;

  everySecond: Observable<number> = timer(0, 2500);
  
  constructor(
    private _formBuilder: FormBuilder,
    private http: HttpService,
    private dialog: MatDialog,
    private shareService: ShareserviceService,
    private locationService: SidenavService
  ) { 
    this.merchant_ID = sessionStorage.getItem('merchant_ID');
    this.imgSrc = this.http.serviceUrl + "images/";
  }

  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
      CUSTOMERNO: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9]{10}') 
      ])],
      FNAME: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z]{2,}') 
      ])],
      LNAME: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z]{2,}') 
      ])],
      CITY: [''],
      PCODE: ['', Validators.compose([
        Validators.pattern('^[0-9]{6}') 
      ])], 
      otp: ''
    });
    // this.fetchRecords();
    this.beneficiaryGroup = this._formBuilder.group({
      MOBILENO: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9]{10}') 
      ])],
      NAME: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z ]{2,}') 
      ])],
      BANKID: ['', Validators.compose([
        Validators.required
      ])],
      ACCNO: ['', Validators.compose([
        Validators.required 
      ])],
      REPEATACCNO: ['', Validators.compose([
        Validators.required
      ])],
      IFSC: ['', Validators.compose([ 
        Validators.required
      ])],
      OTP: ''
    });
  }
  saveBeneficiary(){
    if(this.beneficiaryGroup.get("ACCNO").value == this.beneficiaryGroup.get("REPEATACCNO").value){
      this.inProgress = true;
      let param = new FormData();
      let cell = this.firstFormGroup.get("CUSTOMERNO").value;
      if(cell.length==10){
        param.append('CUSTOMERNO',cell);
        param.append('NAME',this.beneficiaryGroup.get("NAME").value);
        param.append('MOBILENO',this.beneficiaryGroup.get("MOBILENO").value);
        param.append('BANKID',this.beneficiaryGroup.get("BANKID").value);
        param.append('ACCNO',this.beneficiaryGroup.get("ACCNO").value);
        param.append('IFSC',this.beneficiaryGroup.get("IFSC").value);
        this.http.postDataByForm('dmt/AddBeneficiary', param)
            .then(data => {
              this.inProgress = false;
              this.response = data;
              console.log(this.response);
              if(this.response.status==1){
                  // this.OTPsent = true;
                  let dialogRef = this.dialog.open(AlertpopupComponent,{
                    width: "350px",
                    data: { title: "Beneficiary added!", message: this.response.data.STATUSMSG, popup:'popup'}
                  });
                  dialogRef.afterClosed().subscribe(result => {
                    this.getBeneficiary(); 
                  });
                  this.beneficiaryGroup.reset();
                  this.closeAddBeneficiary();
              }
              else {
                let dialogRef = this.dialog.open(AlertpopupComponent,{
                  width: "350px",
                  data: { title: "Fail to save!", message: this.response.data.STATUSMSG, popup:'popup'}
                });
                dialogRef.afterClosed().subscribe(result => {
                });
              }
            });
      }
      else {
        let dialogRef = this.dialog.open(AlertpopupComponent,{
          width: "350px",
          data: { title: "Error !", message: "Mobile no. should have 10 digits. Please, verify it.", popup:'popup'}
        });
        dialogRef.afterClosed().subscribe(result => {
          this.inProgress = false;
        });
      }
    }
    else {
      let dialogRef = this.dialog.open(AlertpopupComponent,{
        width: "350px",
        data: { title: "Account mismatched", message: "Account No and Confirm Account No must have to matched.", popup:'popup'}
      });
      dialogRef.afterClosed().subscribe(result => {
        this.inProgress = false;
      });
    }
  }
  getBankList(){
    this.inProgress = true;
    let param = new FormData();
    this.http.postDataByForm('dmt/GetBankList', param)
        .then(data => {
          this.inProgress = false;
          this.response = data;
          console.log(this.response);
          if(this.response.data.STATUSCODE==0){
              this.allBanks = this.response.data.BANKLIST;
          }
          else {
            let dialogRef = this.dialog.open(AlertpopupComponent,{
              width: "350px",
              data: { title: "Verification failed!", message: this.response.data.STATUSMSG, popup:'popup'}
            });
            dialogRef.afterClosed().subscribe(result => {
            });
          }
        });
  }
  getIFSC(){
    let bankId = this.beneficiaryGroup.get("BANKID").value;
    let Index = this.allBanks.findIndex(x => x.BANKID === bankId);
    // if(Index){
        let IFSCCode = this.allBanks[Index].MIFSCCODE;
        this.beneficiaryGroup.get("IFSC").setValue(IFSCCode);
    // }
  }
  closeAddBeneficiary(){
    this.newBeneficiary = false;
    this.beneficiaryGroup.reset();
  }
  fetchRecords(cell: any){    
    this.inProgress = true;
    let param = new FormData();
    // let cell = this.firstFormGroup.get("mobileNo").value;
    let condition = [];
    condition.push('cell',cell);
    param.append('table',"sft_mobidebit");
    param.append('billType', "Id");
    param.append('condition',JSON.stringify(condition));
    this.http.postDataByForm('common/get_list', param)
        .then(data => {
          this.inProgress = false;
          this.response = data;
          console.log(this.response);
          if(this.response.status==1){
            this.cartList = this.response.data.records;
            // console.log(this.billers);
          }
        });
  }
  ResendOTP(){
    this.inProgress = true;
    let param = new FormData();
    let cell = this.firstFormGroup.get("CUSTOMERNO").value;
    if(cell.length==10){
      param.append('CUSTOMERNO',cell);
      this.http.postDataByForm('dmt/ResendAddCustomerOTP', param)
          .then(data => {
            this.inProgress = false;
            this.response = data;
            console.log(this.response);
            if(this.response.status==1){
              let dialogRef = this.dialog.open(AlertpopupComponent,{
                width: "350px",
                data: { title: "OTP sent!", message: "OTP resent successfully.", popup:'popup'}
              });
              dialogRef.afterClosed().subscribe(result => {
              });
              this.OTPsent = true; 
            }
            else {
              let dialogRef = this.dialog.open(AlertpopupComponent,{
                width: "350px",
                data: { title: "OTP Not sent!", message: this.response.data.STATUSMSG, popup:'popup'}
              });
              dialogRef.afterClosed().subscribe(result => {
              });
            }
          });
    }
    else {
      let dialogRef = this.dialog.open(AlertpopupComponent,{
        width: "350px",
        data: { title: "Error !", message: "Mobile no. should have 10 digits. Please, verify it.", popup:'popup'}
      });
      dialogRef.afterClosed().subscribe(result => {
        this.inProgress = false;
      });
    }
  }
  customerVerifyOTP(){
    this.inProgress = true;
    let param = new FormData();
    let cell = this.firstFormGroup.get("CUSTOMERNO").value;
    if(cell.length==10){
      param.append('CUSTOMERNO',cell);
      param.append('OTP',this.firstFormGroup.get("otp").value);
      this.http.postDataByForm('dmt/ValidateCustomerOTP', param)
          .then(data => {
            this.inProgress = false;
            this.response = data;
            console.log(this.response);
            if(this.response.status==1){
              let dialogRef = this.dialog.open(AlertpopupComponent,{
                width: "350px",
                data: { title: "Verification successfully!", message: this.response.data.STATUSMSG, popup:'popup'}
              });
              dialogRef.afterClosed().subscribe(result => {
                this.createUser = false;
                this.OTPsent = false;
                this.fetchUserRecords();
              });
            }
            else {
              let dialogRef = this.dialog.open(AlertpopupComponent,{
                width: "350px",
                data: { title: "Verification failed!", message: this.response.data.STATUSMSG, popup:'popup'}
              });
              dialogRef.afterClosed().subscribe(result => {
              });
            }
          });
    }
    else {
      let dialogRef = this.dialog.open(AlertpopupComponent,{
        width: "350px",
        data: { title: "Error !", message: "Mobile no. should have 10 digits. Please, verify it.", popup:'popup'}
      });
      dialogRef.afterClosed().subscribe(result => {
        this.inProgress = false;
      });
    }
  }
  saveCustomer(){
    this.inProgress = true;
    let param = new FormData();
    let cell = this.firstFormGroup.get("CUSTOMERNO").value;
    if(cell.length==10){
      param.append('CUSTOMERNO',cell);
      param.append('FNAME',this.firstFormGroup.get("FNAME").value);
      param.append('LNAME',this.firstFormGroup.get("LNAME").value);
      param.append('CITY',this.firstFormGroup.get("CITY").value);
      param.append('PCODE',this.firstFormGroup.get("PCODE").value);
      this.http.postDataByForm('dmt/addCustomer', param)
          .then(data => {
            this.inProgress = false;
            this.response = data;
            console.log(this.response);
            if(this.response.status==1){
              if(this.response.data.OTPREQ == '1'){
                this.OTPsent = true;
                let dialogRef = this.dialog.open(AlertpopupComponent,{
                  width: "350px",
                  data: { title: "OTP sent!", message: "OTP sent on customer mobile.", popup:'popup'}
                });
                dialogRef.afterClosed().subscribe(result => {
                });
              }
              else {
                let dialogRef = this.dialog.open(AlertpopupComponent,{
                  width: "350px",
                  data: { title: "Account Created!", message: "Account created successfully", popup:'popup'}
                });
                dialogRef.afterClosed().subscribe(result => {
                  this.createUser = false;
                  this.OTPsent = false;
                  this.fetchUserRecords();
                });
              }
            }
            else {
              let dialogRef = this.dialog.open(AlertpopupComponent,{
                width: "350px",
                data: { title: "Fail to save!", message: this.response.data.STATUSMSG, popup:'popup'}
              });
              dialogRef.afterClosed().subscribe(result => {
              });
            }
          });
    }
    else {
      let dialogRef = this.dialog.open(AlertpopupComponent,{
        width: "350px",
        data: { title: "Error !", message: "Mobile no. should have 10 digits. Please, verify it.", popup:'popup'}
      });
      dialogRef.afterClosed().subscribe(result => {
        this.inProgress = false;
      });
    }
  }
  clearAll(){
    this.OTPsent = false;
    this.createUser = false;
    this.Beneficiaries = [];
    this.newBeneficiary = false;
    this.customerDetails = null;
    this.beneficiaryGroup.reset();
    this.firstFormGroup.reset();
    this.setFocus('CUSTOMERNO');
  }
  fetchUserRecords(){
    this.inProgress = true;
    let param = new FormData();
    let cell = this.firstFormGroup.get("CUSTOMERNO").value?this.firstFormGroup.get("CUSTOMERNO").value:"";
    if(cell.length==10){
      this.closeAddBeneficiary();
      param.append('MobileNo',cell);
      this.http.postDataByForm('dmt/fetchCustomer', param)
          .then(data => {
            this.inProgress = false;
            this.response = data;
            console.log(this.response);
            if(this.response.status==1){
              this.customerDetails = this.response.data;
              this.transLimit = this.customerDetails.REMAIN;
              this.getBeneficiary();
            }
            else {
              let message = this.response.data.STATUSMSG?this.response.data.STATUSMSG : this.response.message;
              let title = this.response.data.STATUSMSG?this.response.data.STATUSMSG : "No customer exist";
              let dialogRef = this.dialog.open(AlertpopupComponent,{
                width: "350px",
                data: { title: title, message: message, buttonYes:"Create Customer", popup:'confirm'}
              });
              dialogRef.afterClosed().subscribe(result => {
                  if(result=="Yes"){
                    this.createUser = true;
                  }
                  else {
                    this.firstFormGroup.reset();
                  }
              });
            }
          });
    }
    else {
      let dialogRef = this.dialog.open(AlertpopupComponent,{
        width: "350px",
        data: { title: "Error !", message: "Mobile no. should have 10 digits. Please, verify it.", popup:'popup'}
      });
      dialogRef.afterClosed().subscribe(result => {
        this.inProgress = false;
      });
    }
  }
  removeBaneficary(Beneficiary: any){
    let dialogRef = this.dialog.open(AlertpopupComponent,{
      width: "350px",
      data: { title: "Are you sure?", message: "Are you sure to remove Beneficiary? [Once removed, not recovered]", popup:'confirm'}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result=='Yes'){
        this.DeleteBeneficiary(Beneficiary);
      }
    });
  }
  DeleteBeneficiary(Beneficiary: any){
    this.inProgress = true;
    let param = new FormData();
    let cell = this.firstFormGroup.get("CUSTOMERNO").value?this.firstFormGroup.get("CUSTOMERNO").value:"";
    if(cell.length==10){
      param.append('BENEID',Beneficiary.BENEID);
      param.append('CUSTOMERNO',cell);
      this.http.postDataByForm('dmt/DeleteBeneficiary', param)
          .then((data: any) => {
            this.inProgress = false;
            console.log(data);
            if(data.status==1){
              let message = data.data.STATUSMSG?data.data.STATUSMSG : data.message;
              let title = "Removed successfully!";
              let dialogRef = this.dialog.open(AlertpopupComponent,{
                width: "350px",
                data: { title: title, message: message, popup:'popup'}
              });
              dialogRef.afterClosed().subscribe(result => {
                this.getBeneficiary();
              });
            }
            else {
              let message = data.data.STATUSMSG?data.data.STATUSMSG : data.message;
              let title = "Not removed!";
              let dialogRef = this.dialog.open(AlertpopupComponent,{
                width: "350px",
                data: { title: title, message: message, popup:'popup'}
              });
              dialogRef.afterClosed().subscribe(result => {
              });
            }
          });
    }
    else {
      let dialogRef = this.dialog.open(AlertpopupComponent,{
        width: "350px",
        data: { title: "Error !", message: "Mobile no. should have 10 digits. Please, verify it.", popup:'popup'}
      });
      dialogRef.afterClosed().subscribe(result => {
        this.inProgress = false;
      });
    }
  }
  addBeneficiary(){
      this.getBankList();
      this.newBeneficiary = true;
  } 
  BeneficiaryAccountVerify(beneficiary: any){
    let location = "";
    this.locationService.getPosition().then(pos=>
    {
      location = pos.lng+", "+pos.lat;
        // console.log(`Positon: ${pos.lng} ${pos.lat}`);
        // this.sendMoney.get('AG_LAT').setValue(pos.lng);
        // this.sendMoney.get('AG_LONG').setValue(pos.lat);
    });
    let dialogRef = this.dialog.open(DialogPopup,{
      width: "350px"
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result == 'Yes'){
        beneficiary.location = location;
        this.sureAccountVerify(beneficiary);
      }
    });
  }
  saveVerifyBeneficiary(){
    if(this.beneficiaryGroup.get("ACCNO").value == this.beneficiaryGroup.get("REPEATACCNO").value){
      let dialogRef = this.dialog.open(DialogPopup,{
        width: "350px"
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result == 'Yes'){
          this.verifyAndSave();
        }
      });
    }
    else {
      let dialogRef = this.dialog.open(AlertpopupComponent,{
        width: "350px",
        data: { title: "Account mismatched", message: "Account No and Confirm Account No must have to matched.", popup:'popup'}
      });
      dialogRef.afterClosed().subscribe(result => {
        this.inProgress = false;
      });
    }
  }
  verifyAndSave(){
    let location = "";
    this.locationService.getPosition().then(pos=>
    {
      location = pos.lng+", "+pos.lat;
    });
    this.inProgress = true;
    let param = new FormData();
    let cell = this.firstFormGroup.get("CUSTOMERNO").value;
    if(cell.length==10){
      param.append('CUSTOMERNO',cell);
      param.append('BANKID',this.beneficiaryGroup.get("BANKID").value);
      param.append('ACCNO',this.beneficiaryGroup.get("ACCNO").value);
      param.append('IFSC',this.beneficiaryGroup.get("IFSC").value);
      param.append('BENENAME',this.beneficiaryGroup.get("NAME").value);
      param.append('location',location);
      // param.append('User_Id', sessionStorage.getItem('userId'));
      param.append('Created_by',sessionStorage.getItem('userName'));
      this.http.postDataByForm('dmt/BeneficiaryAccountVerify', param)
          .then(data => {
            this.inProgress = false;
            this.response = data;
            console.log(this.response);
            if(this.response.data.STATUSCODE==0){
              let dialogRef = this.dialog.open(AlertpopupComponent,{
                width: "350px",
                data: { title: "Account Verified!", message: this.response.data.STATUSMSG, popup:'popup'}
              });
              dialogRef.afterClosed().subscribe(result => {
                this.updateBalance();
                this.saveBeneficiary();
              });  
            }
            else {
              let dialogRef = this.dialog.open(AlertpopupComponent,{
                width: "350px",
                data: { title: "Failure", message: "Account Verification failed. ( Error : "+this.response.data.STATUSMSG+" )", popup:'popup'}
              });
              dialogRef.afterClosed().subscribe(result => {
              });
            }
          })
          .finally(() => {
              this.inProgress = false;
          });
    }
    else {
      let dialogRef = this.dialog.open(AlertpopupComponent,{
        width: "350px",
        data: { title: "Error !", message: "Mobile no. should have 10 digits. Please, verify it.", popup:'popup'}
      });
      dialogRef.afterClosed().subscribe(result => {
        this.inProgress = false;
      });
    }
  }
  sureAccountVerify(beneficiary: any){
    this.inProgress = true;
    let param = new FormData();
    let cell = this.firstFormGroup.get("CUSTOMERNO").value;
    if(cell.length==10){
      param.append('CUSTOMERNO',cell);
      param.append('BANKID',beneficiary.BANKID);
      param.append('ACCNO',beneficiary.ACCNO);
      param.append('IFSC',beneficiary.IFSC);
      param.append('BENENAME',beneficiary.BENENAME);
      param.append('location',beneficiary.location);
      // param.append('User_Id', sessionStorage.getItem('userId'));
      param.append('Created_by',sessionStorage.getItem('userName'));
      this.http.postDataByForm('dmt/BeneficiaryAccountVerify', param)
          .then(data => {
            this.inProgress = false;
            this.response = data;
            console.log(this.response);
            if(this.response.data.STATUSCODE==0){
              let dialogRef = this.dialog.open(AlertpopupComponent,{
                width: "350px",
                data: { title: "Account Verified!", message: this.response.data.STATUSMSG, popup:'popup'}
              });
              dialogRef.afterClosed().subscribe(result => {
                this.updateBalance();
                beneficiary.VERIFIED = 1;
              });
              // this.inProgress = true;
              // this.subscription = this.everySecond.subscribe((seconds) => {
              //   this.getStatus(this.response);
              // });  
            }
            else {
              let dialogRef = this.dialog.open(AlertpopupComponent,{
                width: "350px",
                data: { title: "Failure", message: this.response.data.STATUSMSG, popup:'popup'}
              });
              dialogRef.afterClosed().subscribe(result => {
              });
            }
          })
          .finally(() => {
              this.inProgress = false;
          });
    }
    else {
      let dialogRef = this.dialog.open(AlertpopupComponent,{
        width: "350px",
        data: { title: "Error !", message: "Mobile no. should have 10 digits. Please, verify it.", popup:'popup'}
      });
      dialogRef.afterClosed().subscribe(result => {
        this.inProgress = false;
      });
    }
  }
  getBeneficiary(){
    this.inProgress = true;
    let param = new FormData();
    let cell = this.firstFormGroup.get("CUSTOMERNO").value;
    if(cell.length==10){
      param.append('CUSTOMERNO',cell);
      this.http.postDataByForm('dmt/GetAllBeneficiary', param)
          .then(data => {
            this.inProgress = false;
            this.response = data;
            console.log(this.response);
            if(this.response.status==1){
              this.Beneficiaries = this.response.data.BENELIST;
            }
            else {
              this.addBeneficiary();
            }
          });
    }
    else {
      let dialogRef = this.dialog.open(AlertpopupComponent,{
        width: "350px",
        data: { title: "Error !", message: "Mobile no. should have 10 digits. Please, verify it.", popup:'popup'}
      });
      dialogRef.afterClosed().subscribe(result => {
        this.inProgress = false;
      });
    }
  }
  sendMoney(Benefeciary: any){
    let dialogRef = this.dialog.open(AddBeneficiaryComponent,{
      width: "350px",
      data: { beneficiary: JSON.stringify(Benefeciary), Limit: this.transLimit }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      this.sendConfirmMoney(result);
    });
  }
  sendConfirmMoney(beneficiary: any){
    this.inProgress = true;
    let param = new FormData();
    let cell = this.firstFormGroup.get("CUSTOMERNO").value;
    if(cell.length==10){
      param.append('CUSTOMERNO',cell);
      param.append('BENEID',beneficiary.BENEID);
      param.append('ACCNO',beneficiary.ACCNO);
      param.append('AMT',beneficiary.AMT);
      param.append('TRNTYPE',beneficiary.TRNTYPE);
      param.append('IMPS_SCHEDULE',beneficiary.IMPS_SCHEDULE);
      param.append('CHN',beneficiary.CHN);
      param.append('CUR',beneficiary.CUR);
      param.append('BENENAME',beneficiary.BENENAME);
      param.append('location',beneficiary.location);
      param.append('AG_LONG',beneficiary.AG_LONG);
      param.append('AG_LAT',beneficiary.AG_LAT);
      param.append('amount',beneficiary.orginalAmount);
      // param.append('User_Id', sessionStorage.getItem('userId'));
      param.append('Created_by',sessionStorage.getItem('userName'));
      this.http.postDataByForm('dmt/SendMoney', param)
          .then(data => {
            this.inProgress = false;
            this.response = data;
            console.log(this.response);
            if(this.response.data.STATUSCODE==0){
              this.inProgress = true;
              this.updateBalance();
              this.subscription = this.everySecond.subscribe((seconds) => {
                this.getStatus(this.response);
              });

              // this.reciepes.push(this.response.data.records);
              // let dialogRef = this.dialog.open(AlertpopupComponent,{
              //   width: "350px",
              //   data: { title: "Sent Money successfully.", message: this.response.data.STATUSMSG, popup:'popup'}
              // });
              // dialogRef.afterClosed().subscribe(result => {
                // this.inProgress = true;
                // let timer = setTimeout(() => {
                //   this.getStatus(this.response);
                //   clearTimeout(timer);
                  // console.log(respo);
                  // if(respo.TRNSTATUS){
                  //   this.inProgress = false;
                  //   console.log(this.response.data.records);
                  //   // this.reciepes.push(this.response.data.records);
                  //   clearTimeout(timer);
                  //   let dialogRef = this.dialog.open(AlertpopupComponent,{
                  //       width: "350px",
                  //       data: { title: respo.TRNSTATUS, message: respo.TRNSTATUSDESC, popup:'popup'}
                  //   });
                  //   dialogRef.afterClosed().subscribe(result => {
                  //     this.openPrint();
                  //     this.refreshAll();
                  //   });
                  // }
                // }, 5000);
              // });
            }
            else {
              let message = this.response.data.STATUSMSG?this.response.data.STATUSMSG:this.response.message;
              let dialogRef = this.dialog.open(AlertpopupComponent,{
                width: "350px",
                data: { title: "Failure", message: message, popup:'popup'}
              });
              dialogRef.afterClosed().subscribe(result => {
              });
            }
          })
          .catch(() => {
            this.inProgress = false;
          })
          .finally(() => {
            // this.inProgress = false;
          });
    }
    else {
      let dialogRef = this.dialog.open(AlertpopupComponent,{
        width: "350px",
        data: { title: "Error !", message: "Mobile no. should have 10 digits. Please, verify it.", popup:'popup'}
      });
      dialogRef.afterClosed().subscribe(result => {
        this.inProgress = false;
      });
    }
  }
  updateBalance() {
    this.inProgress = true;
    let formdata = new FormData();
    formdata.append('userId', sessionStorage.getItem('userId'));
    this.http.postDataByForm('transaction/refreshBalance', formdata)
      .then((data: any) => {
        this.inProgress = false;
        if (data.status == 1) {
          let currentBalance = data.data.balance;
          sessionStorage.setItem('balance', currentBalance.toString());
          this.shareService.accountBalance.next(currentBalance);
        }
      });
  }
  getStatus(data: any){
    // console.log(data);
    this.inProgress = true;
    let param = new FormData();
    param.append('REFNO',data.data.REFNO);
    param.append('Amount',data.records.Pay_Amount);
    param.append('Created_by',sessionStorage.getItem('userName'));
    param.append('Id',data.records.Id);
    this.http.postDataByForm('dmt/GetTransactionStatus', param)
              .then((data: any) => {
                let response = data;
                console.log(response);
                if(response.data.STATUSCODE=='0'){
                  let respo = {};
                  console.log(response);
                  if(response.data.TRNSTATUS == '1'){
                    this.inProgress = false;
                    this.subscription.unsubscribe();
                    this.reciepes.push(response.record);
                    let dialogRef = this.dialog.open(AlertpopupComponent,{
                        width: "350px",
                        data: { title: "Transaction success !", message: "Payment transfer successfully.", popup:'popup'}
                    });
                    dialogRef.afterClosed().subscribe(result => {
                      this.openPrint();
                      this.clearAll();
                      this.updateBalance();
                    });
                  }
                  else if(response.data.TRNSTATUS == '2' || response.data.TRNSTATUS == '3' || response.data.TRNSTATUS == '5'){
                    this.inProgress = false;
                    this.subscription.unsubscribe();
                    let dialogRef = this.dialog.open(AlertpopupComponent,{
                      width: "350px",
                      data: { title: "Transaction failed !", message: "Transaction failed ( Error : " + response.data.TRNSTATUSDESC + " )", popup:'popup'}
                    });
                    dialogRef.afterClosed().subscribe(result => {
                      // this.clearAll();
                      this.updateBalance();
                    });
                  }
                }
              })
              .finally(() => {
                // this.inProgress = false;
              });
  }
  openPrint() {
    let inputElement: HTMLElement = this.btnPrint.nativeElement as HTMLElement;
    inputElement.click();
    let timer = setTimeout(() => {
      this.clearPrint();
      this.setFocus('CUSTOMERNO');
      clearTimeout(timer);
    }, 2500);
  }
  setFocus(name: any) {    
    const ele = this.aForm.nativeElement[name];    
    if (ele) {
      ele.focus();
    }
  }
  clearPrint() {
  }
  refreshAll(){
    this.Beneficiaries = [];
    this.firstFormGroup.reset();
    this.beneficiaryGroup.reset();
  }
  getMaskValue(Invoice_No: any){
    let first2Char = Invoice_No.substring(0, 2);
    let last2Char = Invoice_No.slice(-2);
    return first2Char+"XXXXXX"+last2Char;
  }
}

@Component({
  selector: 'otp-popup',
  templateUrl: 'OTPpopup.html',
})
export class DialogPopup {
  constructor(@Inject(MAT_DIALOG_DATA) data: any, public dialogRef: MatDialogRef<DialogPopup>) {
      console.log(data);
  }
  closeDialog(respo: any = 'No') {
    this.dialogRef.close(respo);
  }
}

// @Component({
//   selector: 'payment-popup',
//   templateUrl: 'paymentPopup.html',
// })
// export class PaymentPopup {
//   beneName: any = "Beneficiary";
//   beneACCno: any = "0000";
//   sendMoney: FormGroup;
//   paymode: any = '1';

//   constructor(
//       @Inject(MAT_DIALOG_DATA) data: any, 
//       public dialogRef: MatDialogRef<PaymentPopup>,
//       private _formBuilder: FormBuilder,
//       private locationService: SidenavService
//   ) {
//       console.log(data);
//       let beneficiary = JSON.parse(data.beneficiary);
//       this.beneName = beneficiary.BENENAME;
//       this.beneACCno = beneficiary.ACCNO;

//       this.sendMoney = this._formBuilder.group({
//         CUSTOMERNO: [''],
//         BENEID: [''],
//         AMT: ['', Validators.compose([
//           Validators.required,
//           Validators.pattern('^[0-9]{10}') 
//         ])],
//         TRNTYPE: ['1'],
//         IMPS_SCHEDULE: ['1'],
//         CHN: ['WEB'],
//         CUR: ['INR'],
//         AG_LAT: [''],
//         AG_LONG: ['']
//       });

//       this.locationService.getPosition().then(pos=>
//       {
//           // console.log(`Positon: ${pos.lng} ${pos.lat}`);
//           this.sendMoney.get('AG_LAT').setValue(pos.lng);
//           this.sendMoney.get('AG_LONG').setValue(pos.lat);
//       });
//   }
//   changeMode(payType: any){
//     console.log(payType);
//     this.paymode = payType;
//     this.sendMoney.get('TRNTYPE').setValue(payType);
//     this.sendMoney.get('IMPS_SCHEDULE').setValue(payType);
//   }
//   closeDialog(respo: any = 'No') {
//     if(respo == 'Yes'){
//         respo = this.sendMoney.value;
//     }
//     this.dialogRef.close(respo);
//   }
// }