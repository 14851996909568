<app-page-loading [hidden]="!inProgress"></app-page-loading>
<form [formGroup]="FormGroup">
    <div class="row" style="margin-right: 0px; margin-left: 0px; margin-top: 15px;">
        <div class="col-md-5 mb-3">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>My Profile</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <mat-divider></mat-divider>
                    <div>&nbsp;</div>
                    <div class="row myprofile">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-3">
                                    <label>Merchant ID</label>
                                </div>
                                <div class="col-md-9">
                                    <p>{{allRecords.merchant_ID}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-3">
                                    <label>Name</label>
                                </div>
                                <div class="col-md-9">
                                    <p>{{allRecords.Name}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-3">
                                    <label>Contact No</label>
                                </div>
                                <div class="col-md-9">
                                    <p>{{allRecords.Contact_No}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-3">
                                    <label>Email Id</label>
                                </div>
                                <div class="col-md-9">
                                    <p>{{allRecords.Email_Id}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-3">
                                    <label>Address</label>
                                </div>
                                <div class="col-md-9">
                                    <p>{{allRecords.Address}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col-md-7 mb-3">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Change Password</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <mat-divider></mat-divider>
                    <div>&nbsp;</div>
                    <form [formGroup]="userFormgroup">
                        <div class="row myprofile">
                            <div class="form-group col-md-4">
                                <label>Current Password*</label>
                                <input class="form-control" type="password" placeholder="Current Password" formControlName="CurrentPassword">
                            </div>
                            <div class="form-group col-md-4">
                                <label>New Password*</label>
                                <input class="form-control" type="password" placeholder="New Password" formControlName="Password">
                            </div>
                            <div class="form-group col-md-4">
                                <label>Confirm New Password*</label>
                                <input type="password" class="form-control" placeholder="Confirm New Password" formControlName="CPassword">
                            </div>
                            <div class="form-group col-md-12 text-right">
                                <button mat-raised-button color="primary" [disabled]="!userFormgroup.valid || inProgress" (click)="saveRecord()">
                                Change Password Now <mat-icon>forward</mat-icon>
                            </button>
                            </div>
                        </div>
                    </form>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</form>