import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AsyncPipe } from '@angular/common';

import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { DefaultModule } from './layouts/default/default.module';
import { SharedModule } from './shared/shared.module';
import { LoginComponent } from './login/login.component';
import { ForgetpasswordComponent } from './forgetpassword/forgetpassword.component';
import { AlertpopupComponent } from './common/alertpopup/alertpopup.component';
import { LoaderComponent } from './common/loader/loader.component';
import { MessagingService } from './services/messaging.service';
import { LoadwalletComponent } from './common/loadwallet/loadwallet.component';
import { DisabledpageComponent } from './disabledpage/disabledpage.component';
import { PaymentProcessPopupComponent } from './common/payment-process-popup/payment-process-popup.component';
import { AddCustomersComponent } from './common/add-customers/add-customers.component';
import { AddBeneficiaryComponent } from './common/add-beneficiary/add-beneficiary.component';
import { DeviceConnectComponent } from './common/device-connect/device-connect.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { NgxBarcodeModule } from 'ngx-barcode';

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyDpS8zM28kXEJf66r7UO0TnGnTftFKDRTk",
  authDomain: "nirved-67b43.firebaseapp.com",
  databaseURL: "https://nirved-67b43.firebaseio.com",
  projectId: "nirved-67b43",
  storageBucket: "nirved-67b43.appspot.com",
  messagingSenderId: "812486021222",
  appId: "1:812486021222:web:d814c915d001a7a223dda7",
  measurementId: "G-MCVE3YZ58F"
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgetpasswordComponent,
    AlertpopupComponent,
    LoaderComponent,
    LoadwalletComponent,
    DisabledpageComponent,
    PaymentProcessPopupComponent,
    AddCustomersComponent,
    AddBeneficiaryComponent,
    DeviceConnectComponent
  ],
  imports: [
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireMessagingModule,
    HttpClientModule,
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DefaultModule,
    SharedModule,
    MatCardModule,
    FlexLayoutModule,
    MatButtonModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatDividerModule,
    MatIconModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    NgxMatSelectSearchModule,
    NgxQRCodeModule,
    NgxBarcodeModule
  ],
  providers: [MessagingService, AsyncPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
