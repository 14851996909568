<app-page-loading [hidden]="!inProgress"></app-page-loading>
<div class="row" style="padding: 10px; margin-right:0px; margin-left: 0px">
    <div class="col-md-12 text-right" style="padding-right:0px; padding-left:0px; padding-bottom: 0px;">
        <button mat-raised-button color="primary" (click)="loadWallet()">
            <mat-icon>add_circle</mat-icon> Load Wallet
        </button>
    </div>
</div>
<div class="col-12" class="header">
    <mat-card-title>Load Wallet</mat-card-title>
</div>
<div class="row" style="padding: 10px; margin-right:0px; margin-left: 0px">
    <div class="col-md-12 pl-0">
        <button (click)="changeType('month')" class="btn-trans" [ngClass]="{'btn-active':tType=='month'}">
            Month Transaction
        </button>
        <button (click)="changeType('custom')" class="btn-trans" [ngClass]="{'btn-active':tType=='custom'}">
            Custom Transaction
        </button>
    </div>
    <div class="col-md-12" style="padding-right:0px; padding-left:0px">
        <p-table #dt [columns]="cols" [value]="allBills" [paginator]="true" [rows]="100" [showCurrentPageReport]="true" [totalRecords]="totalRecords" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [responsive]="true">
            <ng-template pTemplate="caption">
                <div style="font-size: 20px;">
                    Load Wallet History
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div style="text-align: left">
                            <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                            <input type="text" pInputText size="50" placeholder="Search Global filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="ui-helper-clearfix" style="text-align: right">
                            <button type="button" pButton icon="pi pi-file-excel" iconPos="left" label="EXCEL" (click)="dt.exportCSV()" style="margin-right: 0.5em;"></button>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 50px;">
                        #
                    </th>
                    <th style="width: 140px;">
                        Receipt No.
                    </th>
                    <th style="width: 175px;">
                        TXN No.
                    </th>
                    <th *ngFor="let col of cols">
                        {{col.header}}
                    </th>
                    <th style="width: 80px;">
                        Status
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-bills>
                <tr>
                    <td>
                        {{bills.Id}}
                    </td>
                    <td>
                        {{bills.Order_No}}
                    </td>
                    <td>
                        {{bills.TXN_Id}}
                    </td>
                    <td *ngFor="let col of cols">
                        {{bills[col.field]}}
                    </td>
                    <td>
                        <span *ngIf="bills.isDelete=='1'">Deleted</span>
                        <span *ngIf="bills.isDelete=='0'">
                            <span *ngIf="bills.isDelete=='0'">
                                <span *ngIf="bills.Pay_Status == 0">Pending</span>
                        <span *ngIf="bills.Pay_Status == 1">Success</span>
                        <span *ngIf="bills.Pay_Status == 2">Operator Failed</span>
                        <span *ngIf="bills.Pay_Status == 3">System Failed</span>
                        <span *ngIf="bills.Pay_Status == 4">Under Process</span>
                        <span *ngIf="bills.Pay_Status == 5">Refunded</span>
                        <span *ngIf="bills.Pay_Status == 6">Under Process</span>
                        </span>
                        </span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                There are {{allBills?.length}} Records
            </ng-template>
        </p-table>
    </div>
</div>