import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-device-connect',
  templateUrl: './device-connect.component.html',
  styleUrls: ['./device-connect.component.css']
})
export class DeviceConnectComponent implements OnInit {
  bgImage: any = '';
  elementType : 'url' | 'canvas' | 'img' = 'url';
  value : string = "";
  inProgress: boolean = false;
  constructor(
    private http: HttpService,
    private _router: Router
  ) { 
    if(!sessionStorage.getItem('Auth-token')){
      this._router.navigate(['/login']);
    }
    this.bgImage = this.http.serviceUrl+"images/background.png";
  }

  ngOnInit(): void {
    this.value = sessionStorage.getItem('Auth-token');
  }

}
