<h1 mat-dialog-title>{{title}}</h1>
<div mat-dialog-content>
    <p [innerHTML]="message"></p>
</div>
<div mat-dialog-actions *ngIf="type=='bbps_id'" style="text-align: right;">
    <form [formGroup]="bbps_form">
        <input type="text" class="form-control" placeholder="Enter BBPS Reference No" formControlName="bbps_no">
        <button class="mt-4" mat-button [disabled]="bbps_form.invalid" (click)="closeBBPDialog()" mat-raised-button color="primary">Pay Now</button>
    </form>
</div>
<div mat-dialog-actions *ngIf="type=='bbps_red_id'" style="text-align: right;">
    <form [formGroup]="bbps_form">
        <input type="text" class="form-control" placeholder="Enter BBPS Reference No" formControlName="bbps_no"><br>
        <input type="number" class="form-control" placeholder="Enter Charge Paid" formControlName="charge_paid" min="0">
        <button class="mt-4" mat-button [disabled]="bbps_form.invalid" (click)="closeBBPDialog()" mat-raised-button color="primary">Pay Now</button>
    </form>
</div>
<div mat-dialog-actions *ngIf="type=='popup'" style="text-align: right;">
    <button mat-button (click)="closeDialog()" mat-raised-button color="primary">Ok</button>
</div>
<div mat-dialog-actions *ngIf="type=='confirm'">
    <button mat-button (click)="closeCDialog('No')" mat-raised-button color="warn">{{textNo}}</button>
    <button mat-button (click)="closeCDialog('Yes')" mat-raised-button color="primary">{{textYes}}</button>
</div>