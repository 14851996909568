<div class="container">
    <div class="row">
        <div class="col-md-12 min-vh-100 d-flex flex-column justify-content-center">
            <div class="row">
                <div class="col-lg-8 col-md-10 mx-auto">
                    <!-- form card login -->
                    <div class="card rounded shadow shadow-sm">
                        <div class="card-header">
                            <h3 class="mb-0 mt-0 text-center" style="font-size: 2rem;">
                              Disabled Inspect/Console
                            </h3>
                        </div>
                        <div class="card-body">
                          <h4 class="text-center" style="color: crimson;">Invalid activity found. please, Close INSPECT/CONSOLE of browser.</h4>
                          <p>Invalid activity found during use of our web application. We has strictly restrict to open our code in any circumtances. We have request you, please, don't do such activity like hacker. So, kindly, close your console or inspect of browser and click on bellow link for continue.</p>
                          <hr>
                          <a href="/" class="btn btn-info float-right" style="color: #ffffff;">continue</a>
                        </div>
                        <!--/card-block-->
                    </div>
                    <!-- /form card login -->
                </div>
            </div>
            <!--/row-->
        </div>
        <!--/col-->
    </div>
    <!--/row-->
  </div>
  <!--/container-->