import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertpopupComponent } from 'src/app/common/alertpopup/alertpopup.component';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.css']
})
export class DefaultComponent implements OnInit {

  sideBarOpen: boolean = true;
  isChatbox: boolean = false;
  drawerMode: any = "side";
  tabname: any = "txn";
  firstFormGroup: FormGroup;
  formGroup: FormGroup;
  billers: any = [];
  services: any = [];
  inProgress: boolean = false;
  servType: any = "electricity";
  allBills: any = [];

  constructor(
    private _router: Router,
    private http: HttpService,
    private dialog: MatDialog,
    private _formBuilder: FormBuilder
  ) {
    this.firstFormGroup = this._formBuilder.group({
      txnNo: ['HNT', Validators.required]
    });
    this.formGroup = this._formBuilder.group({
      recharge_by: ['', Validators.required],
      Recharge_Type: [''],
      Invoice_No: ['', Validators.required]
    });

    this.services.push(
      {'title':'Electricity', 'image':'electricity.png', 'type': 'electricity'},
      {'title':'GAS', 'image':'gas.png', 'type': 'gas'},
      {'title':'Tax Payment', 'image':'tax-bills.png', 'type': 'tax'},
      {'title':'Water Bill', 'image':'water-bill.png', 'type': 'water'},
      {'title':'Insurance', 'image':'lic.png', 'type': 'lic'},
      {'title':'Mobile Recharge', 'image':'mobile.png', 'type': 'mobile'},
      {'title':'DTH Recharge', 'image':'dth.png', 'type': 'dth'},
      {'title':'Fastage', 'image':'fastage.png', 'type': 'fastage'},
      {'title':'Domestic Money Transfer', 'image':'dmt.png', 'type': 'dmt'}
    );
    this.formGroup.get('recharge_by').setValue('electricity');
   }

  ngOnInit(): void {
    this.getBillers();
    if(!sessionStorage.getItem('userId') || !sessionStorage.getItem('isLogin')){
        this._router.navigate(['/']);
    } 
    // if(window.innerWidth<=750){
      this.drawerMode = "over";
      this.sideBarOpen = false;
    // }
  }
  getBillers(event: any = "") {
    let billType = event!="" ? event.value : "electricity";
    this.servType = billType;
    if(billType != "dmt"){
      this.inProgress = true;
      let param = new FormData();
      param.append('city', sessionStorage.getItem('City'));
      param.append('billType', billType);
      this.http.postDataByForm('billers/get_billers', param)
        .then((data: any) => {
          this.inProgress = false;
          if (data.status == 1) {
            this.billers = data.data.records;
            this.formGroup.get('recharge_type').setValue(this.billers[0].Id);
          }
          else {
            console.log('Not fetching');
          }
        }).catch((err) => {
          // this.dialog.open(AlertpopupComponent,{
          //   width: "350px",
          //   data: { title: err.statusText, message: err.status+' - '+err.statusText+' Talk with Administrator.', popup:'popup'}
          // });
          // console.log(err);
        })
        .finally(() => {
          this.inProgress = false;
        });
    }
  }
  searchBills() {
    let formdata = new FormData();
    let billAll = {"TXN_Id": this.firstFormGroup.get("txnNo").value};
    this.inProgress = true;
    formdata.append('params', JSON.stringify(billAll));
    formdata.append('period', "custom");
    this.http.postDataByForm('payrecharge/search_list', formdata)
      .then((data: any) => {
        console.log(data);
        this.inProgress = false;
        if (data.status == 1) {
          this.allBills = data.data.records;
          for (let bills of this.allBills) {
              if (bills.isDelete == 1) {
                bills.status = 'Deleted';
              } else if (bills.isDelete == 0) {
                if (bills.Pay_Status == 0) {
                  bills.status = 'Pending';
                } else if (bills.Pay_Status == 1) {
                  bills.status = 'Success';
                } else if (bills.Pay_Status == 2) {
                  bills.status = 'Operator Failed';
                } else if (bills.Pay_Status == 3) {
                  bills.status = 'System Failed';
                } else if (bills.Pay_Status == 4) {
                  bills.status = 'Under Process';
                } else if (bills.Pay_Status == 5) {
                  bills.status = 'Refunded';
                } else if (bills.Pay_Status == 6) {
                  bills.status = 'Under Process';
                }
              }
            }
        }
        else {
          this.allBills = [];
        }
      }).catch((err) => {
        // this.dialog.open(AlertpopupComponent,{
        //   width: "350px",
        //   data: { title: err.statusText, message: err.status+' - '+err.statusText+' Talk with Administrator.', popup:'popup'}
        // });
        // console.log(err);
      }).finally(() => {
        this.inProgress = false;
      });
  }
  getLast(trans: any = 1){
    let formdata = new FormData();
    let billAll = this.formGroup.value;
    delete billAll.recharge_by;
    this.inProgress = true;
    formdata.append('params', JSON.stringify(billAll));
    formdata.append('period', "custom");
    this.http.postDataByForm('payrecharge/search_list', formdata)
      .then((data: any) => {
        console.log(data);
        this.inProgress = false;
        if (data.status == 1) {
          this.allBills = data.data.records;
          let count = 1;
          for (let bills of this.allBills) {
              if (bills.isDelete == 1) {
                bills.status = 'Deleted';
              } else if (bills.isDelete == 0) {
                if (bills.Pay_Status == 0) {
                  bills.status = 'Pending';
                } else if (bills.Pay_Status == 1) {
                  bills.status = 'Success';
                } else if (bills.Pay_Status == 2) {
                  bills.status = 'Operator Failed';
                } else if (bills.Pay_Status == 3) {
                  bills.status = 'System Failed';
                } else if (bills.Pay_Status == 4) {
                  bills.status = 'Under Process';
                } else if (bills.Pay_Status == 5) {
                  bills.status = 'Refunded';
                } else if (bills.Pay_Status == 6) {
                  bills.status = 'Under Process';
                }
              }
              // if(count >= trans){
                break;
              // }
              count++;
            }
        }
        else {
          this.allBills = [];
        }
      }).catch((err) => {
        // this.dialog.open(AlertpopupComponent,{
        //   width: "350px",
        //   data: { title: err.statusText, message: err.status+' - '+err.statusText+' Talk with Administrator.', popup:'popup'}
        // });
        // console.log(err);
      }).finally(() => {
        this.inProgress = false;
      });
  }
  openChatBox(){
    this.isChatbox = !this.isChatbox
  }
  changeTab(tab: any){
    this.allBills = [];
    this.tabname = tab;
  }
  closeMenu(){
    this.sideBarOpen = false;
  }
  sideBarToggle(event: any) {
      this.sideBarOpen = !this.sideBarOpen;
  }
}
