import { Component, OnInit } from '@angular/core';
import { AlertpopupComponent } from 'src/app/common/alertpopup/alertpopup.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {
  userFormgroup: FormGroup;
  inProgress: boolean = false;
  response: any;
  constructor(private _formBuilder: FormBuilder,
    private dialog: MatDialog,
    private http: HttpService
  ) { }

  ngOnInit(): void {
    this.userFormgroup = this._formBuilder.group({
      CurrentPassword: ['', Validators.required],
      Password: ['', Validators.required],
      CPassword: ['', Validators.required]
    });
  }

  saveRecord(){
      this.inProgress = true;
      let user = JSON.parse(sessionStorage.getItem('user'));
      let userData = user;
      if(this.userFormgroup.get('Password').value!=this.userFormgroup.get('CPassword').value){
        let dialogRef = this.dialog.open(AlertpopupComponent,{
          width: "350px",
          data: { title: "", message: "Password and Confirm Password not matched, Please, verify it",popup:'popup'}
        });
        dialogRef.afterClosed().subscribe(result => {
          console.log(result);
        });
      }
      else {
        let records = {
            Id: userData.Id,
            Password: this.userFormgroup.get('Password').value 
        };
        let data = JSON.stringify(records);
        this.inProgress = true;
        let formdata = new FormData();
        formdata.append('user',data);
        this.http.postDataByForm('users/changePassword',formdata)
                .then(data => {
                  this.inProgress = false;
                  this.response = data;
                  console.log(this.response);
                  if(this.response.status==1){
                    sessionStorage.setItem('Auth-token',this.response.data.authToken);
                    let dialogRef = this.dialog.open(AlertpopupComponent,{
                      width: "350px",
                      data: { title: 'Password change success!', message: "Password change successfully", popup:'popup'}
                    });
                    dialogRef.afterClosed().subscribe(result => {
                        this.userFormgroup.reset();
                    });
                  }
                  else {
                    let dialogRef = this.dialog.open(AlertpopupComponent,{
                      width: "350px",
                      data: { title: this.response.title, message: this.response.message,popup:'popup'}
                    });
                    dialogRef.afterClosed().subscribe(result => {
                      console.log(result);
                    });
                  }
                });
      }
  }
}
