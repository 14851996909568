<div id="printAvail" style="display: none;">
    <div *ngIf="reciepes.length>0">
        <table cellpadding="2px" cellspacing="0" style="width:100%" *ngFor="let reciepe of reciepes">
            <tbody>
                <tr>
                    <th colspan="2" align="center" style="border-bottom:1px dotted #000000;">
                        <table style="width: 100%;" cellspacing="0" cellpadding="0">
                            <tr>
                                <!-- <td>
                                <img [src]="imgSrc+'nirved-logo.png'" style="max-height:80px; float: left; margin-bottom: 10px;">
                            </td> -->
                                <td style="text-align: center;">
                                    <h3 style="padding:0px;margin:0px;margin-bottom:5px;font-weight: bold;font-style: italic;">Online Payment Receipt </h3>
                                    <h4 style="margin:0px;font-weight: bold;font-style: italic;">Domestic Money Transfer</h4>
                                </td>
                                <!-- <td>
                                <img [src]="imgSrc+'easy-pay.png'" style="max-height:50px; float: right;">
                            </td> -->
                            </tr>
                        </table>
                    </th>
                </tr>
                <tr>
                    <td style="border-bottom:1px dotted #000000;font-weight: bold;font-style: italic;">
                        Date : {{reciepe.Recharge_Date}}
                    </td>
                    <td style="text-align: right; border-bottom:1px dotted #000000;font-weight: bold;font-style: italic;">
                        Time : {{reciepe.payTime}}
                    </td>
                </tr>
                <tr>
                    <td style="font-weight: bold;font-style: italic;">
                        Service Number
                    </td>
                    <td style="font-weight: bold;font-style: italic;">
                        : {{reciepe.Invoice_No}}
                        <!-- getMaskValue() -->
                    </td>
                </tr>
                <tr>
                    <td style="font-weight: bold;font-style: italic;">
                        Customer Name
                    </td>
                    <td style="font-weight: bold;font-style: italic;">
                        : {{reciepe.Customer_Name}}
                    </td>
                </tr>
                <tr>
                    <td style="font-weight: bold;font-style: italic;">
                        Customer Phone
                    </td>
                    <td style="font-weight: bold;font-style: italic;">
                        : {{reciepe.Customer_Phone}}
                    </td>
                </tr>
                <tr>
                    <td style="font-weight: bold;font-style: italic;">
                        TXN Id
                    </td>
                    <td style="font-weight: bold;font-style: italic;">
                        : {{reciepe.bbpsReferenceNumber || '---'}}
                    </td>
                </tr>
                <tr>
                    <td style="font-weight: bold;font-style: italic;">Merchant ID</td>
                    <td style="font-weight: bold;font-style: italic;">: {{merchant_ID}}</td>
                </tr>
                <tr>
                    <td style="font-weight: bold;font-style: italic;">Receipt Number</td>
                    <td style="font-weight: bold;font-style: italic;">: {{reciepe.TXN_Id}}</td>
                </tr>
                <tr>
                    <td style="font-weight: bold;font-style: italic;">Txn Status</td>
                    <td style="font-weight: bold;font-style: italic;">: Success</td>
                </tr>
                <tr>
                    <td style="border-top:1px dotted #000000;font-weight: bold;font-style: italic;" align="left">Amount</td>
                    <th style="border-top:1px dotted #000000;font-weight: bold;font-style: italic;" align="left">Rs. {{reciepe.Amount | number: '1.2-2'}}</th>
                </tr>
                <tr>
                    <td align="left" style="font-weight: bold;font-style: italic;">Paid Amount</td>
                    <th align="left" style="font-weight: bold;font-style: italic;">Rs. {{reciepe.Pay_Amount | number: '1.2-2'}}</th>
                </tr>
                <tr>
                    <td align="left" style="font-weight: bold;font-style: italic;">Excess Amount</td>
                    <th align="left" style="font-weight: bold;font-style: italic;">Rs. {{reciepe.Pay_Amount - reciepe.Amount | number: '1.2-2'}}</th>
                </tr>
                <tr>
                    <th colspan="2" style="border-top:1px dotted #000000;font-weight: bold;font-style: italic;">Thank You For Using Our Services</th>
                </tr>
                <tr>
                    <td colspan="2" style="font-size: 10px;font-weight: bold;font-style: italic;">
                        <strong>Disclaimer -</strong>
                        <ol>
                            <li>Please check Service Number and amount mentioned in your receipt.</li>
                            <li>This receipt is valid subject to realization of cheque.</li>
                            <li>Receipt required for the settlement of deposit, If any</li>
                        </ol>
                    </td>
                </tr>
                <tr>
                    <th colspan="2" style="border-top:1px dotted #000000;font-size: 10px;">
                        <!-- Paid by Nirved Multiservices LLP<br> -->
                        <!-- Contact to Customer care : care@nirvedservices.in<br> -->
                        <!-- Nirved Multiservices LLP<br> -->
                        <!-- <strong>www.nirvedservices.in</strong><br> -->
                        <!-- Thanks for using Service of Nirved Multiservices LLP -->
                    </th>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<button id="btnPrint" style="display: none;" #btnPrint printSectionId="printAvail" ngxPrint>Print Now</button>
<app-page-loading [hidden]="!inProgress"></app-page-loading>
<div class="row" style="margin-left: 0px; margin-right: 0px;">
    <div fxLayoutAlign="center start" fxLayout="row" fxFlexFill>
        <div fxFlex="25">
            <mat-card class="box-design">
                <form [formGroup]="firstFormGroup" #aForm>
                    <mat-form-field class="example-full-width">
                        <mat-label>Mobile No*</mat-label>
                        <input matInput name="CUSTOMERNO" formControlName="CUSTOMERNO" [readonly]="inProgress || customerDetails || OTPsent" (change)="fetchUserRecords()" type="tel" placeholder="Enter Mobile No.">
                    </mat-form-field>
                    <div *ngIf="customerDetails">
                        <div class="mb-2">
                            <mat-label>First Name*</mat-label>
                            <div class="text-right" style="font-weight: bold;">{{customerDetails.FNAME}}</div>
                        </div>
                        <div class="mb-2">
                            <mat-label>Last Name*</mat-label>
                            <div class="text-right" style="font-weight: bold;">{{customerDetails.LNAME}}</div>
                        </div>
                        <div class="mb-3">
                            <mat-label>Status</mat-label>
                            <div class="text-right" style="font-weight: bold;">
                                <span class="alert" style="padding: 3px 5px;" [ngClass]="{'alert-success': customerDetails.STATUSDESC=='Active', 'alert-danger': customerDetails.STATUSDESC!='Active'}">{{customerDetails.STATUSDESC}}</span>
                            </div>
                        </div>
                        <div class="mb-2">
                            <mat-label>Transfer Limit</mat-label>
                            <div class="text-right" style="font-weight: bold;">{{customerDetails.LIMIT}}</div>
                        </div>
                        <div class="mb-2">
                            <mat-label>Limit Used</mat-label>
                            <div class="text-right" style="font-weight: bold;">{{customerDetails.USED}}</div>
                        </div>
                        <div class="mb-2">
                            <mat-label>Remain Limit</mat-label>
                            <div class="text-right" style="font-weight: bold;">{{customerDetails.REMAIN}}</div>
                        </div>
                        <div fxLayout="row" fxLayoutGap="15px" style="margin-bottom: 15px;" class="text-center">
                            <button type="submit" (click)="clearAll()" style="color: #ffffff;" mat-raised-button color="warn">Clear & Close</button>
                        </div>
                    </div>
                    <div fxLayout="row" *ngIf="!createUser && !customerDetails" fxLayoutGap="15px" style="margin-bottom: 15px;">
                        <button type="reset" mat-stroked-button color="primary">Clear</button>
                        <button type="submit" [disabled]="inProgress" (click)="fetchUserRecords()" mat-raised-button color="primary">Get Customer</button>
                    </div>
                    <div [hidden]="!createUser">
                        <mat-form-field class="example-full-width">
                            <mat-label>First Name*</mat-label>
                            <input matInput formControlName="FNAME" type="text" placeholder="Enter First Name*">
                        </mat-form-field>
                        <mat-form-field class="example-full-width">
                            <mat-label>Last Name*</mat-label>
                            <input matInput formControlName="LNAME" type="text" placeholder="Enter Last Name">
                        </mat-form-field>
                        <mat-form-field class="example-full-width">
                            <mat-label>City</mat-label>
                            <input matInput formControlName="CITY" type="text" placeholder="Enter City">
                        </mat-form-field>
                        <mat-form-field class="example-full-width">
                            <mat-label>Pincode</mat-label>
                            <input matInput formControlName="PCODE" type="text" placeholder="Enter PCODE">
                        </mat-form-field>
                        <div [hidden]="OTPsent" fxLayout="row" *ngIf="createUser" fxLayoutGap="15px" style="margin-bottom: 15px;">
                            <button type="reset" mat-stroked-button color="primary">Clear</button>
                            <button type="submit" [disabled]="!firstFormGroup.valid || inProgress" (click)="saveCustomer()" mat-raised-button color="primary">Submit</button>
                        </div>
                    </div>
                    <div [hidden]="!OTPsent">
                        <mat-form-field class="example-full-width">
                            <mat-label>OTP*</mat-label>
                            <input matInput formControlName="otp" type="number" placeholder="Enter OTP">
                        </mat-form-field>
                        <div fxLayout="row" fxLayoutGap="15px" style="margin-bottom: 15px;">
                            <button type="submit" (click)="ResendOTP()" mat-stroked-button color="primary">Resend</button>
                            <button type="submit" [disabled]="!firstFormGroup.valid || inProgress" (click)="customerVerifyOTP()" style="color: #ffffff;" mat-raised-button color="primary">Fetch</button>
                        </div>
                    </div>
                </form>
            </mat-card>
        </div>
        <div fxFlex="75" fxFlexFill style="max-height: 80vh; overflow-y: auto;">
            <div *ngIf="newBeneficiary">
                <form [formGroup]="beneficiaryGroup">
                    <mat-card class="box-design">
                        <div class="row">
                            <div class="col-md-7">
                                <h2 class="m-0">Add New Beneficiary</h2>
                            </div>
                            <div class="col-md-5 text-right">
                                <button type="submit" (click)="closeAddBeneficiary()" mat-raised-button color="warn">
                                    <mat-icon>close</mat-icon>
                                    Close</button>
                            </div>
                        </div>
                        <div style="clear: both;"></div>
                        <mat-divider></mat-divider>
                        <div class="row m-0 mt-2">
                            <div class="col-md-6">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Beneficiary Name*</mat-label>
                                    <input matInput formControlName="NAME" type="text" placeholder="Enter NAME">
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Beneficiary Mobile No*</mat-label>
                                    <input matInput formControlName="MOBILENO" type="tel" placeholder="Enter MOBILE NO">
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Beneficiary Bank Name*</mat-label>
                                    <mat-select formControlName="BANKID" (selectionChange)="getIFSC()" matNativeControl>
                                        <!-- <mat-option>
                                            [formControl]="bankCtrl"
                                            <ngx-mat-select-search [formControl]="bankFilterCtrl"></ngx-mat-select-search>
                                        </mat-option> -->
                                        <mat-option *ngFor="let bank of allBanks" value="{{bank.BANKID}}">{{bank.BANKNAME}}</mat-option>
                                    </mat-select>
                                    <!-- <input matInput formControlName="BANKID" [readonly]="inProgress" type="text" placeholder="Enter Bank Name"> -->
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Bank IFSC*</mat-label>
                                    <input matInput formControlName="IFSC" type="text" placeholder="Enter IFSC">
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Account No*</mat-label>
                                    <input matInput formControlName="ACCNO" type="text" placeholder="Enter Account No">
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Confirm Account No*</mat-label>
                                    <input matInput formControlName="REPEATACCNO" (paste)="$event.preventDefault()" type="text" placeholder="Enter Retype ACCNO">
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row m-0" fxLayoutGap="15px" style="margin-bottom: 15px;">
                            <button type="reset" mat-stroked-button color="primary">Clear</button>
                            <button type="submit" [disabled]="!beneficiaryGroup.valid || inProgress" (click)="saveBeneficiary()" mat-raised-button color="primary">Submit</button>
                            <button type="submit" [disabled]="!beneficiaryGroup.valid || inProgress" (click)="saveVerifyBeneficiary()" mat-raised-button color="primary">Verify & Submit</button>
                        </div>
                    </mat-card>
                </form>
            </div>
            <div *ngIf="!newBeneficiary">
                <mat-card class="box-design p-1">
                    <div class="row p-2">
                        <div class="col-md-7">
                            <h2 class="m-0">Beneficiary List</h2>
                        </div>
                        <div class="col-md-5 text-right" *ngIf="customerDetails">
                            <button type="submit" (click)="getBeneficiary()" mat-stroked-button color="primary">
                                <mat-icon>refresh</mat-icon>
                                Refresh</button>
                            <button type="submit" (click)="addBeneficiary()" mat-raised-button color="primary">+ Add Beneficiary</button>
                        </div>
                    </div>
                    <div style="clear: both;"></div>
                    <mat-divider></mat-divider>
                    <div *ngIf="Beneficiaries.length == 0">
                        <div class="pt-4 pb-4 text-center">
                            <strong>Opps! No Beneficiary found.</strong>
                        </div>
                    </div>
                    <div *ngIf="Beneficiaries.length > 0">
                        <div class="rechargecart">
                            <table width="100%" cellpadding="10" cellspacing="0">
                                <thead>
                                    <tr>
                                        <!-- <th>Remove</th> -->
                                        <th>
                                            Beneficiary Name
                                        </th>
                                        <th>
                                            Mobile No.
                                        </th>
                                        <th>
                                            Bank Name
                                        </th>
                                        <th>
                                            IFSC
                                        </th>
                                        <th>
                                            Account No
                                        </th>
                                        <th>
                                            Verified / Pay Now
                                        </th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="Beneficiaries.length>0">
                                    <tr *ngFor="let Beneficiary of Beneficiaries">
                                        <!-- <td>
                                            <button mat-icon-button color="warn" (click)="removeBaneficary(Beneficiary)">
                                                <mat-icon>delete_sweep</mat-icon>
                                            </button>
                                        </td> -->
                                        <td>{{Beneficiary.BENENAME}}</td>
                                        <td>{{Beneficiary.MOBILENO}}</td>
                                        <td>{{Beneficiary.BANKNAME}}</td>
                                        <td>{{Beneficiary.IFSC}}</td>
                                        <td>{{Beneficiary.ACCNO}}</td>
                                        <td>
                                            <button type="submit" matTooltip="Click to Verified your account" *ngIf="Beneficiary.VERIFIED=='0'" (click)="BeneficiaryAccountVerify(Beneficiary)" mat-raised-button color="warn">
                                                <mat-icon>close</mat-icon>
                                            </button>
                                            <button style="background-color: green !important" mat-raised-button color="primary" *ngIf="Beneficiary.VERIFIED=='1'">
                                                <mat-icon>done_all</mat-icon>
                                            </button>
                                            <button type="submit" (click)="sendMoney(Beneficiary)" mat-raised-button color="primary">
                                                Pay
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
</div>