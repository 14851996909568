<mat-nav-list class="drawer-items">
    <div class="profile-card fontColor">
        <div class="side-header">
            <h3 class="welcomeh2" style="margin-bottom: 0px;line-height: 10px;">Welcome </h3>
            <h3 class="welcomeh2"><strong>{{userName}}</strong></h3>
            <p>{{userRole}}</p>
        </div>
    </div>
    <mat-divider></mat-divider>
    <a mat-list-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/user">
        <mat-icon>home</mat-icon> Dashboard</a>
    <div *ngIf="userRole=='Super Admin'">
        <mat-divider></mat-divider>
        <h2 matSubheader>Bills</h2>
        <a mat-list-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/user/recharge">
            <mat-icon>article</mat-icon> All Bills</a>
        <a *ngIf="userRole=='Super Admin'" mat-list-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/user/pending-bills">
            <mat-icon>article</mat-icon> Pending Bill</a>
        <a *ngIf="userRole=='Super Admin'" mat-list-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/user/fail-to-success">
            <mat-icon>article</mat-icon> F2S Bills</a>
    </div>
    <div *ngIf="userRole=='Super Admin' || userRole=='Distributor'">
        <mat-divider></mat-divider>
        <h2 matSubheader>Management</h2>
        <a mat-list-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/user/user/retailer">
            <mat-icon>directions_run</mat-icon> Retailers</a>
        <a *ngIf="userRole=='Super Admin'" mat-list-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/user/user/distributor">
            <mat-icon>directions_run</mat-icon> Distributor</a>
    </div>
    <div *ngIf="userRole=='Retailer' || userRole=='Distributor'">
        <mat-divider></mat-divider>
        <h2 matSubheader>Transaction</h2>
        <a mat-list-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/user/my-transaction">
            <mat-icon>assignment</mat-icon> My Transaction</a>
        <a mat-list-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/user/account-summary">
            <mat-icon>article</mat-icon> Account Summary</a>
        <a mat-list-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/user/refund-summary">
            <mat-icon>article</mat-icon> Refund Summary</a>
    </div>
    <div *ngIf="userRole=='Super Admin'">
        <mat-divider></mat-divider>
        <h2 matSubheader>Transaction</h2>
        <a mat-list-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/user/transaction">
            <mat-icon>assignment</mat-icon> Transaction</a>
        <a mat-list-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/user/account-summary">
            <mat-icon>article</mat-icon> Account Summary</a>
        <a mat-list-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/user/refund-summary">
            <mat-icon>article</mat-icon> Refund Summary</a>
    </div>
    <mat-divider></mat-divider>
    <h2 matSubheader>Request</h2>
    <a mat-list-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/user/payment-options" *ngIf="userRole === 'Retailer' || userRole=='Distributor'">
        <mat-icon>account_balance</mat-icon> Payment options</a>
    <a mat-list-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/user/payment-options" *ngIf="userRole === 'Super Admin'">
        <mat-icon>account_balance</mat-icon> Manage Bank</a>
    <a mat-list-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/user/recharge-request">
        <mat-icon>assignment</mat-icon> Recharge Request</a>
    <a mat-list-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/user/recharge-request/credit">
        <mat-icon>article</mat-icon> Credit Request</a>
    <div *ngIf="userRole=='Super Admin'">
        <mat-divider></mat-divider>
        <h2 matSubheader>User Notice</h2>
        <a mat-list-item routerLinkActive="list-item-active" (click)="toggleSidebar()" routerLink="/user/user-notice">
            <mat-icon>warning</mat-icon> User Notice</a>
    </div>
</mat-nav-list>