import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LazyLoadEvent } from 'primeng/api';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-refund-summary',
  templateUrl: './refund-summary.component.html',
  styleUrls: ['./refund-summary.component.css']
})
export class RefundSummaryComponent implements OnInit {

  allBills: any = [];
  respo: any;
  cols: any[];
  first = 0;
  rows = 10;
  datasource: any = [];
  loading: boolean = false;
  totalRecords: number = 0;
  userRole: any = "Retailer";
  exportColumns: any[];
  openUser: boolean = false;
  inProgress: boolean = false;
  editId: any = "0";
  actionName: any = "Submit";
  paymentTypes: any = [];
  Credit: boolean = false;
  marchant: any = [];
  currentBalance: any = 0;
  isMobile: boolean = false;

  tType: any = "today";
  billAll: FormGroup;

  constructor(
    private http: HttpService,
    private _formBuilder: FormBuilder
  ) { 
      this.paymentTypes = [{type:'Credit'},{type:'Debit'}];
      this.userRole = sessionStorage.getItem('userRole');

      this.billAll = this._formBuilder.group({
        startDate: '',
        endDate: '',
      });

      if(window.innerWidth<=750){
        this.isMobile = true;
      }
  }

  ngOnInit(): void {
    this.getDetails();
    this.initTable();

    this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));

    this.loading = false;

}
changeType(type: any){
  this.tType = type;
  this.getDetails();
}
initTable(){
    this.cols = [
      { field: 'Txn_no', header: 'Txn. No.' },
      { field: 'Transaction_Type', header: 'Transaction Type' },
      { field: 'openingBal', header: 'Opening Balance'},
      { field: 'Transaction_Amount', header: 'Amount' },
      { field: 'finalBal', header: 'Final Balance' },
      { field: 'Description', header: 'Description' },
      { field: 'Name', header: 'Retainer Name' },
      { field: 'Created_on', header: 'Txn Date' }
    ];
  }

  loadCarsLazy(event: LazyLoadEvent) {
    this.loading = true;

    //in a real application, make a remote request to load data using state metadata from event
    //event.first = First row offset
    //event.rows = Number of rows per page
    //event.sortField = Field name to sort with
    //event.sortOrder = Sort order as number, 1 for asc and -1 for dec
    //filters: FilterMetadata object having field as key and filter value, filter matchMode as value

    //imitate db connection over a network
    setTimeout(() => {
        if (this.datasource) {
            this.allBills = this.datasource.slice(event.first, (event.first + event.rows));
            this.loading = false;
        }
    }, 1000);
}

  next() {
    this.first = this.first + this.rows;
  }

  prev() {
      this.first = this.first - this.rows;
  }

  reset() {
      this.first = 0;
  }

  isLastPage(): boolean {
      return this.first === (this.allBills.length - this.rows);
  }

  isFirstPage(): boolean {
      return this.first === 0;
  }
  searchBills() {
    this.inProgress = true;
    let formdata = new FormData();
    let billAll = this.billAll.value;
    formdata.append('userRole',this.userRole);
    // formdata.append('userRole','Super Admin');
    if(this.userRole=='Super Admin'){
      formdata.append('isForAdmin','Yes');
    }
    formdata.append("userId",sessionStorage.getItem('userId'));
    formdata.append('period',this.tType);
    let inCondition = ["Description LIKE '%Refund%' AND Created_on BETWEEN '"+billAll.startDate+"' AND '"+billAll.endDate+"'"];
    formdata.append('inCondition',JSON.stringify(inCondition));
    this.http.postDataByForm('transaction/get_list',formdata)
        .then(data => {
          console.log(data);
          this.inProgress = false;
          this.respo = data;
          if(this.respo.status==1){
            this.allBills = this.respo.data.records;
            this.totalRecords = this.datasource.length;
          }
        });
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
      import("file-saver").then(FileSaver => {
          let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
          let EXCEL_EXTENSION = '.xlsx';
          const data: Blob = new Blob([buffer], {
              type: EXCEL_TYPE
          });
          FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      });
  }
  getDetails(){
    this.inProgress = true;
    let formdata = new FormData();
    formdata.append('userRole',this.userRole);
    if(this.userRole=='Super Admin'){
      formdata.append('isForAdmin','Yes');
    }
    // formdata.append('userRole','Super Admin');
    formdata.append("userId",sessionStorage.getItem('userId'));
    formdata.append('period',this.tType);
    let inCondition = ["Description LIKE '%Refund%'"];
    formdata.append('inCondition',JSON.stringify(inCondition));
    this.http.postDataByForm('transaction/get_list',formdata)
        .then(data => {
          console.log(data);
          this.inProgress = false;
          this.respo = data;
          if(this.respo.status==1){
            //this.datasource = this.respo.data.records;
            this.allBills = this.respo.data.records;
            this.totalRecords = this.datasource.length;
          }
        });
  }
}
