import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertpopupComponent } from 'src/app/common/alertpopup/alertpopup.component';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-usernotice',
  templateUrl: './usernotice.component.html',
  styleUrls: ['./usernotice.component.css']
})
export class UsernoticeComponent implements OnInit {

  editId: any = "";
  FormGroup: FormGroup;
  actionName: any = "Save";
  first = 0;
  rows = 10;
  allRecords: any = [];
  totalRecords: any = 0;
  respo: any;
  openUser: boolean = false;
  inProgress: boolean = false;

  exportColumns: any[];

  constructor(
    private _formBuilder: FormBuilder,
    private http: HttpService,
    private dialog: MatDialog,
    private _router: Router
  ) { }
  setFirstLetterToUppercase(string:string):string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  createRecord(){
    this.FormGroup.reset();
    this.FormGroup.get("isactive").setValue('1');
    this.editId = 0;
    this.actionName = "Save";
    this.openUser = true;
  }
  closeUser() {
    this.FormGroup.reset();
    this.openUser = false;
    this.getUserRecords();
  }
  // Data tables
  next() {
    this.first = this.first + this.rows;
  }

  prev() {
      this.first = this.first - this.rows;
  }

  reset() {
      this.first = 0;
  }

  isLastPage(): boolean {
      return this.first === (this.allRecords.length - this.rows);
  }

  isFirstPage(): boolean {
      return this.first === 0;
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
}
  editRecords(record: any){
      this.FormGroup.patchValue({
        Id: record.Id,
        noticetitle: record.noticetitle,
        noticemessage: record.noticemessage,
        start_date: record.start_date,
        end_date: record.end_date,
        isactive: record.isactive
      });
      this.editId = record.Id;
      this.openUser= true;
      this.actionName = "Update";
  }
  changeStatus(record: any){
    let dialogRef = this.dialog.open(AlertpopupComponent,{
      width: "350px",
      data: { title: 'Sure to change status!', message: "Are you sure, You want to change the status of Bank.", popup:'confirm'}
    });
    dialogRef.afterClosed().subscribe(result => {
        if(result=='Yes'){
          this.changeConfirm(record);
        }
    });
  }
  changeConfirm(record: any){
    console.log(record);
    this.inProgress = true;
    record.isactive = record.isactive=='1'?'0':'1';
    let formdata = new FormData();
    formdata.append('record',JSON.stringify(record));
    formdata.append('table','usernotice');
    let url = "common/saveCommonRecord";
    this.updateRecord(url, formdata);
  }
  ngOnInit(): void {
    let date = new Date();
    this.FormGroup = this._formBuilder.group({
      noticetitle: ['Important Notice', Validators.required],
      noticemessage: ['', Validators.required],
      start_date: [''],
      end_date: [''],
      isactive: ['1'],
    });

    this.getUserRecords();
  }

  getUserRecords(){
    this.inProgress = true;
    let formdata = new FormData();
    formdata.append('table','usernotice');
    this.http.postDataByForm('common/get_list',formdata)
            .then(data => {
              this.inProgress = false;
              this.respo = data;
              if(this.respo.status==1){
                  this.allRecords = this.respo.data.records;
                  this.totalRecords = this.allRecords.length;
                  console.log(this.allRecords);
              }
            })
           .finally(() => {
              this.inProgress = false;
           });
  }
  saveUser(){
    this.inProgress = true;
    let data = this.FormGroup.value;
    if(this.editId!=0){
      data.Id = this.editId;
    }
    let formdata = new FormData();
    formdata.append('record',JSON.stringify(data));
    formdata.append('table','usernotice');
    let url = "common/saveCommonRecord";
    if(this.editId!=0){
      url = "common/saveCommonRecord";
      this.updateRecord(url, formdata);
    }
    else {
      this.saveRecord(url, formdata);
    }
  }
  updateRecord(url: any, data: any){
    this.http.postDataByForm(url,data)
            .then(data => {
              this.inProgress = false;
              this.respo = data;
              let Action = "Updated";
              if(this.respo.status==1){
                let dialogRef = this.dialog.open(AlertpopupComponent,{
                  width: "350px",
                  data: { title: Action+' successfully', message: "Notice record updated", popup:'popup'}
                });
                dialogRef.afterClosed().subscribe(result => {
                    this.getUserRecords();
                    this.FormGroup.get("noticemessage").setValue("");  
                });         
                if(this.editId!=0){
                  this.openUser = false;
                  this.editId = 0;
                }       
              }
              else {
                let dialogRef = this.dialog.open(AlertpopupComponent,{
                  width: "350px",
                  data: { title: 'Action fail', message: this.respo.message, popup:'popup'}
                });
                dialogRef.afterClosed().subscribe(result => {
                    this.getUserRecords();
                });
              }
            })
            .finally(() => {
              this.inProgress = false;
           });
  }
  saveRecord(url: any, data: any){
    this.http.postDataByForm(url,data)
            .then(data => {
              this.inProgress = false;
              this.respo = data;
              let Action = "Saved";
              if(this.respo.status==1){
                let dialogRef = this.dialog.open(AlertpopupComponent,{
                  width: "350px",
                  data: { title: Action+' successfully', message: "Notice record save successfully.", popup:'popup'}
                });
                dialogRef.afterClosed().subscribe(result => {
                  this.getUserRecords();
                  this.FormGroup.get("noticemessage").setValue("");  
                });         
              }
              else {
                let dialogRef = this.dialog.open(AlertpopupComponent,{
                  width: "350px",
                  data: { title: 'Action fail', message: this.respo.message, popup:'popup'}
                });
                dialogRef.afterClosed().subscribe(result => {
                  this.getUserRecords();
                });
              }
            })
           .finally(() => {
              this.inProgress = false;
           });
  }
  deleteRecord(record: any){
    let dialogRef = this.dialog.open(AlertpopupComponent, {
      width: "350px",
      data: { title: 'Are you sure to Delete!', message: "Are you sure to remove the record?", popup: 'confirm' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes') {
        this.delRecord(record);
      }
    });
  }
  delRecord(record: any){
    let formdata = new FormData();
    formdata.append('table','usernotice');
    formdata.append('Id',record.Id);
    this.http.postDataByForm("common/delCommonRecord",formdata)
        .then(data => {
          this.inProgress = false;
          this.respo = data;
          let Action = "Deleted";
          if(this.respo.status==1){
            let dialogRef = this.dialog.open(AlertpopupComponent,{
              width: "350px",
              data: { title: Action+' successfully', message: "Notice record deleted successfully.", popup:'popup'}
            });
            dialogRef.afterClosed().subscribe(result => {
              this.getUserRecords();
            });         
          }
          else {
            let dialogRef = this.dialog.open(AlertpopupComponent,{
              width: "350px",
              data: { title: 'Action fail', message: this.respo.message, popup:'popup'}
            });
            dialogRef.afterClosed().subscribe(result => {
            });
          }
        })
      .finally(() => {
          this.inProgress = false;
      });
  }
}
