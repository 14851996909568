import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './layouts/default/default.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { PaybillComponent } from './modules/paybill/paybill.component';
import { AllbillsComponent } from './modules/allbills/allbills.component';
import { PendingbillsComponent } from './modules/pendingbills/pendingbills.component';
import { MybillsComponent } from './modules/mybills/mybills.component';
import { BillerComponent } from './modules/biller/biller.component';
import { UsersComponent } from './modules/users/users.component';
import { WalletmanagementComponent } from './modules/walletmanagement/walletmanagement.component';
import { PushwalletComponent } from './modules/pushwallet/pushwallet.component';
import { MytransactionComponent } from './modules/mytransaction/mytransaction.component';
import { BanksComponent } from './modules/banks/banks.component';
import { PaymentoptionsComponent } from './modules/paymentoptions/paymentoptions.component';
import { AddtransationComponent } from './modules/addtransation/addtransation.component';
import { ChangepasswordComponent } from './modules/changepassword/changepassword.component';
import { MyprofileComponent } from './modules/myprofile/myprofile.component';
import { MoneytransferComponent } from './modules/moneytransfer/moneytransfer.component';
import { DisabledpageComponent } from './disabledpage/disabledpage.component';
import { UsernoticeComponent } from './modules/usernotice/usernotice.component';
import { FailtosuccessComponent } from './modules/failtosuccess/failtosuccess.component';
import { DeviceConnectComponent } from './common/device-connect/device-connect.component';
import { RefundSummaryComponent } from './modules/refund-summary/refund-summary.component';

const routes: Routes = [
    {
        path: '',
        component: LoginComponent
    },
    {
      path: 'service-disabled',
      component: DisabledpageComponent
    },
    {
        path: 'user',
        component: DefaultComponent,
        children: [
          {
            path: '',
            component: DashboardComponent
          },
          {
            path: 'billers',
            component: BillerComponent
          },
          {
            path: 'recharge',
            component: AllbillsComponent
          },
          {
            path: 'wallets',
            component: WalletmanagementComponent
          },
          {
            path: 'banks',
            component: BanksComponent
          },
          {
            path: 'payment-options',
            component: PaymentoptionsComponent
          },
          {
            path: 'recharge-request',
            component: PushwalletComponent
          },
          {
            path: 'refund-summary',
            component: RefundSummaryComponent
          },
          {
            path: 'recharge-request/:paidby',
            component: PushwalletComponent
          },
          {
            path: 'user/:userType',
            component: UsersComponent
          },
          {
            path: 'pending-bills',
            component: PendingbillsComponent
          },
          {
            path: 'my-transaction',
            component: MybillsComponent
          },
          {
            path: 'transaction',
            component: MybillsComponent
          },
          {
            path: 'add-recharge',
            component: PaybillComponent
          },
          {
            path: 'account-summary',
            component: MytransactionComponent
          },
          {
            path: 'recharge/:billtype',
            component: PaybillComponent
          },
          {
            path: 'transaction',
            component: MytransactionComponent
          },
          {
            path: 'add-transaction',
            component: AddtransationComponent
          },
          {
            path: 'change-password',
            component: ChangepasswordComponent
          },
          {
            path: 'my-profile',
            component: MyprofileComponent
          },
          {
            path: 'money-transfer',
            component: MoneytransferComponent
          },
          {
            path: 'user-notice',
            component: UsernoticeComponent
          },
          {
            path: 'fail-to-success',
            component: FailtosuccessComponent
          },
          {
            path: 'device-connect',
            component: DeviceConnectComponent
          }
        ]
    },
    { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
