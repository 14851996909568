<div class="spinner" fxLayoutAlign="center center" fxFlexFill [hidden]="!inProgress">
    <mat-spinner diameter="75"></mat-spinner>
    <img src="../../../favicon.ico" style="height:67px; position: absolute;">
</div>
<div class="loginBackground row justify-content-center align-self-center h-100vh">
    <nav class="navbar navbar-expand-lg navbar-dark navbar-custom fixed-top" style="right: auto !important;">
        <!-- Image Logo -->
        <a class="navbar-brand logo-image" href="/"><img src="/assets/images/handt-logo.png" style="height: 5rem;" alt="H & T Services"> H & T Services</a>
    </nav>
    <header id="header" class="header">
        <div class="header-content">
            <div class="container" style="background: url(/assets/images/logo.jpeg); background-position: center center; background-size: contain !important; background-repeat: no-repeat;">
                <div class="row">
                    <div class="col-lg-12 h-100" style="padding-top: 5rem; background: url(/assets/images/logo.jpeg);" *ngIf="isMobile">
                        <div class="image-container row h-100 mx-auto">
                            <div class="col-lg-4 col-md-4">&nbsp;</div>
                            <div class="col-lg-8 col-md-8">
                                <mat-card>
                                    <form [formGroup]="firstFormGroup">
                                        <h2 style="text-align: center;">User Login</h2>
                                        <mat-form-field>
                                            <mat-label>Email Id</mat-label>
                                            <input matInput class="inputForm" fxFlex="100" (change)="checkEmailId()" placeholder="EmailId" type="email" formControlName="email_id" required>
                                        </mat-form-field>
                                        <div [hidden]="invalidUser">
                                            <mat-form-field>
                                                <mat-label>Password</mat-label>
                                                <input matInput type="password" class="inputForm" fxFlex="100" placeholder="Password" formControlName="password" required>
                                            </mat-form-field>
                                            <mat-label class="forgetcss"><button type="button" mat-button color="primary">Forget password ?</button></mat-label>
                                            <mat-form-field [hidden]="!ipChange">
                                                <mat-label>OTP</mat-label>
                                                <input matInput type="text" (keyup)="verifyOTP()" class="inputForm" fxFlex="100" placeholder="OTP" formControlName="OTP">
                                                <small style="color: red;">OTP sent on your register Email Id</small>
                                            </mat-form-field>
                                            <label for="" *ngIf="notify!=''">
                                            <small style="color: red;">{{notify}}</small>
                                        </label>
                                        </div>
                                        <div *ngIf="invalidUser" style="text-align: center;">
                                            <button type="button" mat-raised-button (click)="checkValidUser()" [disabled]="inProgress" color="primary">Login <mat-icon>login</mat-icon></button>
                                        </div>
                                        <div *ngIf="!invalidUser" style="text-align: center;">
                                            <button type="button" mat-raised-button (click)="loginNow()" [disabled]="(!firstFormGroup.valid) || !validForm || inProgress" color="primary">Login <mat-icon>login</mat-icon></button>
                                        </div>
                                    </form>
                                </mat-card>
                            </div>
                        </div>
                    </div>
                    <!-- end of col -->
                    <div class="col-lg-3"></div>
                    <div class="col-lg-6" *ngIf="!isMobile">
                        <div class="image-container row mx-auto align-items-center" style="height: 90vh;">
                            <div class="col-lg-2 col-md-2">&nbsp;</div>
                            <div class="col-lg-8 col-md-8">
                                <mat-card style="background: #fffffff2;">
                                    <form [formGroup]="firstFormGroup">
                                        <h2 style="text-align: center;">User Login</h2>
                                        <mat-form-field>
                                            <mat-label>Email Id</mat-label>
                                            <input matInput class="inputForm" fxFlex="100" (change)="checkEmailId()" placeholder="EmailId" type="email" formControlName="email_id" required>
                                        </mat-form-field>
                                        <div [hidden]="invalidUser">
                                            <mat-form-field>
                                                <mat-label>Password</mat-label>
                                                <input matInput type="password" class="inputForm" fxFlex="100" placeholder="Password" formControlName="password" required>
                                            </mat-form-field>
                                            <mat-label class="forgetcss"><button type="button" mat-button color="primary">Forget password ?</button></mat-label>
                                            <mat-form-field [hidden]="!ipChange">
                                                <mat-label>OTP</mat-label>
                                                <input matInput type="text" (keyup)="verifyOTP()" class="inputForm" fxFlex="100" placeholder="OTP" formControlName="OTP">
                                                <small style="color: red;">OTP sent on your register Email Id</small>
                                            </mat-form-field>
                                            <label for="" *ngIf="notify!=''">
                                            <small style="color: red;">{{notify}}</small>
                                        </label>
                                        </div>
                                        <div *ngIf="invalidUser" style="text-align: center;">
                                            <button type="button" mat-raised-button (click)="checkValidUser()" [disabled]="inProgress" color="primary">Login <mat-icon>login</mat-icon></button>
                                        </div>
                                        <div *ngIf="!invalidUser" style="text-align: center;">
                                            <button type="button" mat-raised-button (click)="loginNow()" [disabled]="(!firstFormGroup.valid) || inProgress" color="primary">Login <mat-icon>login</mat-icon></button>
                                        </div>
                                    </form>
                                </mat-card>
                            </div>
                            <div class="col-lg-2 col-md-2">&nbsp;</div>
                        </div>
                        <!-- end of image-container -->
                    </div>
                    <!-- end of col -->
                </div>
                <!-- end of row -->
            </div>
            <!-- end of container -->
        </div>
        <!-- end of header-content -->
    </header>
</div>