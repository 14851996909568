<app-page-loading [hidden]="!inProgress"></app-page-loading>
<div *ngIf="openUser">
    <form [formGroup]="FormGroup">
        <div class="row" style="margin-right: 0px; margin-left: 0px; margin-top: 15px;">
            <div class="col-md-12">
                <mat-card>
                    <mat-card-header>
                        <mat-card-title>{{userType}} Records</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <mat-divider></mat-divider>
                        <div>&nbsp;</div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Name</label>
                                    <input class="form-control" placeholder="Name" formControlName="Name" required>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Contact No</label>
                                    <input class="form-control" placeholder="Contact No" formControlName="Contact_No" required>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Address</label>
                                    <textarea class="form-control" placeholder="Address" formControlName="Address" required></textarea>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>City</label>
                                    <input class="form-control" placeholder="City" formControlName="City" required>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Email Id</label>
                                    <input class="form-control" placeholder="Email Id" formControlName="Email_Id" required>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Password</label>
                                    <input class="form-control" type="password" placeholder="Password" formControlName="Password" required>
                                </div>
                            </div>
                            <div class="col-md-4" [hidden]="userRole=='Admin' || userRole=='Super Admin'">
                                <div class="form-group">
                                    <label>Bill Format</label>
                                    <input class="form-control" placeholder="Bill Format" formControlName="billFormat">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="file">Adhar Card</label>
                                    <input id="file" type="file" class="form-control" (change)="onFileChange($event)">
                                </div>
                                <img [src]="imageSrc" *ngIf="imageSrc" style="height: 200px;">
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="file1">Pan Card</label>
                                    <input id="file1" type="file" class="form-control" (change)="onFileBackground($event)">
                                </div>
                                <img [src]="imageSrc1" *ngIf="imageSrc1" style="height: 200px;">
                            </div>
                            <div class="col-md-4" [hidden]="userRole!=='Super Admin'">
                                <label for="file1">Parent User</label>
                                <select name="parentId" class="form-control" id="parentId" formControlName="Parent_Id">
                        <option *ngFor="let user of userByRoles" value="{{user.Id}}">{{user.Name}} [{{user.userRole}}]</option>
                    </select>
                            </div>
                            <div class="col-md-12 text-right">
                                <button mat-raised-button color="warn" (click)="closeUser()" style="margin-right: 15px;">
                        <mat-icon>close</mat-icon> Clear and Close
                    </button>
                                <button mat-raised-button color="primary" [disabled]="!FormGroup.valid || inProgress" (click)="saveUser()">
                        <mat-icon>save</mat-icon> {{actionName}} <mat-icon>forward</mat-icon>
                    </button>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </form>
</div>
<div *ngIf="!openUser">
    <mat-divider></mat-divider>
    <div class="row" style="padding: 10px; margin-right:0px; margin-left: 0px">
        <div class="col-md-12 text-right" style="padding-right:0px; padding-left:0px; padding-bottom: 15px;">
            <button mat-raised-button color="primary" (click)="createRecord()"><mat-icon>add_circle</mat-icon> Create New {{userType}}</button>
        </div>
        <div class="col-md-12" style="padding-right:0px; padding-left:0px" *ngIf="allRecords">
            <p-table #dt [columns]="cols" [value]="allRecords" [paginator]="true" [rows]="100" [showCurrentPageReport]="true" [totalRecords]="totalRecords" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [responsive]="true">
                <ng-template pTemplate="caption">
                    <div style="font-size: 20px;">
                        {{userType}} Records
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div style="text-align: left">
                                <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                                <input type="text" pInputText size="50" placeholder="Search Global filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="ui-helper-clearfix" style="text-align: right">
                                <button type="button" pButton icon="pi pi-file-excel" iconPos="left" label="EXCEL" (click)="dt.exportCSV()" style="margin-right: 0.5em;"></button>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 130px;">
                            Merchant Id
                        </th>
                        <th>
                            Name
                        </th>
                        <th>
                            {{parentType}}
                        </th>
                        <th>
                            Address
                        </th>
                        <th>
                            Contact Info
                        </th>
                        <th>Balance</th>
                        <th>
                            Created By
                        </th>
                        <th style="width: 120px;" *ngIf="userRole=='Super Admin' || userRole=='Distributor'">
                            Action
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-record>
                    <tr>
                        <td>
                            {{record.merchant_ID}}
                        </td>
                        <td>
                            {{record.Name}}
                        </td>
                        <td>
                            {{record.Parent_Name}}
                        </td>
                        <td>
                            {{record.City}}<br> {{record.Address}}
                        </td>
                        <td title="{{record.Email_Id}}">
                            {{record.Contact_No}}
                            <br> {{record.Email_Id}}
                        </td>
                        <td>
                            {{record.balance | number:'.2'}}
                        </td>
                        <td>
                            {{record.Register_Date}} by {{record.Created_by}}
                        </td>
                        <td *ngIf="userRole=='Super Admin' || userRole=='Distributor'">
                            <button type="button" mat-raised-button color="warn" *ngIf="record.is_Active==0" (click)="changeStatus(record)"> <mat-icon>close</mat-icon> </button>
                            <button type="button" mat-raised-button color="success" *ngIf="record.is_Active!=0" (click)="changeStatus(record)"> <mat-icon>check</mat-icon> </button>
                            <br>
                            <!-- <button type="button" mat-icon-button (click)="viewDocs(record)" color="warn"><mat-icon>remove_red_eye</mat-icon></button> -->
                            <button type="button" mat-icon-button (click)="editRecords(record)" color="primary"><mat-icon>edit</mat-icon></button>
                        </td>
                        <!-- <td *ngIf="userRole!=='Super Admin'">
                            <button type="button" mat-raised-button color="warn" *ngIf="record.is_Active==0">Inactive</button>
                            <button type="button" mat-raised-button color="primary" *ngIf="record.is_Active!=0">Active</button>
                        </td> -->
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                    There are {{allRecords?.length}} Records
                </ng-template>
            </p-table>
        </div>
    </div>
</div>