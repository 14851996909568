import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-money-transfer',
  templateUrl: './money-transfer.component.html',
  styleUrls: ['./money-transfer.component.css']
})
export class MoneyTransferComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
