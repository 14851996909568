import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobiloadmoney',
  templateUrl: './mobiloadmoney.component.html',
  styleUrls: ['./mobiloadmoney.component.css']
})
export class MobiloadmoneyComponent implements OnInit {
  inProgress: boolean = false;
  tType: any = "month";
  allBills: any = [];
  totalRecords: any = 0;
  cols: any = [];
  constructor() { }
  initTable(){
    this.cols = [
      // { field: 'Id', header: 'Id' },
      // { field: 'TXN_Id', header: 'TXN Id' },
      { field: 'Recharge_Type', header: 'Service Type' },
      { field: 'Invoice_No', header: 'Service No.' },
      { field: 'Amount', header: 'Amount' },
      { field: 'Customer_Name', header: 'Customer Name' },
      // { field: 'Customer_Phone', header: 'Customer Phone' },
      { field: 'Recharge_Date', header: 'Paid Date' },
    ];
  }
  ngOnInit(): void {
      this.initTable();
  }
  loadWallet(){

  }
  changeType(type: any){
    this.tType = type;
    this.loadWallet();
  }
}
