import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ShareserviceService } from 'src/app/services/shareservice.service';
import { HttpService } from 'src/app/services/http.service';
import { AlertpopupComponent } from 'src/app/common/alertpopup/alertpopup.component';
import { ActivatedRoute, Router } from '@angular/router';
import { interval } from 'rxjs';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

declare var require: any;
var convertRupeesIntoWords = require('convert-rupees-into-words');

@Component({
  selector: 'app-paybill',
  templateUrl: './paybill.component.html',
  styleUrls: ['./paybill.component.css']
})
export class PaybillComponent implements OnInit, OnDestroy {

  //displayedColumns: string[] = ['Recharge Type', 'Invoice No', 'Customer Name', 'Mobile','Amount','Due Date'];

  @ViewChild('btnPrint') btnPrint: ElementRef;
  @ViewChild('aForm') aForm: ElementRef;

  matcher = new MyErrorStateMatcher();
  firstFormGroup: FormGroup;
  cartList: any = [];
  reciepes: any = [];
  allBills: any = [];
  // subscription: any;

  transactionNo: number = Date.now();
  currentBalance: number = 0;
  cartAmount: number = 0;
  location: any = "";
  billFetched: boolean = false;
  merchant_ID: any = "";
  receivedAmount: any = "0";
  remainAmount: any = "";

  displayedColumns: string[] = ['recharge_type', 'invoice_no', 'customer_name', 'amount'];

  biller_response: any = [];
  billers: any;
  respo: any;
  imgSrc: any;

  inProgress: boolean = false;
  isRed: boolean = false;
  printAvail: boolean = true;
  billType: any = "All";
  billerCity: any = "";
  billimage: any = "";

  billerId: any = "0";
  isEditable: boolean = true;
  billFetchAmount: any = 0;

  isWindowClosed: boolean = true;
  isFetchable: boolean = true;

  constructor(
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    private shareService: ShareserviceService,
    private http: HttpService,
    private route: ActivatedRoute,
    private _router: Router
  ) {

    let date = new Date().getHours();
    if (date >= 6 && date <= 19) {
      this.isWindowClosed = true;
    }
    else {
      this.isWindowClosed = false;
    }
    console.log(this.isWindowClosed);

    this.imgSrc = this.http.serviceUrl + "images/";
    this.shareService.accountBalance.subscribe(value => {
      this.currentBalance = value;
    });
    if (sessionStorage.getItem('balance')) {
      this.currentBalance = parseFloat(sessionStorage.getItem('balance'));
    }
    this.merchant_ID = sessionStorage.getItem('merchant_ID');
    this.route.paramMap.subscribe(params => {
      this.billType = params.get('billtype');
      this.billimage = this.billType + "-payment.png";
    });

    if (sessionStorage.getItem('userRole') != 'Retailer') {
      this._router.navigate(['/user']);
    }

    // if(sessionStorage.getItem('cartList')){
    //   this.cartList = JSON.parse(sessionStorage.getItem('cartList')); 
    //   for(let i=0;i<this.cartList.length;i++){
    //       this.cartAmount += parseFloat(this.cartList[i].amount);
    //   }  
    // }

    this.firstFormGroup = this._formBuilder.group({
      recharge_type: ['', Validators.required],
      invoice_no: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9a-zA-Z]{1,}')
      ])],
      customer_name: [''],
      customer_mobile: ['', Validators.compose([
        Validators.pattern('^[0-9]{10}')
      ])],
      amount: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9]{1,5}')
      ])],
      charge_amount: ['0'],
      due_date: [''],
    });

    if(this.billType=="electricity" || this.billType=="gas"){
      // this.firstFormGroup.get("customer_name").setValidators([Validators.required]);
      this.firstFormGroup.get("due_date").setValidators([Validators.required]);
    }
    this.getBillers();
  }
  getReturnAmount() {
    console.log(this.receivedAmount);
    if (this.receivedAmount != 0) {
      this.remainAmount = this.cartAmount - parseFloat(this.receivedAmount);
    }
    else {
      this.remainAmount = "";
    }
    console.log(this.remainAmount);
  }
  clearReturnAmount() {
    this.remainAmount = "";
    this.receivedAmount = "0";
  }
  getBillers() {
    this.inProgress = true;
    let param = new FormData();
    param.append('city', sessionStorage.getItem('City'));
    param.append('billType', this.billType);
    this.http.postDataByForm('billers/get_billers', param)
      .then(data => {
        this.inProgress = false;
        this.biller_response = data;
        // console.log(this.biller_response);
        if (this.biller_response.status == 1) {
          this.billers = this.biller_response.data.records;
          this.firstFormGroup.get('recharge_type').setValue(this.billers[0].Id);
          this.clearPrint();
          // console.log(this.billers);
        }
        else {
          console.log('Not fetching');
        }
      });
  }
  clearPrint() {
    this.reciepes = [];
    this.billerId = this.firstFormGroup.get('recharge_type').value;
    console.log(this.billerId);
    this.billers.forEach(element => {
      if (element.Id === this.billerId) {
        this.billerCity = element.city;
        if (element.params == '') {
          this.isFetchable = false;
          if(element.Id == '55'){
            this.isRed = true;
            this.firstFormGroup.get("customer_mobile").setValidators(Validators.compose([
              Validators.required,
              Validators.pattern('^[0-9]{10}')
            ]));
            this.firstFormGroup.get('charge_amount').setValidators([Validators.required]);
            this.firstFormGroup.get('charge_amount').setValue("");
            this.firstFormGroup.get("customer_name").setValidators([Validators.required]);
          }
        }
        else {
          this.isFetchable = true;
        }
        console.log(this.isFetchable);
      }
    });
  }
  changeAmount() {
    if (!this.isEditable) {
      if (parseFloat(this.firstFormGroup.get('amount').value) < parseFloat(this.billFetchAmount)) {
        this.firstFormGroup.get('amount').setValue(this.billFetchAmount);
      }
    }
    if(parseFloat(this.firstFormGroup.get('amount').value) > 0){
        // let payable = this.firstFormGroup.get('amount').value;
        // let Amount  = this.firstFormGroup.get('amount').value/10;
        // let fullamount = Math.floor(Amount)*10;
        // let change = payable - fullamount;
        // console.log(change);
        let convenience = parseFloat(this.firstFormGroup.get('amount').value) * 0.5 / 100;
        convenience = Math.round(convenience/10) * 10;
        convenience = convenience < 15 ? 15 : convenience;
        // convenience = convenience + (5 - change);
        // convenience = Math.round(convenience/10) * 10;
        // convenience = Math.ceil(convenience);
        convenience = convenience > 50 ? 50 : convenience;
        if(this.firstFormGroup.get('recharge_type').value == '55'){
          this.firstFormGroup.get('charge_amount').setValue(convenience);
        }
    }
  }
  getBillerDetails() {
    this.isEditable = true;
    this.billFetchAmount = 0;
    if (this.isFetchable) {
      if (this.firstFormGroup.get('invoice_no').value != '') {
        this.inProgress = true;
        let formdata = new FormData();
        formdata.append('billerId', this.billerId);
        formdata.append('city', this.billerCity);
        formdata.append('invoice_no', this.firstFormGroup.get('invoice_no').value);
        formdata.append('recharge_type', this.firstFormGroup.get('recharge_type').value);
        console.log(this.billerId + " " + this.billerCity);
        this.http.postDataByForm('payrecharge/fetchBill', formdata)
          .then((data: any) => {
            this.inProgress = false;
            let response = data;
            if (response.status == 1) {
              try {
                console.log(response.data);
                response = JSON.parse(response.data);
              } catch (e) { }
              if (response.responseCode == "000") {
                this.isEditable = false;
                let billDetails = response.billerResponse
                let billAmount = parseFloat(billDetails.billAmount) / 100;
                this.billFetchAmount = parseFloat(billDetails.billAmount) / 100;
                this.firstFormGroup.get('amount').setValue(billAmount);
                this.firstFormGroup.get('customer_name').setValue(billDetails.customerName);
                this.firstFormGroup.get('due_date').setValue(billDetails.dueDate);
                this.addToCart();
              }
              if(response.data){
                if (response.data.errorInfo) {
                  let dialogRef = this.dialog.open(AlertpopupComponent, {
                    width: "350px",
                    data: { title: "No bill fetched", message: response.data.errorInfo.errorMessage, popup: 'popup' }
                  });
                  dialogRef.afterClosed().subscribe(result => {
                  });
                }
              }
              else {
                if (response.errorInfo) {
                  let dialogRef = this.dialog.open(AlertpopupComponent, {
                    width: "350px",
                    data: { title: "No bill fetched", message: response.errorInfo[0].errorMessage, popup: 'popup' }
                  });
                  dialogRef.afterClosed().subscribe(result => {
                  });
                }
              }
            }
          });
      }
      else {
        this.billFetched = false;
      }
    }
  }
  payNow() {
    this.changeAmount();
    this.inProgress = true;
    let formdata = new FormData();
    let data = this.firstFormGroup.value;
    let billerName = data.recharge_type.split("#");
    data.recharge_type = billerName[0];
    formdata.append('recharge', JSON.stringify(data));
    formdata.append('paytype', 'single');
    formdata.append('User_Id', sessionStorage.getItem('userId'));
    formdata.append('Created_by', sessionStorage.getItem('userName'));
    this.http.postDataByForm('payrecharge/saveRecords', formdata)
      .then(data => {
        console.log(data);
        this.inProgress = false;
        this.respo = data;
        if (this.respo.status == 1) {
          this.firstFormGroup.reset();
          for (let i = 0; i < this.respo.data.records.length; i++) {
            this.respo.data.records[i].rsInWords = convertRupeesIntoWords(parseFloat(this.respo.data.records[i].Amount));
            this.reciepes.push(this.respo.data.records[i]);
          }
          // let dialogRef = this.dialog.open(AlertpopupComponent,{
          //   width: "350px",
          //   data: { title: this.respo.title, message: this.respo.message, popup:'popup'}
          // });
          // dialogRef.afterClosed().subscribe(result => {
          let currentBalance = this.respo.data.balance;
          sessionStorage.setItem('balance', currentBalance.toString());
          this.currentBalance = currentBalance;
          this.shareService.accountBalance.next(currentBalance);
          // });
          this.firstFormGroup.get('recharge_type').setValue(this.billers[0].Id);
          this.firstFormGroup.get('charge_amount').setValue(0);
          this.firstFormGroup.get('charge_amount').clearValidators();
          this.isFetchable = true;
          this.firstFormGroup.get("customer_mobile").clearValidators();
          this.firstFormGroup.get("customer_name").clearValidators();
          this.isRed = false;
          let timer = setTimeout(() => {
            console.log("I am called");
            this.openPrint();
            clearTimeout(timer);
          }, 1000);
        }
        else {
          let dialogRef = this.dialog.open(AlertpopupComponent, {
            width: "350px",
            data: { title: this.respo.title, message: this.respo.message, popup: 'popup' }
          });
          dialogRef.afterClosed().subscribe(result => {
          });
        }
      });
  }
  changePay(i: any) {
    let changeAmt = this.cartList[i].amount;
    let original = this.cartList[i].orginalAmount;
    if (changeAmt == '') {
      this.cartList[i].amount = original;
    }
    if (parseFloat(changeAmt) < parseFloat(original)) {
      this.cartList[i].amount = original;
      let dialogRef = this.dialog.open(AlertpopupComponent, {
        width: "350px",
        data: { title: "Less amount not allowed!", message: "You can not pay the less amount of biller.", popup: 'popup' }
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }
    this.cartAmount = 0;
    this.cartList.forEach(element => {
      element.charge_amount = element.charge_amount ? element.charge_amount : 0;
      this.cartAmount = parseFloat(this.cartAmount.toString()) + (parseFloat(element.amount) + parseFloat(element.charge_amount));
      console.log(element);
    });
    console.log(this.cartList);
  }
  payGroupNow() {
    if (parseFloat(this.cartAmount.toString()) < parseFloat(this.currentBalance.toString())) {
      this.inProgress = true;
      let formdata = new FormData();
      formdata.append('recharge', JSON.stringify(this.cartList));
      formdata.append('paytype', 'multiple');
      formdata.append('User_Id', sessionStorage.getItem('userId'));
      formdata.append('Created_by', sessionStorage.getItem('userName'));
      this.http.postDataByForm('payrecharge/saveRecords', formdata)
        .then(data => {
          this.inProgress = false;
          this.respo = data;
          console.log(this.respo);
          if (this.respo.status == 1) {
            // let p = this.dialog.open(PaymentProcessPopupComponent, {
            //   panelClass: 'payment-process-popup',
            //   data: { transcationId: this.respo.data.records[0].TXN_Id }
            // });
              let result = this.respo.data.records[0];
            // p.afterClosed().subscribe(result => {
              this.cartList = [];
              this.cartAmount = 0;
              sessionStorage.removeItem('cartList');
              this.cartList = [];
              let currentBalance = this.respo.data.balance;
              this.currentBalance = currentBalance;
              sessionStorage.setItem('balance', currentBalance.toString());
              this.shareService.accountBalance.next(currentBalance);
              for (let i = 0; i < this.respo.data.records.length; i++) {
                this.respo.data.records[i].rsInWords = convertRupeesIntoWords(parseFloat(this.respo.data.records[i].Amount));
                this.respo.data.records[i].bbpsReferenceNumber = result.bbpsReferenceNumber || '';
                this.respo.data.records[i].Pay_Status = result.Pay_Status || '';
                if (result.Pay_Status == 0) {
                  this.respo.data.records[i].status = 'Under Process';
                } else if (result.Pay_Status == 1) {
                  this.respo.data.records[i].status = 'Success';
                } else if (result.Pay_Status == 2) {
                  this.respo.data.records[i].status = 'Operator Failed';
                } else if (result.Pay_Status == 3) {
                  this.respo.data.records[i].status = 'System Failed';
                } else if (result.Pay_Status == 4) {
                  this.respo.data.records[i].status = 'Under Process';
                } else if (result.Pay_Status == 5) {
                  this.respo.data.records[i].status = 'Refunded';
                } else if (result.Pay_Status == 6) {
                  this.respo.data.records[i].status = 'Under Process';
                }
                this.reciepes.push(this.respo.data.records[i]);
              }
              this.firstFormGroup.get('recharge_type').setValue(this.billers[0].Id);
              this.billerCity = this.billers[0].city;
              this.billerId = this.billers[0].Id;
              this.isFetchable = true;
              this.clearReturnAmount();
              if (result) {
                console.log(result);
                let timer = setTimeout(() => {
                  this.openPrint();
                  clearTimeout(timer);
                }, 1000);
              }
            // });
          }
          else {
            let dialogRef = this.dialog.open(AlertpopupComponent, {
              width: "350px",
              data: { title: this.respo.title, message: this.respo.message, popup: 'popup' }
            });
            dialogRef.afterClosed().subscribe(result => {
              this.cartList = [];
              this.cartAmount = 0;
            });
          }
        });
    }
    else {
      let dialogRef = this.dialog.open(AlertpopupComponent, {
        width: "350px",
        data: { title: "Insufficient balance!", message: "Insufficient balance in your wallet. Kindly topup your wallet.", popup: 'popup' }
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }
  }
  addToCart() {
    // this.cartList = [];
    console.log(this.firstFormGroup.value);
    let billrecords = this.firstFormGroup.value;
    // let remainBalance = this.currentBalance - billrecords.amount;
    // if(remainBalance > 250){
    // if(this.dedicateBalance(billrecords.amount)){
      let billerId = billrecords.recharge_type;
      let billerName = "";
      this.billers.forEach(element => {
        if (element.Id == billerId) {
          billerName = element.billerName + ", " + element.city;
        }
      });
      billrecords.recharge_type = billerName;
      billrecords.location = this.billerCity;
      billrecords.transactionNo = this.transactionNo;
      billrecords.orginalAmount = billrecords.amount;
      billrecords.charge_amount = billrecords.charge_amount ? billrecords.charge_amount : 0;
      billrecords.billerId = billerId;
      billrecords.billType = this.billType;
      let amountDedicate = parseFloat(billrecords.amount) + parseFloat(billrecords.charge_amount);
      // let respo = this.dedicateBalance(amountDedicate);
      // if(respo)
      // {
      this.cartAmount += amountDedicate;
      this.cartList.push(billrecords);
      console.log(this.cartList);
      sessionStorage.setItem('cartList', JSON.stringify(this.cartList));
      this.billFetched = false;
      this.firstFormGroup.reset();
      this.firstFormGroup.get('recharge_type').setValue(this.billers[0].Id);
      this.billerCity = this.billers[0].city;
      this.billerId = this.billers[0].Id;
      if (this.billers[0].params == '') {
        this.isFetchable = false;
      }
      else {
        this.isFetchable = true;
      }
    // }
    // }
    // else {
    //   let dialogRef = this.dialog.open(AlertpopupComponent, {
    //     width: "350px",
    //     data: { title: "Threshold Limit reached!", message: "Rs. 250 threshold limit reached, please, topup your wallet.", popup: 'popup' }
    //   });
    //   dialogRef.afterClosed().subscribe(result => {
    //     this.firstFormGroup.get('amount').setValue("");
    //   });
    // }
    // this.isFetchable = true;
  }
  dedicateBalance(amount: any): boolean {
    if (sessionStorage.getItem('balance') && amount) {
      this.currentBalance = this.currentBalance - parseFloat(amount);
      if (this.currentBalance < 250) {
        this.currentBalance = this.currentBalance + parseFloat(amount);
        let dialogRef = this.dialog.open(AlertpopupComponent, {
          width: "350px",
          data: { title: "Threshold Limit reached!", message: "Rs. 250 threshold limit reached, please, topup your wallet.", popup: 'popup' }
        });
        dialogRef.afterClosed().subscribe(result => {
          // this.billFetched = false;
          // this.firstFormGroup.reset();
          return false;
        });
      }
      else {
        sessionStorage.setItem('balance', this.currentBalance.toString());
        this.shareService.accountBalance.next(this.currentBalance);
        return true;
      }
    }
    return false;
  }
  removeCart(i: any) {
    this.openPopup(i);
  }
  openPopup(i: any) {
    let dialogRef = this.dialog.open(DialogPopup);
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes') {
        let records = this.cartList[i];
        let amount = records.amount;
        this.cartList.splice(i, 1);
        // this.dedicateBalance(-(amount));
        this.cartAmount -= (parseFloat(amount) + parseFloat(records.charge_amount));
        sessionStorage.setItem('cartList', JSON.stringify(this.cartList));
      }
    });
  }
  printBill(bills: any) {
    if(bills.Pay_Status==1){
        bills.status = "Success";
    }
    this.reciepes.push(bills);
    console.log(bills);
    let timer = setTimeout(() => {
      this.openPrint();
      clearTimeout(timer);
    }, 1000);
  }
  openPrint() {
    console.log("I am called");
    let inputElement: HTMLElement = this.btnPrint.nativeElement as HTMLElement;
    inputElement.click();
    let timer = setTimeout(() => {
      console.log("I am called");
      this.clearPrint();
      this.setFocus('invoice_no');
      clearTimeout(timer);
    }, 2500);
  }
  ngOnInit(): void {
    // this.subscription = interval(5000).subscribe(x => {
    //     this.getLastTenPending();
    //   });
    // this.getLastTenPending();
  }
  ngOnDestroy(): void {
    // this.subscription.unsubscribe();
  }
  getLastTenPending(){
    // this.inProgress = true;
    let formdata = new FormData();
    let condition = {'retailer':'yes'};
    formdata.append('params', JSON.stringify(condition));
    formdata.append("userId", sessionStorage.getItem('userId'));
    formdata.append("limit", "10");
    formdata.append("page","0");
    this.http.postDataByForm('payrecharge/search_list', formdata)
      .then(data => {
        // console.log(data);
        this.respo = data;
        if (this.respo.status == 1) {
          // this.inProgress = false;
          this.allBills = this.respo.data.records;
        }
      });
  }
  setFocus(name: any) {    
    const ele = this.aForm.nativeElement[name];    
    if (ele) {
      ele.focus();
    }
  }
}

@Component({
  selector: 'dialog-popup',
  templateUrl: 'dialog.html',
})
export class DialogPopup {
  constructor(public dialogRef: MatDialogRef<DialogPopup>) { }
  closeDialog(respo: any = 'No') {
    this.dialogRef.close(respo);
  }
}