<!-- <div class="spinner" fxLayoutAlign="center center" fxFlexFill [hidden]='!inProgress'>
    <mat-spinner diameter="50"></mat-spinner>
    <p>Please wait, Work on progress...</p>
</div> -->
<div class="spinner" fxLayoutAlign="center center" fxFlexFill [hidden]="!inProgress">
    <mat-spinner diameter="70"></mat-spinner>
    <!-- <p>Please wait, Work on progress...</p> -->
    <img src="../../../favicon.ico" style="height:65px; position: absolute;">
    <!-- <div class="dot-collision"></div> -->
</div>
<mat-toolbar>
    <mat-toolbar-row>
        <button mat-icon-button (click)="toggleSidebar()">
            <mat-icon>menu</mat-icon>
        </button>
        <img src="/assets/images/handt-logo.png" class="nirvedLogo" style="max-height:100%; margin-right: 10px;"> H & T Services
        <div fxFlex fxLayout="row" fxLayoutAlign="flex-end">
            <ul fxLayout="row" fxLayoutGap="20px">
                <li style="padding-top: 5px;">
                    Support : <a href="tel:917069551087"><i class="fa fa-call"></i> +91 7069551087</a>
                </li>
                <li style="padding-top: 5px;" *ngIf="!isMobile">
                    Balance : <i class="fa fa-rupee"></i> {{currentBalance | number:'.2'}}
                </li>
                <li>
                    <button mat-icon-button routerLink="/user/device-connect" #tooltip="matTooltip" matTooltip="Connect your mobile device" [matTooltipPosition]="position.value" matTooltipHideDelay="500">
                        <mat-icon>device_unknown</mat-icon>
                    </button>
                </li>
                <li>
                    <button mat-icon-button (click)="updateBalance()" #tooltip="matTooltip" matTooltip="Update you Wallet Balance" [matTooltipPosition]="position.value" matTooltipHideDelay="500">
                        <mat-icon>update</mat-icon>
                    </button>
                </li>
                <li>
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>person_outline</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <mat-card class="card-effect"><strong>{{merchant_ID}}</strong></mat-card>
                        <div class="card-effect text-center pt-3 pb-2" *ngIf="isMobile">
                            <div style="padding-top: 5px; font-weight: bold;">
                                Balance
                            </div>
                            <div>
                                <i class="fa fa-rupee"></i> {{currentBalance | number:'.2'}}
                            </div>
                        </div>
                        <button mat-menu-item routerLink="/user/my-profile">
                            <mat-icon>person</mat-icon>
                            My Account
                        </button>
                        <!-- <button mat-menu-item routerLink="/user/change-password">
                            <mat-icon>remove_red_eye</mat-icon>
                            Change Password
                        </button> -->
                        <button mat-menu-item (click)="logoutNow()">
                            <mat-icon>exit_to_app</mat-icon>
                            Sign out
                        </button>
                    </mat-menu>
                </li>
            </ul>
        </div>
    </mat-toolbar-row>
</mat-toolbar>