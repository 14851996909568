import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { LoadwalletComponent } from 'src/app/common/loadwallet/loadwallet.component';
import { AlertpopupComponent } from 'src/app/common/alertpopup/alertpopup.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  services: any = [];
  imgSrc: any = "";
  reports: any = [];
  bgImage: any = "";
  dashboardImg: any = "";
  dashboardTitle: any;
  dashboardDetails: any;
  respo: any;
  inProgress: boolean = false;
  userRole: any = "Client";
  mobiBalance: any = "0";
  currentStatus: any = "Allowed";
  liveStatus: any = "Live";
  changeName: any = "Nirved";
  changeTime: any = "";
  bbSwitch: any = [];
  portalLive: any = [];
  changeLiveName: any = "";
  changeLiveTime: any = "";

  constructor(
    private http: HttpService,
    private route: Router,
    private dialog: MatDialog
  ) { 
      this.userRole = sessionStorage.getItem('userRole');
      this.bgImage = this.http.serviceUrl+"images/background.png";
      this.dashboardImg = this.http.serviceUrl+"images/dashboard.png";
      this.imgSrc = this.http.serviceUrl+"dashboard/";
      this.services.push(
          {'title':'Electricity', 'image':'electricity.png', 'type': 'electricity'},
          {'title':'GAS', 'image':'gas.png', 'type': 'gas'},
          {'title':'Tax Payment', 'image':'tax-bills.png', 'type': 'tax'},
          {'title':'Water Bill', 'image':'water-bill.png', 'type': 'water'},
          {'title':'Insurance', 'image':'lic.png', 'type': 'lic'},
          {'title':'Mobile Recharge', 'image':'mobile.png', 'type': 'mobile'},
          {'title':'DTH Recharge', 'image':'dth.png', 'type': 'dth'},
          {'title':'Fastage', 'image':'fastage.png', 'type': 'fastage'},
          {'title':'Domestic Money Transfer', 'image':'dmt.png', 'type': 'dmt'}
      );
      this.reports = [
        {'title':'Total TXN','count':'0'},
        {'title':'Total Collection', 'count':'0'},
        {'title':'Monthly TXN','count':'0'},
        {'title':'Monthly Collection','count':'0'}
      ];
  }
  openService(service: any){
    if(sessionStorage.getItem('userRole')=='Retailer'){
      if(service.type=='dmt'){
        this.route.navigateByUrl('/user/money-transfer');
      }
      else {
        this.route.navigateByUrl('/user/recharge/'+service.type);
      }
    }
  }
  switchToBB(status: any){
    this.currentStatus = status;
    let formdata = new FormData();
    formdata.append('table','recharge_switch');
    formdata.append('record',JSON.stringify(this.bbSwitch));
    this.http.postDataByForm('common/saveCommonRecord',formdata)
        .then((data: any) => {
            if(data.status == '1'){
              let dialogRef = this.dialog.open(AlertpopupComponent,{
                width: "350px",
                data: { title: "Status changed", message: data.message, popup:'popup'}
              });
              dialogRef.afterClosed().subscribe(result => {
                this.getBBStatus();
              });
            }
            else {
              let dialogRef = this.dialog.open(AlertpopupComponent,{
                width: "350px",
                data: { title: "Fail to change", message: data.message, popup:'popup'}
              });
              dialogRef.afterClosed().subscribe(result => {
              });
            }
        });
  }
  switchToDown(status: any){
    this.liveStatus = status;
    let formdata = new FormData();
    formdata.append('table','recharge_live');
    formdata.append('record',JSON.stringify(this.portalLive));
    this.http.postDataByForm('common/saveCommonRecord',formdata)
        .then((data: any) => {
            if(data.status == '1'){
              let dialogRef = this.dialog.open(AlertpopupComponent,{
                width: "350px",
                data: { title: "Status changed", message: data.message, popup:'popup'}
              });
              dialogRef.afterClosed().subscribe(result => {
                this.getLiveSystem();
              });
            }
            else {
              let dialogRef = this.dialog.open(AlertpopupComponent,{
                width: "350px",
                data: { title: "Fail to change", message: data.message, popup:'popup'}
              });
              dialogRef.afterClosed().subscribe(result => {
              });
            }
        });
  }
  openPage(){
    console.log("I am clicked");
  }
  loadMobiWallet(){
    let dialogRef = this.dialog.open(LoadwalletComponent,{
      width: "350px",
      data: { title: "No Balance!", popup:'loadWallet'}
    });
    dialogRef.afterClosed().subscribe(result => {
        console.log(result);
        this.mobiBalance = result;
    });
  }
  ngOnInit(): void {
      this.getDetails();
      this.getReports();
      this.getBBStatus();
      this.getLiveSystem();
  }
  getLiveSystem(){
    let date = new Date();
    let month = (date.getMonth()+1)<10?"0"+(date.getMonth()+1):(date.getMonth()+1)
    let day = date.getDate()<10?"0"+date.getDate():date.getDate();
    let formdata = new FormData();
    formdata.append('table','recharge_live');
    let condition = {'is_Active': '1'};
    formdata.append('condition',JSON.stringify(condition));
    this.http.postDataByForm('common/get_list',formdata)
        .then(data => {
          console.log(data);
          this.respo = data;
          if(this.respo.status==1){
            this.liveStatus = "Live";
            this.portalLive = this.respo.data.records[0];
            this.changeLiveName = this.portalLive.Change_by;
            this.changeLiveTime = this.portalLive.Change_on;
            this.portalLive.Close_by = sessionStorage.getItem('userName');
            this.portalLive.Close_on = date.getFullYear()+"-"+month+"-"+day+" "+date.getHours()+":"+date.getMinutes()+":"+date.getSeconds();
            this.portalLive.Switch = '0';
            this.portalLive.is_Active = '0';
          }
          else {
            this.changeTime = "";
            this.portalLive = {Change_by: sessionStorage.getItem('userName'), Switch: "1", is_Active: "1"};
            this.liveStatus = "Down";
          }
        });
  }
  getBBStatus(){
    let date = new Date();
    let month = (date.getMonth()+1)<10?"0"+(date.getMonth()+1):(date.getMonth()+1)
    let day = date.getDate()<10?"0"+date.getDate():date.getDate();
    let formdata = new FormData();
    formdata.append('table','recharge_switch');
    let condition = {'is_Active': '1'};
    formdata.append('condition',JSON.stringify(condition));
    this.http.postDataByForm('common/get_list',formdata)
        .then(data => {
          console.log(data);
          this.respo = data;
          if(this.respo.status==1){
            this.currentStatus = "Allowed";
            this.bbSwitch = this.respo.data.records[0];
            this.changeName = this.bbSwitch.Change_by;
            this.changeTime = this.bbSwitch.Change_on;
            this.bbSwitch.Close_by = sessionStorage.getItem('userName');
            this.bbSwitch.Close_on = date.getFullYear()+"-"+month+"-"+day+" "+date.getHours()+":"+date.getMinutes()+":"+date.getSeconds();
            this.bbSwitch.Switch = '0';
            this.bbSwitch.is_Active = '0';
          }
          else {
            this.changeTime = "";
            this.bbSwitch = {Change_by: sessionStorage.getItem('userName'), Switch: "1", is_Active: "1"};
            this.currentStatus = "Not Allowed";
          }
        });
  }
  ngOnChanges(){
    this.getDetails();
    this.getReports();
  }
  getDetails(){
    let formdata = new FormData();
    formdata.append('table','sft_usernotice');
    let condition = {'isactive': '1'};
    formdata.append('condition',JSON.stringify(condition));
    this.http.postDataByForm('common/get_notice',formdata)
        .then(data => {
          console.log(data);
          this.respo = data;
          if(this.respo.status==1){
            //this.datasource = this.respo.data.records;
            let records = this.respo.data.records;
            if(records){
              this.dashboardTitle = records[0].noticetitle;
              this.dashboardDetails = records[0].noticemessage;
            }
          }
        });
  }

  getReports(){
    this.inProgress = true;
    let formdata = new FormData();
    formdata.append('userId',sessionStorage.getItem('userId'));
    formdata.append('userRole',sessionStorage.getItem('userRole'));
    this.http.postDataByForm('common/get_counter',formdata)
        .then(data => {
          console.log(data);
          this.inProgress = false;
          this.respo = data;
          if(this.respo.status==1){
            //this.datasource = this.respo.data.records;
            this.reports = this.respo.data.records;
            this.mobiBalance = this.respo.data.mobiBalance;
          }
        });
  }
}
