<div id="printAvail" style="display: none;">
    <div *ngIf="reciepe">
        <table cellpadding="2px" cellspacing="0" style="width:100%">
            <tbody>
                <tr>
                    <th colspan="2" align="center" style="border-bottom:1px dotted #000000;">
                        <table style="width: 100%;" cellspacing="0" cellpadding="0">
                            <tr>
                                <!-- <td>
                                <img [src]="imgSrc+'nirved-logo.png'" style="max-height:80px; float: left; margin-bottom: 10px;">
                            </td> -->
                                <td style="text-align: center;">
                                    <h3 style="padding:0px;margin:0px;margin-bottom:5px;font-weight: bold;font-style: italic;">Online Payment Receipt</h3>

                                    <h4 style="margin:0px;font-weight: bold;font-style: italic;">Receipt of {{reciepe.Recharge_Type}}</h4>
                                </td>
                                <!-- <td>
                                <img [src]="imgSrc+'easy-pay.png'" style="max-height:50px; float: right;">
                            </td> -->
                            </tr>
                        </table>
                    </th>
                </tr>
                <tr>
                    <td style="border-bottom:1px dotted #000000;font-weight: bold;font-style: italic;">
                        Date : {{reciepe.payDate}}
                    </td>
                    <td style="text-align: right; border-bottom:1px dotted #000000;font-weight: bold;font-style: italic;">
                        Time : {{reciepe.payTime}}
                    </td>
                </tr>
                <tr>
                    <td style="font-weight: bold;font-style: italic;">
                        Service Number
                    </td>
                    <td style="font-weight: bold;font-style: italic;">
                        : {{reciepe.Invoice_No}}
                    </td>
                </tr>
                <tr>
                    <td style="font-weight: bold;font-style: italic;">
                        Customer Name
                    </td>
                    <td style="font-weight: bold;font-style: italic;">
                        : {{reciepe.Customer_Name}}
                    </td>
                </tr>
                <!-- <tr>
                    <td style="font-weight: bold;font-style: italic;">
                        BBPS Reference Number
                    </td>
                    <td style="font-weight: bold;font-style: italic;">
                        : {{reciepe.bbpsReferenceNumber || '-'}}
                    </td>
                </tr> -->
                <tr>
                    <td style="font-weight: bold;font-style: italic;">Merchant ID</td>
                    <td style="font-weight: bold;font-style: italic;">: {{merchant_ID}}</td>
                </tr>
                <tr>
                    <td style="font-weight: bold;font-style: italic;">Receipt Number</td>
                    <td style="font-weight: bold;font-style: italic;">: {{reciepe.TXN_Id}}</td>
                </tr>
                <tr>
                    <td style="font-weight: bold;font-style: italic;">Txn Status</td>
                    <td style="font-weight: bold;font-style: italic;">: {{reciepe.status}}</td>
                </tr>
                <tr>
                    <td style="border-top:1px dotted #000000;font-weight: bold;font-style: italic;" align="left">Bill Amount</td>
                    <th style="border-top:1px dotted #000000;font-weight: bold;font-style: italic;" align="left">Rs. {{reciepe.Amount}}</th>
                </tr>
                <tr *ngIf="reciepe.charge_amount!='0'">
                    <td align="left" style="font-weight: bold;font-style: italic;">Convenience Amount</td>
                    <th align="left" style="font-weight: bold;font-style: italic;">Rs. {{reciepe.charge_amount}}</th>
                </tr>
                <tr>
                    <td align="left" style="font-weight: bold;font-style: italic;">Amount Deposited</td>
                    <th align="left" style="font-weight: bold;font-style: italic;">Rs. {{reciepe.paidAmount | number: '1.2-2'}}</th>
                </tr>
                <tr>
                    <td align="left" style="font-weight: bold;font-style: italic;">Excess Payment</td>
                    <th align="left" style="font-weight: bold;font-style: italic;">Rs. {{reciepe.Amount - reciepe.Pay_Amount}} <b>(Credit)</b></th>
                </tr>
                <tr>
                    <th colspan="2" style="border-top:1px dotted #000000;font-weight: bold;font-style: italic;">Thank You For Using Our Services</th>
                </tr>
                <tr>
                    <td colspan="2" style="font-size: 10px;font-weight: bold;font-style: italic;">
                        <strong>Disclaimer -</strong>
                        <ol>
                            <li>Please check Service Number and amount mentioned in your receipt.</li>
                            <li>This receipt is valid subject to realization of cheque.</li>
                            <li>Biller may acknowledge payment within 2 business days</li>
                            <li>Receipt required for the settlement of deposit, If any</li>
                            <li>Receipt and Transaction inquiries are valid for 90-days only.</li>
                        </ol>
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <ngx-barcode [bc-value]="reciepe.TXN_Id" [bc-height]="40" [bc-display-value]="true"></ngx-barcode>
                    </td>
                </tr>
                <tr>
                    <th colspan="2" style="border-top:1px dotted #000000;font-size: 10px;">
                        <!-- Paid by Nirved Multiservices LLP<br>
                    Contact to Customer care : care@nirvedservices.in<br>
                    Nirved Multiservices LLP<br>
                    <strong>www.nirvedservices.in</strong><br>
                    Thanks for using Service of Nirved Multiservices LLP -->
                    </th>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<button id="btnPrint" style="display: none;" #btnPrint printSectionId="printAvail" ngxPrint>Print Now</button>
<app-page-loading [hidden]="!inProgress"></app-page-loading>
<!-- <div class="row" style="padding: 10px; margin-right:0px; margin-left: 0px" *ngIf="userRole=='Retailer'">
    <div class="col-md-12 text-right"  style="padding-right:0px; padding-left:0px; padding-bottom: 0px;">
        <button mat-raised-button color="primary" (click)="rechargeNow()"><mat-icon>add_circle</mat-icon> Recharge Now</button>
    </div>
</div> -->
<div class="col-12" class="header">
    <mat-card-title><span *ngIf="userRole=='Retailer'">My</span> Transaction</mat-card-title>
</div>
<div class="row" style="padding: 10px; margin-right:0px; margin-left: 0px">
    <div class="col-md-8 pl-0">
        <div class="row">
            <div class="col-md-8">
                <button (click)="changeType('today')" class="btn-trans" [ngClass]="{'btn-active':tType=='today'}">
                    Today <span *ngIf="!isMobile">Transaction</span>
                </button>
                <button (click)="changeType('month')" class="btn-trans" [ngClass]="{'btn-active':tType=='month'}">
                    Month <span *ngIf="!isMobile">Transaction</span>
                </button>
                <button (click)="changeType('custom')" class="btn-trans" [ngClass]="{'btn-active':tType=='custom'}">
                    Custom <span *ngIf="!isMobile">Transaction</span>
                </button>
                <div class="row" *ngIf="tType=='custom'">
                    <div class="col-md-12 my-2">
                        <form [formGroup]="billAll" class="row">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-5">
                                        <input class="form-control" type="date" formControlName="startDate">
                                    </div>
                                    <div class="col-md-5">
                                        <input class="form-control" type="date" formControlName="endDate">
                                    </div>
                                    <div class="col-md-2">
                                        <button mat-raised-button color="primary" (click)="searchBills()">
                                            <mat-icon>search</mat-icon> Search
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-md-2" [ngClass]="{'pl-0 pr-0': !isMobile, 'mt-2': isMobile}">
                <select (change)="getStatus($event)" [(ngModel)]="txnstatus" class="form-control">
                    <option value="">- All</option>
                    <option value="0">Pending</option>
                    <option value="2">Fail</option>
                    <option value="1">Success</option>
                </select>
            </div>
        </div>
    </div>
    <div class="col-md-4 pl-0 text-right">
        <a class="cursor-pointer" (click)="getDetails()">
            <i class="material-icons pt-2">loop</i>
        </a>
    </div>
    <div class="col-md-12" style="padding-right:0px; padding-left:0px">
        <p-table #dt [columns]="cols" [value]="allBills" [lazy]="false" (onLazyLoad)="loadCarsLazy($event)" (onPage)="paginate($event)" [paginator]="true" [rows]="100" [showCurrentPageReport]="true" [totalRecords]="totalRecords" [loading]="loading" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [responsive]="true">
            <ng-template pTemplate="caption">
                <div style="font-size: 20px;">
                    <span *ngIf="userRole=='Retailer'">My</span> Transaction
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div style="text-align: left">
                            <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                            <input type="text" pInputText size="50" placeholder="Search Global filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="ui-helper-clearfix" style="text-align: right">
                            <button type="button" pButton icon="pi pi-file-excel" iconPos="left" label="EXCEL" (click)="dt.exportCSV()" style="margin-right: 0.5em;"></button>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 50px;">
                        #
                    </th>
                    <th>
                        TXN Id
                    </th>
                    <th *ngFor="let col of colus">
                        {{col.header}}
                    </th>
                    <th style="width: 120px;">
                        Status
                    </th>
                    <th style="width: 70px;">
                        Action
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-bills>
                <tr>
                    <td>
                        {{bills.Id}}
                    </td>
                    <td>
                        <!-- <a href="{{pdfSrc+'/'+bills.Invoice_No+'.pdf'}}" target="_blank">{{bills.TXN_Id}}</a> -->
                        {{bills.TXN_Id}}
                    </td>
                    <td *ngFor="let col of colus">
                        <span *ngIf="col.field != 'paidAmount'">
                            {{bills[col.field]}}
                        </span>
                        <span *ngIf="col.field == 'paidAmount'">
                            {{bills[col.field] | number: '1.2-2'}}
                        </span>
                    </td>
                    <td style="padding: 0px; text-align: center;">
                        <span class="alert" [ngClass]="{'alert-success': bills.Pay_Status=='1','alert-info':bills.Pay_Status=='4'||bills.Pay_Status=='6'||bills.Pay_Status=='0','alert-danger':bills.Pay_Status=='2'||bills.Pay_Status=='3','alert-warning':bills.Pay_Status=='5'}"
                            style="padding: 2px 5px;">{{bills.status}}</span>
                    </td>
                    <td>
                        <button (click)="printBill(bills)" mat-icon-button color="primary" *ngIf="bills.Pay_Status == 0 || bills.Pay_Status == 1 || bills.Pay_Status == 4 || bills.Pay_Status == 6">
                            <mat-icon>print</mat-icon>
                        </button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                There are {{allBills?.length}} Records
            </ng-template>
        </p-table>
    </div>
</div>