import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { ShareserviceService } from 'src/app/services/shareservice.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertpopupComponent } from 'src/app/common/alertpopup/alertpopup.component';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { TooltipPosition } from '@angular/material/tooltip';
import { FormControl } from '@angular/forms';
import { interval, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  positionOptions: TooltipPosition[] = ['below', 'above', 'left', 'right'];
  position = new FormControl(this.positionOptions[0]);

  currentBalance: number = 0;
  inProgress: boolean = false;
  merchant_ID: any = "AHMD";
  @Output() toggleSidebarForMenu: EventEmitter<any> = new EventEmitter<any>();
  respo: any;
  imgSrc: any;
  private subscriptionInterval: Subscription;
  private refreshTimer: number = 60 * 1000;
  subscription: any;
  userRole: any = "Retailer";
  pedingDialog: any;

  // USER IN ACTIVITY
  private IdleTimer: number = 30 * 60 * 1000;
  private userActivity: any;
  private userActivitySubscription: Subscription;
  private userInactive: Subject<any> = new Subject();
  isMobile: boolean = false;

  @HostListener("window:mousemove")
  mouseMoveEvent() {
    console.log("Mouse Events");
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    console.log("Key Events");
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

  constructor(
    private shareService: ShareserviceService,
    private dialog: MatDialog,
    private _router: Router,
    private http: HttpService
  ) {
    if(window.innerWidth<=750){
      this.isMobile = true;
    }
    this.shareService.accountBalance.subscribe(value => {
      this.currentBalance = value;
    });
    this.userActivitySubscription = this.userInactive.subscribe(() => {
      console.log("User Getting Logout due to Inactivity");
      sessionStorage.clear();
      this._router.navigate(['/']);
    });
    this.merchant_ID = sessionStorage.getItem('merchant_ID');
    this.imgSrc = this.http.serviceUrl + "images/";
  }

  ngOnInit(): void {
    this.subscriptionInterval = interval(this.refreshTimer).subscribe(x => {
      this.refreshBalance();
    });
    if (sessionStorage.getItem('balance')) {
      this.currentBalance = parseFloat(sessionStorage.getItem('balance'));
    }
    this.userRole = sessionStorage.getItem('userRole');
    if(this.userRole == "Super Admin" && sessionStorage.getItem('isLogin')){
      this.subscription = interval(this.refreshTimer).subscribe(x => {
        // this.getPendingRequest();
      });
      // this.getPendingRequest();
    }
  }

  getPendingRequest() {
    if(this.pedingDialog){
        this.dialog.closeAll();
        this.pedingDialog = null;
    }
    let data: any = { userRole: this.userRole };
    let formdata = new FormData();
    formdata.append('request', JSON.stringify(data));
    this.http.postDataByForm('payrequest/get_pending', formdata)
      .then(data => {
        this.respo = data;
        console.log(this.respo);
        if (this.respo.status == 1) {
          this.pedingDialog = this.dialog.open(AlertpopupComponent, {
            width: "350px",
            disableClose: true,
            data: { title: this.respo.data.records.length + " " + (this.respo.data.records[0].Pay_mode == 'Credit' ? "Credit" : "Recharge") + ' request received!', message: this.respo.data.records.length + " New " + (this.respo.data.records[0].Pay_mode == 'Credit' ? "Credit" : "Recharge") + ' request received. Please, take action on it.', popup: 'popup' }
          });
        }
      });
  }

  logoutNow() {
    this.dialog.closeAll();
    let dialogRef = this.dialog.open(AlertpopupComponent, {
      width: "350px",
      data: { title: 'Sure to exit !', message: "Are you sure, You want to logout from system.", popup: 'confirm' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes') {
        this.http.getDataByPage("users/remove_session")
            .then((data: any) => {
                console.log(data);
            });
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('userId');
        sessionStorage.removeItem('userName');
        sessionStorage.removeItem('isLogin');
        sessionStorage.removeItem('Auth-token');
        this._router.navigate(['/']);
      }
    });
  }
  toggleSidebar() {
    this.toggleSidebarForMenu.emit();
  }

  refreshBalance() {
    let formdata = new FormData();
    let userId = sessionStorage.getItem('userId');
    if (userId) {
      formdata.append('userId', userId);
      this.http.postDataByForm('transaction/refreshBalance', formdata)
        .then(data => {
          this.respo = data;
          if (this.respo.status == 1 && this.respo.data) {
            this.currentBalance = this.respo.data.balance;
          }
        });
    }
  }

  updateBalance() {
    this.inProgress = true;
    let formdata = new FormData();
    formdata.append('userId', sessionStorage.getItem('userId'));
    this.http.postDataByForm('transaction/refreshBalance', formdata)
      .then(data => {
        this.inProgress = false;
        this.respo = data;
        if (this.respo.status == 1) {
          this.dialog.closeAll();
          let dialogRef = this.dialog.open(AlertpopupComponent, {
            width: "350px",
            data: { title: 'Balance updated !', message: "You got the latest updated balance.", popup: 'popup' }
          });
          dialogRef.afterClosed().subscribe(result => {
            this.currentBalance = this.respo.data.balance;
            sessionStorage.setItem('balance', this.currentBalance.toString());
            this.shareService.accountBalance.next(this.currentBalance);
          });
        }
      });
  }

  setTimeout() {
    this.userActivity = setTimeout(
      () => this.userInactive.next(undefined),
      this.IdleTimer
    );
  }

  ngOnDestroy() {
    if (this.subscriptionInterval) {
      this.subscriptionInterval.unsubscribe();
    }
    if (this.userActivitySubscription) {
      this.userActivitySubscription.unsubscribe();
    }
  }
}
