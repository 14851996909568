import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { interval, Subscription } from 'rxjs';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-payment-process-popup',
  templateUrl: './payment-process-popup.component.html',
  styleUrls: ['./payment-process-popup.component.css']
})
export class PaymentProcessPopupComponent implements OnInit {

  public transcationId: string;
  public subscriptionInterval: Subscription;
  public currentStatus: any = {};
  public lastUpdatedObject: any;
  public statusEnum: any = {
    "0": {
      status: 0,
      title: 'Payment Initializing',
      description: 'Your Payment is under process, Please don\'t refresh the browser until process get completed!',
      class: 'loading',
      reqType: 'inprocess',
      icon: 'loop'
    },
    "1": {
      status: 1,
      title: 'Congratulations!',
      description: 'Your payment has been made successfully!',
      class: '',
      reqType: 'success',
      icon: 'done'
    },
    "2": {
      status: 2,
      title: 'Oops! Payment Operator Failed',
      description: 'Payment failed due to operator failure, please try again after sometime!',
      class: '',
      reqType: 'failed',
      icon: 'clear'
    },
    "3": {
      status: 3,
      title: 'Oops! Payment System Failed',
      description: 'Payment failed due to system failure, please try again after sometime!',
      class: '',
      reqType: 'failed',
      icon: 'clear'
    },
    "4": {
      status: 4,
      title: 'Payment Added to Queue',
      description: 'Your payment added to the queue successfully!',
      class: 'loading',
      reqType: 'inprocess',
      icon: 'loop'
    },
    "5": {
      status: 5,
      title: 'Payment Refunded',
      description: 'Your payment has been refunded to your wallet successfully!',
      class: '',
      reqType: 'refunded',
      icon: 'attach_money'
    },
    "6": {
      status: 6,
      title: 'Payment Initializing',
      description: 'Your Payment is under process, Please don\'t refresh the browser until process get completed!',
      class: 'loading',
      reqType: 'inprocess',
      icon: 'loop'
    }
  }

  constructor(@Inject(MAT_DIALOG_DATA) data: any, private http: HttpService, public dialogRef: MatDialogRef<PaymentProcessPopupComponent>) {
    console.log(data)
    this.transcationId = data.transcationId;
  }

  ngOnInit(): void {
    this.currentStatus = this.statusEnum['0'];
    this.checkStatus();
    this.subscriptionInterval = interval(5000).subscribe(x => {
      this.checkStatus();
    });
  }

  public checkStatus() {
    this.http.postDataByForm(`payrecharge/getStatus?ClientRefNo=${this.transcationId}`, {}).then((data: any) => {
      if (data.pay_status !== undefined) {
        this.currentStatus = this.statusEnum[data.pay_status];
        this.lastUpdatedObject = data.data.records;
        if (data.pay_status == 1) {
          // Show Close and Print button
        } else if (data.pay_status == 2 || data.pay_status == 3 || data.pay_status == 5) {
          // Show Close button
        }
      } else {
        this.currentStatus = this.statusEnum['0'];
      }
    });
  }

  closeDialog(result) {
    if (this.subscriptionInterval) {
      this.subscriptionInterval.unsubscribe();
    }
    this.dialogRef.close(result);
  }
}
