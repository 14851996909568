import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'
 
@Injectable()
export class MessagingService {
 
      currentMessage = new BehaviorSubject(null);
 
      constructor(private angularFireMessaging: AngularFireMessaging) {
        
        this.angularFireMessaging.messages.subscribe((message: any) => { 
            console.log(message);
            message.onMessage = message.onMessage.bind(message);
            message._next = (payload: any) => {
              console.log(payload);
            };
            message.onMessageCallback = (payload: any) => {
              console.log(payload);
            }
            message.onTokenRefresh = message.onTokenRefresh.bind(message);
         });
      }
    
      requestPermission() {
        this.angularFireMessaging.requestToken.subscribe(
        (token) => {
            console.log(token);
        });
      }
    
      receiveMessage() {
        this.angularFireMessaging.messages.subscribe(
            (msg) => {
                console.log("show message!", msg);
                this.currentMessage.next(msg);
          })
      }
}