import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ShareserviceService } from 'src/app/services/shareservice.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  currentBalance: number = 0;
  userRole: any = "Retailer";
  userName: any = "H&T Services";
  @Output() toggleSidebarForMenu: EventEmitter<any> = new EventEmitter<any>();
  constructor(private shareService: ShareserviceService) { 
    this.shareService.accountBalance.subscribe( value => {
      this.currentBalance = value;
    });
  }
  toggleSidebar(){
    // if(window.innerWidth<=750){
      this.toggleSidebarForMenu.emit();
    // }
    // this.toggleSidebarForMenu.emit();
  }
  ngOnInit(): void {
    if(sessionStorage.getItem('balance')){
      this.currentBalance = parseFloat(sessionStorage.getItem('balance'));
    }
    this.userRole = sessionStorage.getItem('userRole');
    this.userName = sessionStorage.getItem('userName');
    console.log(this.userRole);
  }
}
