<app-page-loading [hidden]="!inProgress"></app-page-loading>
<div class="col-12 header" style="margin-bottom: 0px;">
    <mat-card-title>{{isCreditPage?'Credit':'Balance'}} Request</mat-card-title>
</div>
<div *ngIf="openUser">
    <form [formGroup]="FormGroup">
        <div class="row" style="margin-right: 0px; margin-left: 0px; margin-top: 15px;">
            <div class="col-md-12">
                <mat-card>
                    <mat-card-header>
                        <mat-card-title>{{isCreditPage?'Credit':'Balance'}} Request</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <mat-divider></mat-divider>
                        <div>&nbsp;</div>
                        <div class="row">
                            <div class="col-md-4" [hidden]="isCreditPage">
                                <div class="form-group">
                                    <label>Payment By</label>
                                    <mat-select class="form-control" (blur)="checkMode()" name="Pay_mode" formControlName='Pay_mode'>
                                        <mat-option *ngFor="let payment of paymentTypes" [value]="payment.type">
                                            {{payment.type}}
                                        </mat-option>
                                    </mat-select>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Amount</label>
                                    <input class="form-control" type="number" placeholder="Amount" formControlName="Amount">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Remitter Name</label>
                                    <input class="form-control" readonly placeholder="Remitter Name" formControlName="Remitter_Name">
                                </div>
                            </div>
                            <div class="col-md-4" *ngIf="isOnline">
                                <div class="form-group">
                                    <label>Beneficiary Bank*</label>
                                    <mat-select class="form-control" (blur)="getBankDetails()" name="Beneficiary_Bank" formControlName='Beneficiary_Bank'>
                                        <mat-option *ngFor="let bank of Banks" [value]="bank.Id">
                                            {{bank.Bank_Name}}
                                        </mat-option>
                                    </mat-select>
                                </div>
                            </div>
                            <div class="col-md-4" *ngIf="isOnline">
                                <div class="form-group">
                                    <label>Beneficiary Account*</label>
                                    <input class="form-control" readonly placeholder="Beneficiary Account" formControlName="Beneficiary_Account">
                                </div>
                            </div>
                            <div class="col-md-4" *ngIf="isOnline">
                                <div class="form-group">
                                    <label>Benefeciary Branch Code / IFSC Code</label>
                                    <input class="form-control" readonly placeholder="Beneficiary IFSC" formControlName="Beneficiary_IFSC">
                                </div>
                            </div>
                            <div class="col-md-4" [hidden]="isCreditPage">
                                <div class="form-group">
                                    <label>Deposit/Transfer Date</label>
                                    <input class="form-control" type="date" placeholder="Transaction Date" formControlName="Transaction_Date">
                                </div>
                            </div>
                            <div class="col-md-4" [hidden]="isCreditPage">
                                <div class="form-group">
                                    <label>Instrument No/Txn Reference</label>
                                    <input class="form-control" placeholder="Instrument No/Txn Reference" formControlName="Txn_Reference">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Remarks</label>
                                    <input class="form-control" placeholder="Remark" formControlName="Remark">
                                </div>
                            </div>
                            <div class="col-md-4" [hidden]="isCreditPage">
                                <div class="form-group">
                                    <label>Payment Receipt/Slip</label>
                                    <input type="file" (change)="onFileChange($event)" class="form-control">
                                </div>
                            </div>
                            <div class="col-md-8" *ngIf="imageSrc" [hidden]="isCreditPage">
                                <img [src]="imageSrc" *ngIf="imageSrc" style="max-height: 200px;">
                            </div>
                            <div class="col-md-12 text-right">
                                <div><br></div>
                                <button mat-raised-button color="warn" (click)="closeUser()" style="margin-right: 15px;">
                            <mat-icon>close</mat-icon> Clear and Close
                        </button>
                                <button mat-raised-button color="primary" [disabled]="(!FormGroup.valid || (isOnline && !isBankSelecter) || !imageSrc) || inProgress" (click)="saveUser()">
                            <mat-icon>save</mat-icon> {{actionName}} <mat-icon>forward</mat-icon>
                        </button>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </form>
</div>
<div *ngIf="!openUser">
    <mat-divider></mat-divider>
    <div class="row" style="padding: 10px; margin-right:0px; margin-left: 0px">
        <div *ngIf="!isCreditPage || ((userRole=='Retailer' || userRole=='Distributor') && isCreditPage)" class="col-md-12 text-right" style="padding-right:0px; padding-left:0px; padding-bottom: 15px;">
            <button mat-raised-button color="primary" (click)="createRecord()"><mat-icon>add_circle</mat-icon> Create New Request</button>
        </div>
        <div class="col-md-12" style="padding-right:0px; padding-left:0px" *ngIf="allRecords">
            <p-table #dt [columns]="cols" [value]="allRecords" [paginator]="true" [rows]="100" [showCurrentPageReport]="true" [totalRecords]="totalRecords" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [responsive]="true">
                <ng-template pTemplate="caption">
                    <div style="font-size: 20px;">
                        Request Records
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div style="text-align: left">
                                <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                                <input type="text" pInputText size="50" placeholder="Search Global filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="ui-helper-clearfix" style="text-align: right">
                                <button type="button" pButton icon="pi pi-file-excel" iconPos="left" label="EXCEL" (click)="dt.exportCSV()" style="margin-right: 0.5em;"></button>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th>
                            Request No.
                        </th>
                        <th>
                            Payment Mode
                        </th>
                        <th>
                            Amount
                        </th>
                        <th *ngIf="!isCreditPage">
                            Beneficiary
                        </th>
                        <th>
                            Remitter Name
                        </th>
                        <th *ngIf="!isCreditPage">
                            Txn Reference
                        </th>
                        <th style="width: 100px;">
                            Transaction Date
                        </th>
                        <th style="width: 120px;">
                            Remark
                        </th>
                        <th style="width: 120px;">
                            Status
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-record>
                    <tr>
                        <td>
                            {{record.requestNo}}
                        </td>
                        <td>
                            {{record.Pay_mode}}
                        </td>
                        <td>
                            {{record.Amount}}
                        </td>
                        <td *ngIf="!isCreditPage">
                            {{record.Beneficiary_Bank}}<br> {{record.Beneficiary_Account}}
                            <br> {{record.Beneficiary_IFSC}}
                        </td>
                        <td>
                            {{record.Remitter_Name}}
                        </td>
                        <td *ngIf="!isCreditPage">
                            <a href="{{recipePath+record.Receipt}}" target="_blank">{{record.Txn_Reference}}</a>
                        </td>
                        <td>
                            {{record.Created_on}}
                        </td>
                        <td>
                            {{record.Remark}}
                        </td>
                        <td *ngIf="userRole=='Retailer'">
                            <span style="padding: 5px 10px;" [ngClass]="{'alert alert-danger':record.Status=='Rejected', 'alert alert-success':record.Status=='Accepted', 'alert alert-info':record.Status=='Pending'}">{{record.Status}}</span>
                        </td>
                        <td *ngIf="userRole=='Distributor' && !isCreditPage">
                            <span style="padding: 5px 10px;" [ngClass]="{'alert alert-danger':record.Status=='Rejected', 'alert alert-success':record.Status=='Accepted', 'alert alert-info':record.Status=='Pending'}">{{record.Status}}</span>
                        </td>
                        <td *ngIf="userRole=='Distributor' && isCreditPage">
                            <button type="button" mat-raised-button color="warn" *ngIf="record.Status=='Rejected'">Rejected</button>
                            <button type="button" mat-raised-button color="primary" (click)="giveBalance(record)" [disabled]="inProgress" *ngIf="record.Status=='Pending'">Pending</button>
                            <button type="button" mat-raised-button style="background: green; color:#ffffff;" *ngIf="record.Status=='Accepted' && record.Pay_mode!=='Credit'">Accepted</button>
                            <button type="button" mat-raised-button style="background: green; color:#ffffff;" (click)="clearCredit(record)" *ngIf="record.Status=='Accepted' && record.Pay_mode=='Credit'">Credited</button>
                            <span style="background: #ffffff; columns: #000000;" *ngIf="record.Status=='Clear' && record.Pay_mode=='Credit'">Clear</span>
                        </td>
                        <td *ngIf="userRole=='Admin' || userRole=='Super Admin'">
                            <button type="button" mat-raised-button color="warn" *ngIf="record.Status=='Rejected'">Rejected</button>
                            <button type="button" mat-raised-button color="primary" (click)="giveBalance(record)" [disabled]="inProgress" *ngIf="record.Status=='Pending'">Pending</button>
                            <button type="button" mat-raised-button style="background: green; color:#ffffff;" *ngIf="record.Status=='Accepted' && record.Pay_mode!=='Credit'">Accepted</button>
                            <button type="button" mat-raised-button style="background: green; color:#ffffff;" (click)="clearCredit(record)" *ngIf="record.Status=='Accepted' && record.Pay_mode=='Credit'">Credited</button>
                            <span style="background: #ffffff; columns: #000000;" *ngIf="record.Status=='Clear' && record.Pay_mode=='Credit'">Clear</span>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                    There are {{allRecords?.length}} Records
                </ng-template>
            </p-table>
        </div>
    </div>
</div>