import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { HttpService } from 'src/app/services/http.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AlertpopupComponent } from 'src/app/common/alertpopup/alertpopup.component';
import { ShareserviceService } from 'src/app/services/shareservice.service';

@Component({
  selector: 'app-mytransaction',
  templateUrl: './mytransaction.component.html',
  styleUrls: ['./mytransaction.component.css']
})
export class MytransactionComponent implements OnInit {

  allBills: any = [];
  respo: any;
  cols: any[];
  first = 0;
  rows = 10;
  datasource: any = [];
  loading: boolean = false;
  totalRecords: number = 0;
  userRole: any = "Retailer";
  FormGroup: FormGroup;
  exportColumns: any[];
  openUser: boolean = false;
  inProgress: boolean = false;
  editId: any = "0";
  actionName: any = "Submit";
  paymentTypes: any = [];
  Credit: boolean = false;
  isMobile: boolean = false;
  marchant: any = [];
  currentBalance: any = 0;

  tType: any = "today";
  billAll: FormGroup;

  constructor(
    private http: HttpService,
    private route: Router,
    private dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private shareService: ShareserviceService
  ) { 
      this.paymentTypes = [{type:'Credit'},{type:'Debit'}];
      this.userRole = sessionStorage.getItem('userRole');

      this.billAll = this._formBuilder.group({
        startDate: '',
        endDate: '',
      });

      if(window.innerWidth<=750){
        this.isMobile = true;
      }
  }

  ngOnInit(): void {
    this.getDetails();
    this.initTable();

    this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));

    this.loading = false;

    this.FormGroup = this._formBuilder.group({
        Transaction_Type: ['', Validators.required],
        merchant_ID: ['', Validators.required],
        User_Id: '',
        Transaction_Amount: ['', Validators.required],
        Description: ['', Validators.required],
    });
  }
  editRecords(record: any){
    this.FormGroup.patchValue({
        Transaction_Type: record.Transaction_Type,
        merchant_ID: record.merchant_ID,
        User_Id: record.User_Id,
        Transaction_Amount: record.Transaction_Amount,
        Description: record.Description
    });
    this.editId = record.Id;
    this.openUser= true;
    this.actionName = "Update";
}
fetchMerchant(){
  this.inProgress = true;
  let formdata = new FormData();
  formdata.append('merchant_ID',this.FormGroup.get('merchant_ID').value);
  this.http.postDataByForm('users/get_records',formdata)
      .then(data => {
        console.log(data);
        this.inProgress = false;
        this.respo = data;
        if(this.respo.status==1){
          this.marchant = this.respo.data.records;
          this.FormGroup.get('User_Id').setValue(this.marchant[0].Id);
        } 
        else {
          let dialogRef = this.dialog.open(AlertpopupComponent,{
            width: "350px",
            data: { title: this.respo.title, message: this.respo.message,popup:'popup'}
          });
          dialogRef.afterClosed().subscribe(result => {
          });
        }
      });
}
saveUser(){
  this.inProgress = true;
  let formdata = new FormData();
  let data = this.FormGroup.value;
  data.request_id = sessionStorage.getItem('userId');
  data.Created_by = sessionStorage.getItem('userName');
  formdata.append('transaction',JSON.stringify(data));
  formdata.append('userRole',this.userRole);
  this.http.postDataByForm('transaction/save_transaction',formdata)
      .then(data => {
        console.log(data);
        this.inProgress = false;
        this.respo = data;
        if(this.respo.status==1){
          let dialogRef = this.dialog.open(AlertpopupComponent,{
            width: "350px",
            data: { title: this.respo.title, message: this.respo.message,popup:'popup'}
          });
          dialogRef.afterClosed().subscribe(result => {
            this.closeUser();
          });
          this.currentBalance = this.respo.data.balance.toString();
          sessionStorage.setItem('balance',this.currentBalance);
          this.shareService.accountBalance.next(this.currentBalance);
          this.FormGroup.reset();
          this.openUser = false;
          this.getDetails();
        }
        else {
          let dialogRef = this.dialog.open(AlertpopupComponent,{
            width: "350px",
            data: { title: this.respo.title, message: this.respo.message,popup:'popup'}
          });
          dialogRef.afterClosed().subscribe(result => {
          });
        }
      });
}
changeType(type: any){
  this.tType = type;
  this.allBills = [];
  this.totalRecords = 0;
  if(type != "custom"){
    this.getDetails();
  }
  this.reset();
}
changestatus() {
  let payMode = this.FormGroup.get('Transaction_Type').value;
  if(payMode=='Credit'){
    this.Credit = true;
    this.FormGroup.get('merchant_ID').setValue('Self');
    this.FormGroup.get('User_Id').setValue(sessionStorage.getItem('userId'));
  }
  else {
    this.Credit = false;
    this.FormGroup.get('merchant_ID').setValue('');
    this.FormGroup.get('User_Id').setValue('');
  }
}
createRecord(){
  this.openUser = true;
}
closeUser() {
  this.FormGroup.reset();
  this.openUser = false;
  this.marchant = [];
  this.FormGroup.get('merchant_ID').setValue('');
  this.FormGroup.get('User_Id').setValue('');
  this.FormGroup.get('Transaction_Type').setValue('');
  this.getDetails();
}
initTable(){
    this.cols = [
      { field: 'Txn_no', header: 'Txn. No.' },
      { field: 'Transaction_Type', header: 'Transaction Type' },
      { field: 'openingBal', header: 'Opening Balance'},
      { field: 'Transaction_Amount', header: 'Amount' },
      { field: 'finalBal', header: 'Final Balance' },
      { field: 'Description', header: 'Description' },
      { field: 'Name', header: 'Retainer Name' },
      { field: 'Created_on', header: 'Txn Date' }
    ];
  }

  loadCarsLazy(event: LazyLoadEvent) {
    this.loading = true;

    //in a real application, make a remote request to load data using state metadata from event
    //event.first = First row offset
    //event.rows = Number of rows per page
    //event.sortField = Field name to sort with
    //event.sortOrder = Sort order as number, 1 for asc and -1 for dec
    //filters: FilterMetadata object having field as key and filter value, filter matchMode as value

    //imitate db connection over a network
    setTimeout(() => {
        if (this.datasource) {
            this.allBills = this.datasource.slice(event.first, (event.first + event.rows));
            this.loading = false;
        }
    }, 1000);
}

  next() {
    this.first = this.first + this.rows;
  }

  prev() {
      this.first = this.first - this.rows;
  }

  reset() {
      this.first = 0;
  }

  isLastPage(): boolean {
      return this.first === (this.allBills.length - this.rows);
  }

  isFirstPage(): boolean {
      return this.first === 0;
  }
  
//   exportPdf() {
//     import("jspdf").then(jsPDF => {
//         import("jspdf-autotable").then(x => {
//             const doc = new jsPDF.default(0,0);
//             doc.autoTable(this.exportColumns, this.allBills);
//             doc.save('primengTable.pdf');
//         })
//     })
// }

// exportExcel() {
//     import("xlsx").then(xlsx => {
//         const worksheet = xlsx.utils.json_to_sheet(this.getDetails());
//         const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
//         const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
//         this.saveAsExcelFile(excelBuffer, "primengTable");
//     });
// }

  saveAsExcelFile(buffer: any, fileName: string): void {
      import("file-saver").then(FileSaver => {
          let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
          let EXCEL_EXTENSION = '.xlsx';
          const data: Blob = new Blob([buffer], {
              type: EXCEL_TYPE
          });
          FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      });
  }
  getDetails(){
    this.inProgress = true;
    let formdata = new FormData();
    formdata.append('userRole',this.userRole);
    formdata.append("userId",sessionStorage.getItem('userId'));
    formdata.append('period',this.tType);
    this.http.postDataByForm('transaction/get_list',formdata)
        .then(data => {
          console.log(data);
          this.inProgress = false;
          this.respo = data;
          if(this.respo.status==1){
            //this.datasource = this.respo.data.records;
            this.allBills = this.respo.data.records;
            this.totalRecords = this.datasource.length;
          }
        });
  }
  searchBills(){
    let formdata = new FormData();
    let billAll = this.billAll.value;
    let isValid = true;
    if(billAll.startDate == "" || billAll.endDate == ""){
      isValid = false;
      let dialogRef = this.dialog.open(AlertpopupComponent, {
        width: "350px",
        data: { title: 'Date required!', message: "Date range are required for custom summary", popup: 'popup' }
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }
    if(isValid){
      this.inProgress = true;
      formdata.append('userRole',this.userRole);
      // formdata.append('userRole','Super Admin');
      formdata.append("userId",sessionStorage.getItem('userId'));
      formdata.append('period',this.tType);
      let inCondition = ["DATE(Created_on) BETWEEN '"+billAll.startDate+"' AND '"+billAll.endDate+"'"];
      formdata.append('inCondition',JSON.stringify(inCondition));
      this.http.postDataByForm('transaction/get_list',formdata)
          .then(data => {
            this.inProgress = false;
            this.respo = data;
            if(this.respo.status==1){
              this.allBills = this.respo.data.records;
              this.totalRecords = this.datasource.length;
            }
          });
    }
  }
  addTransaction() {
    this.openUser = true;
      // this.route.navigateByUrl('/user/add-transaction');
  }
}
