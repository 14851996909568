<app-page-loading [hidden]="!inProgress"></app-page-loading>
<div class="col-12" class="header">
    <mat-card-title>Refund Summary</mat-card-title>
</div>
<div class="row" style="padding: 10px; margin-right:0px; margin-left: 0px">
    <div class="col-md-12 pl-0">
        <button (click)="changeType('today')" class="btn-trans" [ngClass]="{'btn-active':tType=='today'}">
            Today <span *ngIf="!isMobile">Summary</span>
        </button>
        <button (click)="changeType('month')" class="btn-trans" [ngClass]="{'btn-active':tType=='month'}">
            Month <span *ngIf="!isMobile">Summary</span>
        </button>
        <button (click)="changeType('custom')" class="btn-trans" [ngClass]="{'btn-active':tType=='custom'}">
            Custom <span *ngIf="!isMobile">Summary</span>
        </button>
        <div class="row" *ngIf="tType=='custom'">
            <div class="col-md-12 my-2">
                <form [formGroup]="billAll" class="row">
                    <div class="col-md-8 offset-md-4">
                        <div class="row">
                            <div class="col-md-5">
                                <input class="form-control" type="date" formControlName="startDate">
                            </div>
                            <div class="col-md-5">
                                <input class="form-control" type="date" formControlName="endDate">
                            </div>
                            <div class="col-md-2">
                                <button mat-raised-button color="primary" (click)="searchBills()">
                                    <mat-icon>search</mat-icon> Search
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col-md-12" style="padding-right:0px; padding-left:0px">
        <p-table #dt [columns]="cols" [value]="allBills" [paginator]="true" [rows]="100" [showCurrentPageReport]="true" [totalRecords]="totalRecords" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [responsive]="true">
            <ng-template pTemplate="caption">
                <div style="font-size: 20px;">
                    Refund Summary
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div style="text-align: left">
                            <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                            <input type="text" pInputText size="50" placeholder="Search Global filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="ui-helper-clearfix" style="text-align: right">
                            <button type="button" pButton icon="pi pi-file-excel" iconPos="left" label="EXCEL" (click)="dt.exportCSV()" style="margin-right: 0.5em;"></button>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th *ngFor="let col of cols">
                        {{col.header}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-bills>
                <tr>
                    <td *ngFor="let col of cols">
                        <span *ngIf="col.header!='Transaction Type'">{{bills[col.field]}}</span>
                        <span *ngIf="col.header=='Transaction Type'" style="padding: 2px 15px;" class="alert" [ngClass]="{'alert-success': bills[col.field]=='Credit', 'alert-danger': bills[col.field]=='Debit'}">{{bills[col.field]}}</span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                There are {{allBills?.length}} Records
            </ng-template>
        </p-table>
    </div>
</div>