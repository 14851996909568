import { Component, OnInit, HostListener } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ShareserviceService } from '../services/shareservice.service';
import { AlertpopupComponent } from '../common/alertpopup/alertpopup.component';
import { HttpService } from '../services/http.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  invalidUser: boolean = true;
  firstFormGroup: FormGroup;
  inProgress: boolean = false;
  userStatus: any = [];
  ipChange: boolean = false;
  OTPText: any = "";
  validForm: boolean = true;
  notify: any = '';
  bgImage: any = '';
  bannerImage: any = '';
  isMobile: boolean = false;
  key: any ="";

  constructor(
      private _formBuilder: FormBuilder,
      private http: HttpService,
      public dialog: MatDialog,
      private _router: Router
    ) { 
      if(window.innerWidth<=750){
        this.isMobile = true;
      }
    }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
    this.key = event.key;
    if(this.key == 'Enter'){
      if(this.dialog.openDialogs && this.dialog.openDialogs.length){
        this.dialog.closeAll();
      }
      else if(this.invalidUser){
        this.checkValidUser();
      }
      else {
        this.loginNow();
      }
    }
  }

  ngOnInit(): void {
    // Remove if open Login page
    sessionStorage.removeItem('Auth-token');

    this.bgImage = this.http.serviceUrl+"images/background.png";
    this.bannerImage = this.http.serviceUrl+"images/bannerImg.png";

    this.firstFormGroup = this._formBuilder.group({
      email_id: ['', Validators.required],
      password: ['', Validators.required],
      OTP: '',
    });
  }
  checkEmailId(){
    this.invalidUser = true;
  }
  checkValidUser(){
      this.inProgress = true;
      let formdata = new FormData();
      formdata.append('login',JSON.stringify(this.firstFormGroup.value));
      this.http.postDataByForm('users/user_checking',formdata)
             .then(data => {
                // console.log(data);
                this.userStatus  = data;
                this.inProgress = false;
                if(this.userStatus.status==1){
                  this.invalidUser = false;
                  if(this.userStatus.data.IP_change){
                    this.ipChange = true;
                    this.validForm = false;
                    // this.firstFormGroup.get('OTP').setValidators([Validators.required])
                    // this.firstFormGroup.get('OTP').updateValueAndValidity();
                    // this.OTPText = this.userStatus.data.OTPtext;
                  }
                }
                else {
                  let dialogRef = this.dialog.open(AlertpopupComponent,{
                    width: "350px",
                    data: { title: 'Request fail !', message: this.userStatus.message, popup:'popup'}
                  });
                  dialogRef.afterClosed().subscribe(result => {
                      this.firstFormGroup.reset();
                  });
                }
             }).catch(() => {

             }).finally(() => {
               this.inProgress = false;
             });
  }
  verifyOTP(){
    let enterOTP = this.firstFormGroup.get('OTP').value;
    if(enterOTP == ""){
      this.notify = 'OTP is required.';
      this.validForm = false;
    }
    else if(enterOTP.length != 6){
      this.notify = 'OTP should have 6 characters.';
      this.validForm = false;
    }
    else {
      this.notify = '';
      this.validForm = true;
    }
  }
  loginNow(){
    let enterOTP = this.firstFormGroup.get('OTP').value;
    if(this.ipChange && enterOTP == ""){
      this.notify = 'OTP is required.';
    }
    else {
      this.inProgress = true;
      let formdata = new FormData();
      formdata.append('login',JSON.stringify(this.firstFormGroup.value));
      this.http.postDataByForm('users/login',formdata)
              .then(data => {
                // console.log(data);
                this.userStatus  = data;
                this.inProgress = false;
                if(this.userStatus.status==1){
                  this.invalidUser = false;
                  sessionStorage.setItem('user',JSON.stringify(this.userStatus.data));
                  sessionStorage.setItem('userId',this.userStatus.data.Id);
                  sessionStorage.setItem('City', this.userStatus.data.City);
                  sessionStorage.setItem('merchant_ID',this.userStatus.data.merchant_ID);
                  sessionStorage.setItem('billFormat', this.userStatus.data.billFormat);
                  sessionStorage.setItem('userName',this.userStatus.data.Name);
                  sessionStorage.setItem('userRole',this.userStatus.data.userRole);
                  sessionStorage.setItem('balance',this.userStatus.data.balance);
                  sessionStorage.setItem('Auth-token',this.userStatus.data.authToken);
                  sessionStorage.setItem('isLogin','Yes');
                  // this.firstFormGroup.get('OTP').clearValidators();
                  // this.firstFormGroup.get('OTP').updateValueAndValidity();
                  this._router.navigate(['/user']);
                }
                else{
                  let dialogRef = this.dialog.open(AlertpopupComponent,{
                    width: "350px",
                    data: { title: 'Request fail !', message: this.userStatus.message, popup:'popup'}
                  });
                  dialogRef.afterClosed().subscribe(result => {
                      this.firstFormGroup.get('password').setValue('');
                  });
                }
              }).catch(() => {

              }).finally(() => {
                this.inProgress = false;
              });
      }
  }
  // checkValidUser(){
  //     this.invalidUser = false;

  // }

  // loginNow(){
  //     sessionStorage.setItem('userId','1');
  //     sessionStorage.setItem('balance','100000');
  //     sessionStorage.setItem('userRole','Admin');
  // }

}
