<div class="modal-confirm">
    <div class="modal-content">
        <div class="modal-header justify-content-center {{currentStatus.reqType}}">
            <div class="icon-box">
                <i class="material-icons {{currentStatus.class}}">{{currentStatus.icon}}</i>
            </div>
        </div>
        <div class="modal-body text-center">
            <h4>{{currentStatus.title}}</h4>
            <p>{{currentStatus.description}}</p>
            <button class="btn btn-success" *ngIf="currentStatus.status == 1 || currentStatus.status == 2 || currentStatus.status == 3  || currentStatus.status == 5 || currentStatus.status == 6 || currentStatus.status == 4" (click)="closeDialog(false)"><span>Close</span> <i class="material-icons">&#xE5C8;</i></button>
            <button class="btn btn-primary ml-2" *ngIf="currentStatus.status == 1 || currentStatus.status == 6 || currentStatus.Pay_Status == 4" (click)="closeDialog(lastUpdatedObject)"><span>Generate Receipt</span> <i class="material-icons">Print</i></button>
        </div>
    </div>
</div>