<app-page-loading [hidden]="!inProgress"></app-page-loading>
<div *ngIf="openUser">
    <form [formGroup]="FormGroup">
        <div class="row" style="margin-right: 0px; margin-left: 0px; margin-top: 15px;">
            <div class="col-md-12">
                <mat-card>
                    <mat-card-header>
                        <mat-card-title>Wallet Records</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <mat-divider></mat-divider>
                        <div>&nbsp;</div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Wallet Name</label>
                                    <input class="form-control" placeholder="Wallet Name" formControlName="Wallet_Name" required>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Amount</label>
                                    <input class="form-control" placeholder="Amount" formControlName="Credited_Amount" required>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div><br></div>
                                <button mat-raised-button color="warn" (click)="closeUser()" style="margin-right: 15px;">
                            <mat-icon>close</mat-icon> Clear and Close
                        </button>
                                <button mat-raised-button color="primary" [disabled]="!FormGroup.valid || inProgress" (click)="saveUser()">
                            <mat-icon>save</mat-icon> {{actionName}} <mat-icon>forward</mat-icon>
                        </button>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </form>
</div>
<div *ngIf="openWallet">
    <form [formGroup]="PopupGroup">
        <div class="row" style="margin-right: 0px; margin-left: 0px; margin-top: 15px;">
            <div class="col-md-12">
                <mat-card>
                    <mat-card-header>
                        <mat-card-title>Wallet Popup</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <mat-divider></mat-divider>
                        <div>&nbsp;</div>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Wallet Name <br><br> <strong>{{walletNAME}}</strong></label>
                                    <input class="form-control" type="hidden" formControlName="WalletId" required>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Opening Balance</label>
                                    <input class="form-control" readonly formControlName="Opening_bal" required>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Amount</label>
                                    <input class="form-control" (change)="findCloseBal()" placeholder="Amount" formControlName="Amount" required>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Closing Balance</label>
                                    <input class="form-control" readonly formControlName="Closing_bal" required>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div><br></div>
                                <button mat-raised-button color="warn" (click)="closeWallet()" style="margin-right: 15px;">
                                <mat-icon>close</mat-icon> Clear and Close
                            </button>
                                <button mat-raised-button color="primary" [disabled]="!PopupGroup.valid || inProgress" (click)="savePopupWallet()">
                                <mat-icon>save</mat-icon> {{actionName}} <mat-icon>forward</mat-icon>
                            </button>
                            </div>
                        </div>
                        <mat-divider style="margin-top: 5px;"></mat-divider>
                        <h2 style="margin-top: 25px;">Wallet Popup History</h2>
                        <div *ngIf="walletHistory.length>0">
                            <table cellpadding="10" style="width: 100%;" border="1">
                                <tr>
                                    <th>Opening Balance</th>
                                    <th>Amount</th>
                                    <th>Closing Balance</th>
                                    <th>Created By</th>
                                </tr>
                                <tr *ngFor="let walletH of walletHistory">
                                    <td>{{walletH.Opening_bal | number: '.2'}}</td>
                                    <td>{{walletH.Amount | number: '.2'}}</td>
                                    <td>{{walletH.Closing_bal | number: '.2'}}</td>
                                    <td>Created By {{walletH.Created_by}} on {{walletH.Created_on}}</td>
                                </tr>
                            </table>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </form>
</div>
<div *ngIf="!openUser && !openWallet">
    <mat-divider></mat-divider>
    <div class="row" style="padding: 10px; margin-right:0px; margin-left: 0px">
        <div class="col-md-12 text-right" style="padding-right:0px; padding-left:0px; padding-bottom: 15px;">
            <button mat-raised-button color="primary" (click)="createRecord()"><mat-icon>add_circle</mat-icon> Create New Wallet</button>
        </div>
        <div class="col-md-12" style="padding-right:0px; padding-left:0px" *ngIf="allRecords">
            <p-table #dt [value]="allRecords" [paginator]="true" [rows]="100" [showCurrentPageReport]="true" [totalRecords]="totalRecords" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [responsive]="true">
                <ng-template pTemplate="caption">
                    <div style="font-size: 20px;">
                        Wallet Records
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div style="text-align: left">
                                <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                                <input type="text" pInputText size="50" placeholder="Search Global filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="ui-helper-clearfix" style="text-align: right">
                                <button type="button" pButton icon="pi pi-file-excel" iconPos="left" label="EXCEL" (click)="dt.exportCSV()" style="margin-right: 0.5em;"></button>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th>
                            Wallet Name
                        </th>
                        <th>
                            Loaded Amount
                        </th>
                        <th>
                            Used Amount
                        </th>
                        <th>
                            Wallet Balance
                        </th>
                        <th>
                            Created By
                        </th>
                        <th>
                            Created On
                        </th>
                        <th style="width: 100px;">
                            Status
                        </th>
                        <th style="width: 120px;">
                            Action
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-record>
                    <tr>
                        <td>
                            {{record.Wallet_Name}}
                        </td>
                        <td>
                            {{record.Credited_Amount | number: '.2'}}
                        </td>
                        <td>
                            <button (click)="fetchUsed(record)" mat-button color="primary">
                                    {{record.Used_Amount | number: '.2'}}
                                </button>
                        </td>
                        <td>
                            {{record.Credited_Amount - record.Used_Amount | number: '.2'}}
                        </td>
                        <td>
                            {{record.Created_by}}
                        </td>
                        <td>
                            {{record.Created_on}}
                        </td>
                        <td>
                            <button type="button" mat-raised-button color="warn" *ngIf="record.is_Active==0" (click)="changeStatus(record)">Inactive</button>
                            <button type="button" mat-raised-button color="primary" *ngIf="record.is_Active!=0" (click)="changeStatus(record)">Active</button>
                        </td>
                        <td>
                            <button type="button" mat-icon-button (click)="editRecords(record)" color="primary"><mat-icon>edit</mat-icon></button>
                            <button type="button" mat-icon-button (click)="popupWallet(record)" color="primary"><mat-icon>account_balance_wallet</mat-icon></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                    There are {{allRecords?.length}} Records
                </ng-template>
            </p-table>
        </div>
    </div>
</div>