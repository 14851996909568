<app-page-loading [hidden]="!inProgress"></app-page-loading>
<div class="col-12" class="header">
    <mat-card-title>Account Summary</mat-card-title>
</div>
<div *ngIf="openUser">
    <form [formGroup]="FormGroup">
        <div class="row" style="margin-right: 0px; margin-left: 0px; margin-top: 15px;">
            <div class="col-md-12">
                <mat-card>
                    <mat-card-header>
                        <mat-card-title>Transaction</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <mat-divider></mat-divider>
                        <div>&nbsp;</div>
                        <div class="row">
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label>Transaction Type</label>
                                    <mat-select class="form-control" value='' (blur)="changestatus()" name="Transaction Type" formControlName='Transaction_Type'>
                                        <mat-option value=''>Transaction Type</mat-option>
                                        <mat-option *ngFor="let payment of paymentTypes" [value]="payment.type">
                                            {{payment.type}}
                                        </mat-option>
                                    </mat-select>
                                </div>
                            </div>
                            <div class="col-md-2" *ngIf='!Credit'>
                                <div class="form-group">
                                    <label>Merchant ID</label>
                                    <input class="form-control" (change)="fetchMerchant()" placeholder="merchant ID" formControlName="merchant_ID" required>
                                    <input class="form-control" [hidden]='true' placeholder="User ID" formControlName="User_Id" required>
                                    <div *ngIf="marchant.length>0">
                                        <div class="row">
                                            <div class="col-md-12" style="font-weight: bold;">
                                                Merchant Role
                                            </div>
                                            <div class="col-md-12">
                                                {{marchant[0]['userRole']}}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12" style="font-weight: bold;">
                                                Merchant Name
                                            </div>
                                            <div class="col-md-12">
                                                {{marchant[0]['Name']}}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12" style="font-weight: bold;">
                                                Merchant City
                                            </div>
                                            <div class="col-md-12">
                                                {{marchant[0]['City']}}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12" style="font-weight: bold;">
                                                Merchant Contact
                                            </div>
                                            <div class="col-md-12">
                                                {{marchant[0]['Contact_No']}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2" *ngIf='Credit'>
                                <div class="form-group">
                                    <label>Super Admin (self)</label>
                                    <input class="form-control" (change)="fetchMerchant()" placeholder="merchant ID" formControlName="merchant_ID" required>
                                    <input class="form-control" [hidden]='true' placeholder="User ID" formControlName="User_Id" required>
                                    <div *ngIf="marchant.length>0">
                                        <div class="row">
                                            <div class="col-md-12" style="font-weight: bold;">
                                                Merchant Role
                                            </div>
                                            <div class="col-md-12">
                                                {{marchant[0]['userRole']}}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12" style="font-weight: bold;">
                                                Merchant Name
                                            </div>
                                            <div class="col-md-12">
                                                {{marchant[0]['Name']}}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12" style="font-weight: bold;">
                                                Merchant City
                                            </div>
                                            <div class="col-md-12">
                                                {{marchant[0]['City']}}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12" style="font-weight: bold;">
                                                Merchant Contact
                                            </div>
                                            <div class="col-md-12">
                                                {{marchant[0]['Contact_No']}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Transaction Amount</label>
                                    <input class="form-control" placeholder="Transaction Amount" formControlName="Transaction_Amount" required>
                                </div>
                            </div>
                            <div class="col-md-5">
                                <div class="form-group">
                                    <label>Remark</label>
                                    <input class="form-control" placeholder="Remark" formControlName="Description" required>
                                </div>
                            </div>
                            <div class="col-md-12 text-right">
                                <div><br></div>
                                <button mat-raised-button color="warn" (click)="closeUser()" style="margin-right: 15px;">
                            <mat-icon>close</mat-icon> Clear and Close
                        </button>
                                <button mat-raised-button color="primary" [disabled]="inProgress" (click)="saveUser()">
                            <mat-icon>save</mat-icon> {{actionName}} <mat-icon>forward</mat-icon>
                        </button>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </form>
</div>
<div *ngIf="!openUser">
    <div *ngIf="userRole=='Super Admin'" class="row" style="padding: 10px; margin-right:0px; margin-left: 0px">
        <div class="col-md-12 text-right" style="padding-right:0px; padding-left:0px; padding-bottom: 15px;">
            <button mat-raised-button color="primary" (click)="addTransaction()"><mat-icon>add_circle</mat-icon> Add Transaction</button>
        </div>
    </div>
    <div class="row" style="padding: 10px; margin-right:0px; margin-left: 0px">
        <div class="col-md-12 pl-0">
            <button (click)="changeType('today')" class="btn-trans" [ngClass]="{'btn-active':tType=='today'}">
                Today <span *ngIf="!isMobile">Summary</span>
            </button>
            <button (click)="changeType('month')" class="btn-trans" [ngClass]="{'btn-active':tType=='month'}">
                Month <span *ngIf="!isMobile">Summary</span>
            </button>
            <button (click)="changeType('custom')" class="btn-trans" [ngClass]="{'btn-active':tType=='custom'}">
                Custom <span *ngIf="!isMobile">Summary</span>
            </button>
            <div class="row" *ngIf="tType=='custom'">
                <div class="col-md-12 my-2">
                    <form [formGroup]="billAll" class="row">
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-5">
                                    <input class="form-control" type="date" formControlName="startDate">
                                </div>
                                <div class="col-md-5">
                                    <input class="form-control" type="date" formControlName="endDate">
                                </div>
                                <div class="col-md-2">
                                    <button mat-raised-button color="primary" (click)="searchBills()">
                                        <mat-icon>search</mat-icon> Search
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-md-12" style="padding-right:0px; padding-left:0px">
            <p-table #dt [columns]="cols" [value]="allBills" [paginator]="true" [rows]="100" [showCurrentPageReport]="true" [totalRecords]="totalRecords" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [responsive]="true">
                <ng-template pTemplate="caption">
                    <div style="font-size: 20px;">
                        Account Summary
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div style="text-align: left">
                                <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                                <input type="text" pInputText size="50" placeholder="Search Global filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="ui-helper-clearfix" style="text-align: right">
                                <button type="button" pButton icon="pi pi-file-excel" iconPos="left" label="EXCEL" (click)="dt.exportCSV()" style="margin-right: 0.5em;"></button>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th *ngFor="let col of cols">
                            {{col.header}}
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-bills>
                    <tr>
                        <td *ngFor="let col of cols">
                            <span *ngIf="col.header!='Transaction Type'">{{bills[col.field]}}</span>
                            <span *ngIf="col.header=='Transaction Type'" style="padding: 2px 15px;" class="alert" [ngClass]="{'alert-success': bills[col.field]=='Credit', 'alert-danger': bills[col.field]=='Debit', 'alert-warning': bills[col.field]=='Recharge'}">{{bills[col.field]}}</span>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                    There are {{allBills?.length}} Records
                </ng-template>
            </p-table>
        </div>
    </div>
</div>