<app-page-loading [hidden]="!inProgress"></app-page-loading>
<div class="row" style="padding: 10px; margin-right:0px; margin-left: 0px">
    <div class="col-md-8 pl-0">
        <div class="row">
            <div class="col-md-8">
                <button (click)="changeType('pending')" class="btn-trans" [ngClass]="{'btn-active':tType=='pending'}">
                    Pending Bills ({{PendingBillsOther.length}})
                </button>
                <button (click)="changeType('red')" class="btn-trans" [ngClass]="{'btn-active':tType=='red'}">
                    Red Pending Bills ({{PendingBillsRed.length}})
                </button>
            </div>
        </div>
    </div>
    <div class="col-md-2">
        <button *ngIf="ismoresuccess" (click)="setSuccessAll()" class="btn btn-success">
            Success All
        </button>
    </div>
    <div class="col-md-2 pl-0 text-right">
        <a class="cursor-pointer" (click)="getDetails()">
            <i class="material-icons pt-2">loop</i>
        </a>
    </div>
    <div class="col-md-12" style="margin-top:15px; padding-right:0px; padding-left:0px">
        <p-table #dt [columns]="cols" [value]="dataBillList" [paginator]="true" [rows]="100" [showCurrentPageReport]="true" [totalRecords]="totalRecords" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [responsive]="true">
            <ng-template pTemplate="caption">
                <div style="font-size: 20px;">
                    Pending Bills
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div style="text-align: left">
                            <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                            <input type="text" pInputText size="50" placeholder="Search Global filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="ui-helper-clearfix" style="text-align: right">
                            <button type="button" pButton icon="pi pi-file-excel" iconPos="left" label="EXCEL" (click)="dt.exportCSV()" style="margin-right: 0.5em;"></button>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 50px; text-align: center;">
                        <input type="checkbox" name="cskallsuccess" (change)="checkAll($event)" />
                    </th>
                    <th *ngFor="let col of colus">
                        {{col.header}}
                    </th>
                    <!-- <th>
                        Is BB
                    </th> -->
                    <th style="width: 80px;">
                        Action
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-bills let-index="rowIndex">
                <tr>
                    <td style=" text-align: center;">
                        <input type="checkbox" name="csksuccess" (change)="getCheckbox($event, index)" value="bills['Id']" [checked]="bills['paychecked']" />
                    </td>
                    <td *ngFor="let col of colus">
                        {{bills[col.field]}}
                    </td>
                    <!-- <td>
                        <button type="button" mat-raised-button color="warn" *ngIf="bills.isSendBB=='0'" (click)="sendToBB(bills)">Send BB</button>
                        <span *ngIf="bills.isSendBB=='1'">Yes</span>
                    </td> -->
                    <td>
                        <button type="button" *ngIf="tType=='pending'" mat-icon-button (click)="paySuccess(bills)" color="primary"><mat-icon>check_circle</mat-icon></button>
                        <button type="button" *ngIf="tType=='red'" mat-icon-button (click)="payRedSuccess(bills)" color="primary"><mat-icon>check_circle</mat-icon></button>
                        <button type="button" mat-icon-button (click)="removeBill(bills)" *ngIf="userRole=='Super Admin'" color="warn"><mat-icon>delete</mat-icon></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                There are {{allBills?.length}} Records
            </ng-template>
        </p-table>
    </div>
</div>