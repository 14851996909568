import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-biller',
  templateUrl: './biller.component.html',
  styleUrls: ['./biller.component.css']
})
export class BillerComponent implements OnInit {
  firstFormGroup: FormGroup;
  biller_response: any;
  billers: any = [];
  inProgress: boolean = false;
  constructor(
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    private http: HttpService
  ) { 
    
    this.firstFormGroup = this._formBuilder.group({
      recharge_type: ['', Validators.required],
      invoice_no: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9]{1,}')
      ])],
      customer_name: ['', Validators.required],
      customer_mobile: '',
      amount: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9]{1,5}') 
      ])],
      due_date: ['', Validators.required],
    });

    this.getBillers();
  }

  getBillers(){
    this.inProgress = true;
    this.http.getDataByPage('billers/get_billers')
        .then(data => {
          this.inProgress = false;
          this.biller_response = data;
          console.log(this.biller_response);
          if(this.biller_response.status==1){
            this.billers = this.biller_response.data.records;
            console.log(this.billers);
          }
          else {
             console.log('Not fetching'); 
          }
        });
  }
  ngOnInit(): void {
  }
  addBillerNow(){
    
  }
}
