import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-alertpopup',
  templateUrl: './alertpopup.component.html',
  styleUrls: ['./alertpopup.component.css']
})
export class AlertpopupComponent implements OnInit {
  title: any = "";
  message: any = "";
  textNo: any = "No";
  textYes: any = "Yes";
  bbps_form: FormGroup;
  type: any = "popup";
  constructor(
    @Inject(MAT_DIALOG_DATA) data: any, 
    public dialogRef: MatDialogRef<AlertpopupComponent>,
    private _formBuilder: FormBuilder
  ) { 
      console.log(data);
      this.title = data.title;
      this.message = data.message;
      this.type = data.popup;
      if(data.buttonYes){
          this.textYes = data.buttonYes;
      }
      if(data.buttonNo){
        this.textNo = data.buttonNo;
      }
      this.bbps_form = this._formBuilder.group({
        bbps_no: ['', Validators.required],
        charge_paid: ['0']
      });

      if(this.type=='bbps_red_id'){
        this.bbps_form.get("charge_paid").setValue("");
        this.bbps_form.get("charge_paid").setValidators([Validators.required]);
      }
  }

  ngOnInit(): void {
  }
  
  closeCDialog(respo: any){
    this.dialogRef.close(respo);
  }
  closeDialog(){  
    this.dialogRef.close();
  }
  closeBBPDialog(){
    this.dialogRef.close(this.bbps_form.value);
  }
}