import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultComponent } from './default.component';
import { DashboardComponent } from 'src/app/modules/dashboard/dashboard.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { PaybillComponent, DialogPopup } from 'src/app/modules/paybill/paybill.component';
import { MybillsComponent } from 'src/app/modules/mybills/mybills.component';
import { MytransactionComponent } from 'src/app/modules/mytransaction/mytransaction.component';
import { AddtransationComponent } from 'src/app/modules/addtransation/addtransation.component';
import { PendingbillsComponent } from 'src/app/modules/pendingbills/pendingbills.component';
import { AllbillsComponent } from 'src/app/modules/allbills/allbills.component';
import { UsersComponent } from 'src/app/modules/users/users.component';
import { UserbalanceComponent } from 'src/app/modules/userbalance/userbalance.component';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxPrintModule } from 'ngx-print';
import { NgxPrinterModule } from 'ngx-printer';

import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { PushwalletComponent } from 'src/app/modules/pushwallet/pushwallet.component';
import { MyprofileComponent } from 'src/app/modules/myprofile/myprofile.component';
import { ChangepasswordComponent } from 'src/app/modules/changepassword/changepassword.component';
import { WalletmanagementComponent } from 'src/app/modules/walletmanagement/walletmanagement.component';
import { BillerComponent } from 'src/app/modules/biller/biller.component';
import { BanksComponent } from 'src/app/modules/banks/banks.component';
import { PaymentoptionsComponent } from 'src/app/modules/paymentoptions/paymentoptions.component';
import { SidenavService } from 'src/app/services/sidenav.service';
import { MobiloadmoneyComponent } from 'src/app/modules/mobiloadmoney/mobiloadmoney.component';
import { MoneytransferComponent } from 'src/app/modules/moneytransfer/moneytransfer.component';
import { UsernoticeComponent } from 'src/app/modules/usernotice/usernotice.component';
import { FailtosuccessComponent } from 'src/app/modules/failtosuccess/failtosuccess.component';
import { MoneyTransferComponent } from 'src/app/modules/money-transfer/money-transfer.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { PageLoadingComponent } from 'src/app/common/page-loading/page-loading.component';
import { RefundSummaryComponent } from 'src/app/modules/refund-summary/refund-summary.component';
import { NgxBarcodeModule } from 'ngx-barcode';

@NgModule({
  declarations: [
    DefaultComponent,
    DashboardComponent,    
    PaybillComponent,
    DialogPopup,
    MybillsComponent,
    MytransactionComponent,
    AddtransationComponent,
    PendingbillsComponent,
    AllbillsComponent,
    UsersComponent,
    UserbalanceComponent,
    PushwalletComponent,
    MyprofileComponent,
    ChangepasswordComponent,
    WalletmanagementComponent,
    BanksComponent,    
    PaymentoptionsComponent,
    MobiloadmoneyComponent,
    MoneytransferComponent,
    UsernoticeComponent,
    FailtosuccessComponent,
    MoneyTransferComponent,
    PageLoadingComponent,
    BillerComponent,
    RefundSummaryComponent
  ],
  imports: [
    TableModule,
    ButtonModule,
    NgxPrintModule,
    
    CommonModule,
    RouterModule,
    SharedModule,
    MatSidenavModule,
    MatCardModule,
    FlexLayoutModule,
    MatButtonModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatDividerModule,
    MatIconModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    NgxBarcodeModule,
    NgxMatSelectSearchModule,

    NgxPrinterModule.forRoot({printOpenWindow: true})
  ],
  providers: [SidenavService],
})
export class DefaultModule { }
