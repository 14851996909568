import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { HttpService } from 'src/app/services/http.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AlertpopupComponent } from 'src/app/common/alertpopup/alertpopup.component';

@Component({
  selector: 'app-mybills',
  templateUrl: './mybills.component.html',
  styleUrls: ['./mybills.component.css']
})
export class MybillsComponent implements OnInit {

  allBills: any = [];
  respo: any;
  cols: any[];
  colus: any[];
  first = 0;
  rows = 10;
  datasource: any = [];
  loading: boolean = false;
  totalRecords: number = 0;
  userRole: any;
  exportColumns: any[];
  inProgress: boolean = false;
  reciepes: any = [];
  imgSrc: any = "";
  merchant_ID: any = "";
  userId: any = "";
  tType: any = "today";
  reciepe: any;
  billAll: FormGroup;
  pdfSrc: any = "";
  payStatus: any  = "";
  txnstatus: any = "";
  isMobile: boolean = false;

  @ViewChild('btnPrint') btnPrint: ElementRef;

  constructor(
    private http: HttpService,
    private route: Router,
    private dialog: MatDialog,
    private _formBuilder: FormBuilder
  ) {
    this.imgSrc = this.http.serviceUrl + "images/";
    this.pdfSrc  = this.http.serviceUrl + "pdf-invoices/";
    this.userRole = sessionStorage.getItem('userRole');
    this.merchant_ID = sessionStorage.getItem('merchant_ID');
    this.userId = sessionStorage.getItem('userId');

    this.billAll = this._formBuilder.group({
      startDate: '',
      endDate: '',
    });

    if(window.innerWidth<=750){
      this.isMobile = true;
    }
  }
  printBill(bills: any) {
    this.reciepe = bills;
    console.log(bills);
    let timer = setTimeout(() => {
      this.openPrint();
      clearTimeout(timer);
    }, 1000);
  }
  openPrint() {
    console.log(this.reciepe);
    let inputElement: HTMLElement = this.btnPrint.nativeElement as HTMLElement;
    inputElement.click();
  }
  ngOnInit(): void {
    this.getDetails();
    this.initTable();

    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));

    this.loading = false;
  }
  initTable() {
    this.cols = [
      { field: 'TXN_Id', header: 'TXN Id' },
      { field: 'Recharge_Type', header: 'Service Type' },
      { field: 'Invoice_No', header: 'Service No.' },
      { field: 'paidAmount', header: 'Amount' },
      { field: 'Customer_Name', header: 'Customer Name' },
      { field: 'Customer_Phone', header: 'Customer Phone' },
      { field: 'bbpsReferenceNumber', header: 'BBPS Ref No.' },
      { field: 'Due_Date', header: 'Due Date' },
      { field: 'Recharge_Date', header: 'Recharge Date' },
      { field: 'status', header: 'Status' }
    ];
    this.colus = [
      // { field: 'TXN_Id', header: 'TXN Id' },
      { field: 'Recharge_Type', header: 'Service Type' },
      { field: 'Invoice_No', header: 'Service No.' },
      { field: 'paidAmount', header: 'Amount' },
      { field: 'Customer_Name', header: 'Customer Name' },
      { field: 'Customer_Phone', header: 'Customer Phone' },
      { field: 'bbpsReferenceNumber', header: 'BBPS Ref No.' },
      { field: 'Due_Date', header: 'Due Date' },
      { field: 'Recharge_Date', header: 'Recharge Date' },
      // { field: 'status', header: 'Status' }
    ];
  }

  loadCarsLazy(event: LazyLoadEvent) {
    this.loading = true;

    //in a real application, make a remote request to load data using state metadata from event
    //event.first = First row offset
    //event.rows = Number of rows per page
    //event.sortField = Field name to sort with
    //event.sortOrder = Sort order as number, 1 for asc and -1 for dec
    //filters: FilterMetadata object having field as key and filter value, filter matchMode as value

    //imitate db connection over a network
    setTimeout(() => {
      if (this.datasource) {
        this.allBills = this.datasource.slice(event.first, (event.first + event.rows));
        this.loading = false;
      }
    }, 1000);
  }

  next() {
    this.first = this.first + this.rows;
  }

  prev() {
    this.first = this.first - this.rows;
  }

  reset() {
    this.first = 0;
  }

  isLastPage(): boolean {
    return this.first === (this.allBills.length - this.rows);
  }

  isFirstPage(): boolean {
    return this.first === 0;
  }

  //   exportPdf() {
  //     import("jspdf").then(jsPDF => {
  //         import("jspdf-autotable").then(x => {
  //             const doc = new jsPDF.default(0,0);
  //             doc.autoTable(this.exportColumns, this.allBills);
  //             doc.save('primengTable.pdf');
  //         })
  //     })
  // }

  // exportExcel() {
  //     import("xlsx").then(xlsx => {
  //         const worksheet = xlsx.utils.json_to_sheet(this.getDetails());
  //         const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
  //         const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
  //         this.saveAsExcelFile(excelBuffer, "primengTable");
  //     });
  // }
  paginate(event: any) {  
    console.log(event);
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }
  getStatus(event: any){
    // console.log(event.target.value);
    // this.inProgress = true;
    this.allBills = [];
    this.totalRecords = 0;
    this.payStatus = event.target.value;
    if(event.target.value!=''){
      let billAll = this.billAll.value;
      let formdata = new FormData();
      formdata.append('userRole', this.userRole);
      formdata.append('userId', this.userId);
      formdata.append('period', this.tType);
      let isValid = false;
      
      // if(this.tType != 'custom'){
      //   delete condition.startDate;
      //   delete condition.endDate;
      // }
      if(this.tType == 'custom'){
        if(billAll.startDate != "" && billAll.endDate != ""){
          isValid = true;
          let condition = {"User_Id": sessionStorage.getItem('userId'), 'Pay_Status':event.target.value, "startDate": billAll.startDate, 'endDate': billAll.endDate};
          formdata.append('params', JSON.stringify(condition));
        }
        else {
          let dialogRef = this.dialog.open(AlertpopupComponent, {
            width: "350px",
            data: { title: 'Date required!', message: "Date range are required for custom summary", popup: 'popup' }
          });
          dialogRef.afterClosed().subscribe(result => {
          });
        }
      }
      else {
        isValid = true;
        let condition = {"User_Id": sessionStorage.getItem('userId'), 'Pay_Status':event.target.value};
        formdata.append('params', JSON.stringify(condition));
      }
      // formdata.append('params', JSON.stringify(condition));
      if(isValid) {
        this.inProgress = true;
        formdata.append("userId", sessionStorage.getItem('userId'));
        this.http.postDataByForm('payrecharge/search_list', formdata)
          .then(data => {
            // console.log(data);
            this.inProgress = false;
            this.respo = data;
            if (this.respo.status == 1) {
              //this.datasource = this.respo.data.records;
              this.allBills = this.respo.data.records;
              this.totalRecords = this.datasource.length;
              for (let bills of this.allBills) {
                bills.paidAmount = parseFloat(bills.Amount) + parseFloat(bills.charge_amount);
                if (bills.isDelete == 1) {
                  bills.status = 'Deleted';
                } else if (bills.isDelete == 0) {
                  if (bills.Pay_Status == 0) {
                    bills.status = 'Pending';
                  } else if (bills.Pay_Status == 1) {
                    bills.status = 'Success';
                  } else if (bills.Pay_Status == 2) {
                    bills.status = 'Operator Failed';
                  } else if (bills.Pay_Status == 3) {
                    bills.status = 'System Failed';
                  } else if (bills.Pay_Status == 4) {
                    bills.status = 'Under Process';
                  } else if (bills.Pay_Status == 5) {
                    bills.status = 'Refunded';
                  } else if (bills.Pay_Status == 6) {
                    bills.status = 'Under Process';
                  }
                }
                // console.log(bills);
              }
            }
            else {
              this.allBills = [];
              this.totalRecords = 0;
            }
          }).catch(err => {

          }).finally(() => {
              this.inProgress = false;
          });
        }
      }
      else {   
        if(this.tType == 'custom'){
          this.searchBills();
        }   
        else {
          this.getDetails();
        }
      }
  }
  searchBills(){
    let formdata = new FormData();
    let billAll = this.billAll.value;
    formdata.append('userRole', this.userRole);
    formdata.append('userId', this.userId);
    formdata.append('period', this.tType);
    let isValid = false;
    // let inCondition = ["Created_on BETWEEN '"+billAll.startDate+"' AND '"+billAll.endDate+"'"];
    // formdata.append('inCondition',JSON.stringify(inCondition));
    // if(this.tType == 'custom'){
    //   let condition = {"User_Id": sessionStorage.getItem('userId'), 'Pay_Status': this.payStatus, "startDate": billAll.startDate, 'endDate': billAll.endDate};
    //   if(this.payStatus==""){
    //     delete condition.Pay_Status;
    //   }
    //   formdata.append('params', JSON.stringify(condition));
    // }
    if(billAll.startDate != "" && billAll.endDate != ""){
      isValid = true;
      let condition = {"User_Id": sessionStorage.getItem('userId'), 'Pay_Status': this.payStatus, "startDate": billAll.startDate, 'endDate': billAll.endDate};
      formdata.append('params', JSON.stringify(condition));
    }
    else {
      let dialogRef = this.dialog.open(AlertpopupComponent, {
        width: "350px",
        data: { title: 'Date required!', message: "Date range are required for custom summary", popup: 'popup' }
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }
    if(isValid){      
      this.inProgress = true;
      this.http.postDataByForm('payrecharge/search_list', formdata)
        .then(data => {
          this.inProgress = false;
          // console.log(data);
          this.respo = data;
          if (this.respo.status == 1) {
            //this.datasource = this.respo.data.records;
            this.allBills = this.respo.data.records;
            this.totalRecords = this.datasource.length;
            for (let bills of this.allBills) {
              bills.paidAmount = parseFloat(bills.Amount) + parseFloat(bills.charge_amount);
              if (bills.isDelete == 1) {
                bills.status = 'Deleted';
              } else if (bills.isDelete == 0) {
                if (bills.Pay_Status == 0) {
                  bills.status = 'Pending';
                } else if (bills.Pay_Status == 1) {
                  bills.status = 'Success';
                } else if (bills.Pay_Status == 2) {
                  bills.status = 'Operator Failed';
                } else if (bills.Pay_Status == 3) {
                  bills.status = 'System Failed';
                } else if (bills.Pay_Status == 4) {
                  bills.status = 'Under Process';
                } else if (bills.Pay_Status == 5) {
                  bills.status = 'Refunded';
                } else if (bills.Pay_Status == 6) {
                  bills.status = 'Under Process';
                }
              }
              // console.log(bills);
            }
          }
        }).catch(err => {

        }).finally(() => {
            this.inProgress = false;
        });
      }
  }
  getDetails() {
    this.inProgress = true;
    let formdata = new FormData();
    formdata.append('userRole', this.userRole);
    formdata.append('userId', this.userId);
    formdata.append('period', this.tType);
    this.http.postDataByForm('payrecharge/get_list', formdata)
      .then(data => {
        this.inProgress = false;
        // console.log(data);
        this.respo = data;
        if (this.respo.status == 1) {
          //this.datasource = this.respo.data.records;
          this.allBills = this.respo.data.records;
          this.totalRecords = this.datasource.length;
          for (let bills of this.allBills) {
            bills.paidAmount = parseFloat(bills.Amount) + parseFloat(bills.charge_amount);
            if (bills.isDelete == 1) {
              bills.status = 'Deleted';
            } else if (bills.isDelete == 0) {
              if (bills.Pay_Status == 0) {
                bills.status = 'Pending';
              } else if (bills.Pay_Status == 1) {
                bills.status = 'Success';
              } else if (bills.Pay_Status == 2) {
                bills.status = 'Operator Failed';
              } else if (bills.Pay_Status == 3) {
                bills.status = 'System Failed';
              } else if (bills.Pay_Status == 4) {
                bills.status = 'Under Process';
              } else if (bills.Pay_Status == 5) {
                bills.status = 'Refunded';
              } else if (bills.Pay_Status == 6) {
                bills.status = 'Under Process';
              }
            }
            // console.log(bills);
          }
        }
      }).catch(err => {

      }).finally(() => {
          this.inProgress = false;
      });
  }
  changeType(type: any) {
    this.tType = type;
    this.txnstatus = "";
    if(type != 'custom'){
      this.getDetails();
    }
    else {
      this.allBills = [];
      this.totalRecords = 0;
    }
  }
  rechargeNow() {
    this.route.navigateByUrl('/user/add-recharge');
  }
}
