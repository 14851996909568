<div class="spinner" fxLayoutAlign="center center" fxFlexFill [hidden]="!inProgress">
    <mat-spinner diameter="50"></mat-spinner>
    <p>Please wait, Work on progress...</p>
</div>
<div class="loginBackground row justify-content-center align-self-center h-80vh m-0">
    <div class="col-md-6 text-center my-auto">
        <div class="card card-block text-center">
            <h1 class="mt-4">H&T Services - Mobile Support</h1>
            <mat-divider></mat-divider>
            <ngx-qrcode [elementType]="elementType" [value]="value" cssClass="aclass" errorCorrectionLevel="L">
            </ngx-qrcode>
            <h3 style="font-weight: bold; color: #ff6b58">Scan the QC code to connect with Current Session</h3>
        </div>
    </div>
</div>