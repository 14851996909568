import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';
import { AlertpopupComponent } from '../alertpopup/alertpopup.component';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {

  title: any;
  type: any;
  firstFormGroup: FormGroup;
  inProgress: boolean = false;
  userStatus: any;
  cell: any;
  response: any;
  // constructor() { }
  constructor(
    @Inject(MAT_DIALOG_DATA) data: any, 
    public dialogRef: MatDialogRef<LoaderComponent>,
    private _formBuilder: FormBuilder,
    private http: HttpService,
    private dialog: MatDialog
  ) { 
    console.log(data);
    this.cell = data.cell;
}
  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
      emailId: ['', Validators.required],
      cell: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9]{10}')
      ])],
      otp: ['', Validators.required]
    });    
    this.firstFormGroup.get("cell").setValue(this.cell);
    this.sendOTP();
  }
  closePopup(){
    this.dialogRef.close();
  }
  loadRequest(){
    this.inProgress = true;
    let formdata = new FormData();
    formdata.append('user',JSON.stringify(this.firstFormGroup.value));
    formdata.append('userRole',sessionStorage.getItem('userRole'));
    this.http.postDataByForm('mobikwik/createMobiWallet',formdata)
           .then(data => {
              this.inProgress = false;
              console.log(data);
              this.userStatus  = data;
              if(this.userStatus.status==1){
                let dialogPopupRef = this.dialog.open(AlertpopupComponent,{
                  width: "350px",
                  data: { title: 'Request success !', message: this.userStatus.message, popup:'popup'}
                });
                dialogPopupRef.afterClosed().subscribe(result => {
                    this.firstFormGroup.reset();
                    this.closePopup();
                });
              }
              else {
                let dialogPopupRef = this.dialog.open(AlertpopupComponent,{
                  width: "350px",
                  data: { title: 'Request fail !', message: this.userStatus.message, popup:'popup'}
                });
                dialogPopupRef.afterClosed().subscribe(result => {
                });
              }
           });
  }
  sendOTP(){
    this.inProgress = true;
    let param = new FormData();
    let cell = this.firstFormGroup.get("cell").value;
    param.append('cell',cell);
    param.append('amount',"1");
    param.append('msgcode',"502");
    param.append('userRole',sessionStorage.getItem('userRole'));
    this.http.postDataByForm('mobikwik/requestforOTP', param)
        .then(data => {
          this.inProgress = false;
          this.response = data;
          console.log(this.response);
          if(this.response.status==1){
            let dialogRef = this.dialog.open(AlertpopupComponent,{
              width: "350px",
              data: { title: "SMS Sent successfully !", message: this.response.message, popup:'popup'}
            });
            dialogRef.afterClosed().subscribe(result => {
            });
          }
          else {
            let dialogRef = this.dialog.open(AlertpopupComponent,{
              width: "350px",
              data: { title: "Error !", message: this.response.message, popup:'popup'}
            });
            dialogRef.afterClosed().subscribe(result => {
            });
          }
        });
  }
}
