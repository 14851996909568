<app-page-loading [hidden]="!inProgress"></app-page-loading>
<div class="loginBackground" style="height: 100%;">
    <div class="row" style="margin-left: 0px; margin-right: 0px;">
        <div class="col-12 header" style="padding: 0; padding-top: 10px; background: #f9f9f9;" *ngIf="dashboardTitle">
            <mat-card-title style="font-size: 16px;">
                <marquee behavior="scroll" direction="left"><strong style="color: #ff4500;">{{dashboardTitle}} : </strong><span style="color: #4b84ae;">{{dashboardDetails}}</span></marquee>
            </mat-card-title>
        </div>
        <div class="col-lg-12 col-md-12 mt-2">
            <div *ngIf="userRole=='Super Admin'">
                <div class="row">
                    <!-- <div class="col-md-2 col-8">
                        <h3 class="mt-2 text-right">BB Flow is {{currentStatus}}</h3>
                    </div>
                    <div class="col-md-2 col-4">
                        <button type="button" class="mr-3" *ngIf="currentStatus=='Allowed'" (click)="switchToBB('Not Allowed')" mat-raised-button color="primary"><mat-icon>check</mat-icon> Allowed</button>
                        <button type="button" class="mr-3" *ngIf="currentStatus!='Allowed'" (click)="switchToBB('Allowed')" mat-raised-button color="warn"><mat-icon>close</mat-icon> 
                        &nbsp;&nbsp;&nbsp; Not Allowed</button>
                    </div> -->
                    <!-- <div class="col-md-2 col-12 pb-3"><span *ngIf="changeTime!=''">By {{changeName}} on {{changeTime | date: 'dd-MM-yyyy hh:mm:ss'}}</span></div> -->
                    <div class="col-md-2 col-8">
                        <h3 class="mt-2 text-right">Services is down.</h3>
                    </div>
                    <div class="col-md-2 col-4">
                        <button type="button" class="mr-3" *ngIf="liveStatus=='Live'" (click)="switchToDown('Down')" mat-raised-button color="primary"><mat-icon>check</mat-icon> Live</button>
                        <button type="button" class="mr-3" *ngIf="liveStatus!='Live'" (click)="switchToDown('Live')" mat-raised-button color="warn"><mat-icon>close</mat-icon> 
                        &nbsp;&nbsp;&nbsp; Service Down</button>
                    </div>
                    <div class="col-md-2 col-12 pb-3">
                        <span *ngIf="changeTime!=''">By {{changeLiveName}} on {{changeLiveTime | date: 'dd-MM-yyyy hh:mm:ss'}}</span>
                    </div>
                </div>
            </div>
            <mat-divider></mat-divider>
            <h3 class="mb-2 mt-2">Summary</h3>
            <mat-divider></mat-divider>
            <div *ngIf="userRole=='Retailer'">
                <div class="row mt-3 mb-3">
                    <div class="col-md-3 col-6 cardreport" *ngFor="let report of reports; let i=index" (click)="openPage()">
                        <mat-card class="report-card">
                            <mat-card-subtitle><i class="fa fa-rupee" *ngIf="i%2==1"></i> {{report.count | number:'1.2-2'}}</mat-card-subtitle>
                            <mat-card-title>{{report.title}}</mat-card-title>
                            <!-- <img mat-card-image style="height: 150px;" [src]="imgSrc+service.image" alt="{{service.title}}"> -->
                        </mat-card>
                    </div>
                </div>
            </div>
            <div *ngIf="userRole=='Super Admin' || userRole=='Distributor'">
                <div class="row mt-3 mb-3">
                    <div class="col-md-3 col-6 cardreport mt-4" *ngFor="let report of reports; let i=index" (click)="openPage()">
                        <mat-card class="report-card">
                            <mat-card-subtitle><i class="fa fa-rupee" *ngIf="i%2==1"></i> {{report.count | number:'1.2-2'}}</mat-card-subtitle>
                            <mat-card-title>{{report.title}}</mat-card-title>
                            <!-- <img mat-card-image style="height: 150px;" [src]="imgSrc+service.image" alt="{{service.title}}"> -->
                        </mat-card>
                    </div>
                </div>
            </div>
            <div *ngIf="userRole=='Retailer'">
                <h3 class="mb-2 mt-5">Services</h3>
                <mat-divider></mat-divider>
                <div class="row mt-3 p-4 service-mobi">
                    <div class="col-4 col-sm-3 col-md-0 mb-4" *ngFor="let service of services">
                        <mat-card (click)="openService(service)" #tooltip="matTooltip" matTooltip="{{service.title}}" style="border-radius: 50%; box-shadow: none">
                            <img class="w-100" [src]="imgSrc+service.image" alt="{{service.title}}">
                        </mat-card>
                    </div>
                </div>
            </div>
            <!-- <div *ngIf="userRole=='Super Admin'">
                <h3 class="mb-2 mt-5">Mobikwik Wallet</h3>
                <mat-divider></mat-divider>
                <div class="row mt-3">
                    <div class="col-md-3 cardreport">
                        <mat-card class="report-card" style="text-align: center;">
                            <mat-card-subtitle><i class="fa fa-rupee"></i> {{mobiBalance | number:'.2'}}</mat-card-subtitle>
                            <mat-card-title>Wallet Balance</mat-card-title>
                            <button type="button" mat-raised-button (click)="loadMobiWallet()" color="primary">Load Wallet</button>
                        </mat-card>
                    </div>
                    <div class="col-md-3 cardreport">
                        <mat-card class="report-card" style="text-align: center;">
                            <mat-card-subtitle>Wallet Debit</mat-card-subtitle>
                            <mat-card-title>&nbsp;</mat-card-title>
                            <button type="button" mat-raised-button routerLink="/user/money-transfer" color="primary">User Pay</button>
                        </mat-card>
                    </div>
                </div>
            </div> -->
        </div>
        <div class="col-lg-12 mt-3 align-items-center" *ngIf="dashboardTitle">
            <div class="container h-100">
                <div class="row align-items-center h-100">
                    <div class="col-lg-4 col-md-8 mx-auto">
                        <!-- <div class="mb-3 bgcolor p-3 border-top" *ngIf="dashboardTitle">
                            <h2 class="mb-0 colorred">{{dashboardTitle}}</h2>
                            <div>{{dashboardDetails}}</div>
                        </div> -->
                        <img [src]="dashboardImg" class="w-100" alt="Nirved Multiservices LLP">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>